import React from 'react';
import clsx from 'clsx';
import queryString from 'query-string';
import { BlockType } from '../common/enums';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as IconSmall } from '../assets/svg/icon_a_small.svg';
import { ReactComponent as IconMedium } from '../assets/svg/icon_a_medium.svg';
import { ReactComponent as IconBig } from '../assets/svg/icon_a_big.svg';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setTextSize } from '../common/app/actions';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { RootState, AppDispatch } from '../redux/configureStore';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      iconStyle: {
        width: '32px',
        height: '32px',
        marginRight: '10px',
        borderRadius: '50%',
        transition: '1s ease',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      iconStandaloneStyle: {
        width: '44px',
        height: '44px',
        marginRight: '20px',
      },
      activeStyle: {
        background: COLORS.appGold,
      },
      iconContainerStyle: {
        display: 'flex',
        alignItems: 'center',
      },
    })
);

const TextSizeIcons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const textSize: BlockType = useSelector(
    (state: RootState) => state.app.textSize
  );
  const { pathname, search } = useLocation();

  const searchData = queryString.parse(search);
  const isStandalone = Boolean(searchData.standalone);

  const onFontClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const buttonId: any = event.currentTarget.getAttribute('data-id');

    dispatch(setTextSize(buttonId));
  };

  return (
    <div className={clsx(classes.iconContainerStyle)}>
      <IconSmall
        data-id={BlockType.SMALL}
        className={clsx(
          classes.iconStyle,
          isStandalone && classes.iconStandaloneStyle,
          textSize === BlockType.SMALL && classes.activeStyle
        )}
        onClick={onFontClick}
      />
      <IconMedium
        data-id={BlockType.MEDIUM}
        className={clsx(
          classes.iconStyle,
          isStandalone && classes.iconStandaloneStyle,
          textSize === BlockType.MEDIUM && classes.activeStyle
        )}
        onClick={onFontClick}
      />
      <IconBig
        data-id={BlockType.BIG}
        className={clsx(
          classes.iconStyle,
          isStandalone && classes.iconStandaloneStyle,
          textSize === BlockType.BIG && classes.activeStyle
        )}
        onClick={onFontClick}
      />
    </div>
  );
};

export default TextSizeIcons;
