import { ReactComponent as IconMarker } from '../assets/svg/icon_marker.svg';
import { ReactComponent as IconFlower } from '../assets/svg/type_flower.svg';
import { ReactComponent as IconFlowerNumber } from '../assets/svg/type_flower_number.svg';
import { ReactComponent as IconSeller } from '../assets/svg/type_seller.svg';
import { ReactComponent as IconSellerNumber } from '../assets/svg/type_seller_number.svg';
import { ReactComponent as IconParking } from '../assets/svg/type_parking.svg';
import { ReactComponent as IconEntrance } from '../assets/svg/type_entrance.svg';

import { ReactComponent as IconButterfly } from '../assets/svg/type_butterfly.svg';

import { ReactComponent as IconTickets } from '../assets/svg/type_tickets.svg';
import { ReactComponent as IconTicketMachine } from '../assets/svg/type_ticket_machine.svg';
import { ReactComponent as IconRestrooms } from '../assets/svg/type_restrooms.svg';
import { ReactComponent as IconCafe } from '../assets/svg/type_cafe.svg';
import { ReactComponent as IconGrocery } from '../assets/svg/type_grocery.svg';
import { ReactComponent as IconSouvenirs } from '../assets/svg/type_souvenirs.svg';
import { ReactComponent as IconWater } from '../assets/svg/type_water.svg';
import { ReactComponent as IconStudy } from '../assets/svg/type_study.svg';
import { ReactComponent as IconBus } from '../assets/svg/type_bus.svg';
import { ReactComponent as IconCash } from '../assets/svg/type_cash.svg';
import { ReactComponent as IconForest } from '../assets/svg/type_forest.svg';
import { ReactComponent as IconExhibition } from '../assets/svg/type_exhibition.svg';
import { ReactComponent as IconTheater } from '../assets/svg/type_theater.svg';
import { ReactComponent as IconChesnuts } from '../assets/svg/type_chesnuts.svg';
// import { ReactComponent as IconZiedonis } from '../assets/svg/type_ziedonis.svg';
import { ReactComponent as IconImportant } from '../assets/svg/type_important.svg';
import { ReactComponent as IconOther } from '../assets/svg/type_other.svg';

import { ReactComponent as IconElectrocar } from '../assets/svg/type_electrocar.svg';
import { ReactComponent as IconInfra } from '../assets/svg/type_infra.svg';

import { ReactComponent as IconInfo } from '../assets/svg/type_info.svg';

import { ReactComponent as IconMusic } from '../assets/svg/type_music.svg';
import { ReactComponent as IconFountain } from '../assets/svg/type_fountain.svg';
import { ReactComponent as IconTarget } from '../assets/svg/type_target.svg';
import { ReactComponent as IconTargetGray } from '../assets/svg/icon_target_gray.svg';

import { ReactComponent as IconKids } from '../assets/svg/type_kids.svg';
import { ReactComponent as IconCarousel } from '../assets/svg/type_carousel.svg';
import { ReactComponent as IconHorse } from '../assets/svg/type_horse.svg';
import { ReactComponent as IconMedical } from '../assets/svg/type_medical.svg';
import { ReactComponent as IconFlag } from '../assets/svg/type_flag.svg';
import { ReactComponent as IconInfo2 } from '../assets/svg/type_info2.svg';
/*
bosket: Boskets
flower: Augs
seller: Tirgotājs
parking: Stāvvieta
          infrastructure: Infrastruktūras objekts
          attraction: Apskates objekts
          service: Pakalpojums
entrance: Ieeja
ticket_office: Kase
ticket_machine: Biļešu automāts
restrooms: Tualete
catering: Ēdināšanas iestādes
grocery: Pārtikas veikals
souvenirs: Suvenīri
water: Dzeramais ūdens
study: Studiju kabinets
bus: Autobusa pietura
cash_machine: Bankomāts
garden: Dārzs
forest: Meža parks
exhibition: Izstāde
theater: Zaļais teātris
chesnuts: Kastaņu aleja
  ziedonis: Ziedoņa aleja
important: Svarīgi
other: Cits
*/

const componentIcons = {
  bosket: IconOther,
  flower: IconFlower,
  flowerNumber: IconFlowerNumber,
  seller: IconSeller,
  sellerNumber: IconSellerNumber,
  parking: IconParking,
  parkingNumber: IconParking,

  butterfly: IconButterfly,

  entrance: IconEntrance,
  ticket_office: IconTickets,
  ticket_machine: IconTicketMachine,
  restrooms: IconRestrooms,
  catering: IconCafe,
  grocery: IconGrocery,
  souvenirs: IconSouvenirs,
  water: IconWater,
  study: IconStudy,
  bus: IconBus,
  cash_machine: IconCash,
  garden: IconFlower,
  forest: IconForest,
  exhibition: IconExhibition,
  theater: IconTheater,
  chesnuts: IconChesnuts,
  // ziedonis: IconZiedonis,
  important: IconImportant,
  other: IconOther,
  infrastructure: IconInfra,
  attraction: IconInfra,
  electrocar: IconElectrocar,
  fountain: IconFountain,
  music: IconMusic,
  target: IconTarget,
  target_outside: IconTargetGray,
  info: IconInfo,

  kids: IconKids,
  carousel: IconCarousel,
  horse: IconHorse,
  medical: IconMedical,
  flag: IconFlag,
  info2: IconInfo2,
};

export const getImportedIcon = (type: string) => {
  return componentIcons[type] || IconOther;
};
