import Flickity from 'react-flickity-component';
import React, { useRef, useEffect, useState, PropsWithChildren } from 'react';
import Wall from './Wall';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { getPath } from '../common/utils/helpers';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';

interface IProps {
  currentWallData?: any;
}

const flickityOptions = {
  initialIndex: 0,
  draggable: true,
  freeScroll: true,
  prevNextButtons: false,
  contain: true,
  // adaptiveHeight: true,
  // wrapAround: true,
  accessibility: true,
  cellAlign: 'left',
  percentPosition: true,
  // setGallerySize: true,
};

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      fakeEndStyle: {
        width: '0px',
        height: '100%',
      },
      '@global': {
        '.fcarousel': {
          width: '100%',
          display: 'flex',
          // flex: 1,
          height: '100%',
          maxHeight: 'calc(100% - 70px)',
        },
        '.flickity-viewport': {
          width: '100vw',
          height: '100% !important',
        },
        '.flickity-page-dots': {
          bottom: '-40px !important',
        },
        '.flickity-page-dots > .flickity-page-dot:last-child': {
          display: 'none !important',
        },
        '.flickity-page-dots > .flickity-page-dot:first-child:nth-last-child(2)':
          {
            display: 'none !important',
          },
        '.fcarousel-hide-dot > ol': {
          display: 'none !important',
        },
        '.flickity-page-dot': {
          border: `1px solid ${COLORS.appBlack} !important`,
          background: 'none !important',
          opacity: '1 !important',
          width: '11px !important',
          height: '11px !important',
          boxSizing: 'border-box',
        },
        '.flickity-page-dot.is-selected': {
          border: 'none !important',
          background: `${COLORS.appBlack} !important`,
        },
      },
      '@media screen and (max-device-width:360px)': {
        '@global': {
          '.fcarousel': {
            maxHeight: 'calc(100% - 40px)',
          },
          '.flickity-page-dots': {
            bottom: '-30px !important',
          },
        },
      },
      '@media screen and (orientation:landscape)': {
        '@global': {
          '.fcarousel': {
            width: '100%',
            display: 'flex',
            // flex: 1,
            height: '100%',
            maxHeight: '100%',
          },
          '.fcarousel.standalone': {
            // maxHeight: '740px',
            maxHeight: 'calc(100% - 100px)',
          },
          '.flickity-page-dots': {
            bottom: '20px !important',
          },
          '.flickity-page-dot': {
            border: `1px solid ${COLORS.white} !important`,
            background: 'none !important',
          },
          '.flickity-page-dot.is-selected': {
            border: 'none !important',
            background: `${COLORS.white} !important`,
          },
          '.standalone .flickity-page-dots': {
            bottom: '-64px !important',
          },
          '.standalone .flickity-page-dot': {
            border: `1px solid ${COLORS.appBlack} !important`,
            background: 'none !important',
            width: '24px !important',
            height: '24px !important',
          },
          '.standalone .flickity-page-dot.is-selected': {
            border: 'none !important',
            background: `${COLORS.appBlack} !important`,
          },
        },
      },
    })
);

const FlickityWrapper: React.FC<PropsWithChildren<IProps>> = ({
  currentWallData,
}: IProps) => {
  const classes = useStyles();
  // const [flickity, setFlickity] = useState(null);
  const { search } = useLocation();

  // const fRef = useRef(null);

  const searchData = queryString.parse(search);
  const isStandalone = Boolean(searchData.standalone);

  // const onFlickityLoad = (passedFlickity) => {
  //   setFlickity(passedFlickity);
  // };

  const getSlides = (item, index) => {
    if (!item.images?.length) {
      return null;
    }

    return <Wall key={index} data={item} />;
  };

  const getClass = () => {
    const standaloneClass = isStandalone ? 'standalone' : '';

    const isMoreThanOne = currentWallData?.length > 1;
    if (isMoreThanOne) {
      return `fcarousel ${standaloneClass}`;
    }

    return `fcarousel fcarousel-hide-dot ${standaloneClass}`;
  };

  return (
    // @ts-ignore
    <Flickity
      className={getClass()} // default ''
      elementType={'div'} // default 'div'
      options={flickityOptions} // takes flickity options {}
      // disableImagesLoaded={false} // default false
      reloadOnUpdate // default false
      // static // default false
      // ref={fRef}
      // flickityRef={onFlickityLoad}
    >
      {currentWallData?.map(getSlides)}
      <div className={classes.fakeEndStyle} />
    </Flickity>
  );
};

export default React.memo(FlickityWrapper);
