import ClickAwayListener from 'react-click-away-listener';
import CloseLayout from './CloseLayout';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import React, { useRef, useEffect, useState } from 'react';
import TextBlock from './TextBlock';
import clsx from 'clsx';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import WallImages from './WallImages';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      dialogContainerStyle: {
        '& > div > div': {
          width: '100%',
          height: '100%',
          background: COLORS.appBackground,
        },
      },
      dialogContentStyle: {
        padding: '0 !important',
        boxSizing: 'border-box',
        color: COLORS.appBlack,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        background: COLORS.white,
      },
      dialogContentStandaloneStyle: {
        flexDirection: 'row',
      },
      imageContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '50%',
        minHeight: '50%',
        maxHeight: '50%',
        display: 'flex',
        alignItems: 'center',
        transition: '1s ease',
        '& > div': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          // display: 'flex',
          // alignItems: 'center',
          // justifyContent: 'center',
          overflow: 'unset !important',
          display: 'flex',
        },
      },
      maxImageContainerStyle: {
        height: '70%',
        minHeight: '70%',
        maxHeight: '70%',
      },
      wallTextContainerStyle: {
        width: '100%',
        height: '100%',
        flex: 1,
        // background: COLORS.appBackground,
        zIndex: 10,
        overflow: 'auto',
      },
      minWallTextContainerStyle: {
        height: '30%',
      },
      wallContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      },
      wallContainerStandaloneStyle: {
        display: 'flex',
        flexDirection: 'row',
      },
      imageContainerStandaloneStyle: {
        width: '1030px',
        height: '100%',
        minWidth: '1030px',
      },
      wallTextContainerStandaloneStyle: {
        boxSizing: 'border-box',
        width: '30%',
        minWidth: '30%',
        maxWidth: '30%',
        // padding: '70px',
      },
      oneImageStyle: {
        // '@global': {
        //   '.slide': {
        //     display: 'none',
        //   },
        //   '.slide:first-child': {
        //     display: 'flex',
        //   },
        // },
      },
      '@global': {
        '.MuiDialog-paperFullScreen': {
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
        },
      },
      '@media screen and (orientation: landscape)': {
        wallContainerStyle: {
          flexDirection: 'row',
        },
        imageContainerStyle: {
          width: '70%',
          minWidth: '70%',
          height: '100%',
          maxHeight: '100%',
          minHeight: '100%',
          overflow: 'hidden',
          '& .react-transform-wrapper': {
            overflow: 'unset !important',
          },
        },
        imageContainerFullImageStyle: {
          width: '100%',
        },
        minWallTextContainerStyle: {
          height: '100%',
        },
      },
      '@media screen and (orientation: portrait)': {
        imageContainerFullImageStyle: {
          height: '100%',
          maxHeight: '100%',
          minHeight: '100%',
        },
      },
    })
);

const WallItem = ({ data, onClose }) => {
  const classes = useStyles();
  const { search } = useLocation();

  const [itemData, setItemData] = useState(null);
  const [currentImageNr, setCurrentImageNr] = useState(1);
  const [isZoomed, setZoomed] = useState(false);

  const onSwipedUp = (eventData) => {
    setZoomed(false);
  };

  const onSwipedDown = (eventData) => {
    setZoomed(true);
  };

  const handlers = useSwipeable({
    onSwipedUp,
    onSwipedDown,
    swipeDuration: 300,
    preventScrollOnSwipe: false,
    trackMouse: true,
  });

  // const ref = useRef(null);

  const searchData = queryString.parse(search);
  const isStandalone = Boolean(searchData.standalone);

  const hasImages = Boolean(data?.images?.length);

  const isDeeperLevel = Boolean(data?.pages.length);
  const hasMarkers = isDeeperLevel && Boolean(data.content?.markers?.length);

  const closeWallItem = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setItemData(null);
  };

  const onMarkerClick = (event: React.SyntheticEvent, markerData) => {
    event.preventDefault();
    event.stopPropagation();

    const markerId = markerData.markerid;
    const markerItemData = data.pages.find(
      (item) => item.content.id === markerId
    );

    setItemData(markerItemData);
  };

  const isFullImage = Boolean(hasImages && hasMarkers);

  const getTextBlock = () => {
    if (isFullImage) {
      return null;
    }

    return (
      <div
        className={clsx(
          classes.wallTextContainerStyle,
          setZoomed && !isStandalone && classes.minWallTextContainerStyle,
          isStandalone && classes.wallTextContainerStandaloneStyle
        )}
        {...handlers}
      >
        <TextBlock data={data} hasBackground showFavourite />
      </div>
    );
  };

  const onZoom = (scale) => {
    if (scale > 1) {
      setZoomed(true);
    } else {
      setZoomed(false);
    }
  };

  const onChange = () => {
    // setCurrentImageNr(imageNr);
    // ref?.current?.resetTransform();
    setZoomed(false);
  };

  const handleClickAway = () => {
    // ref?.current?.resetTransform();
    setZoomed(false);
  };

  const getContent = () => {
    const isOneImage = data.images?.length === 1;

    const markerClick = hasMarkers ? { onMarkerClick } : {};

    return (
      <div
        className={clsx(
          classes.wallContainerStyle,
          isStandalone && classes.wallContainerStandaloneStyle
        )}
      >
        {hasImages && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div
              className={clsx(
                classes.imageContainerStyle,
                isZoomed && !isStandalone && classes.maxImageContainerStyle,
                isStandalone && classes.imageContainerStandaloneStyle,
                isFullImage && classes.imageContainerFullImageStyle
                // isOneImage && classes.oneImageStyle
              )}
            >
              <WallImages
                data={data}
                onChange={onChange}
                onZoom={onZoom}
                {...markerClick}
              />
            </div>
          </ClickAwayListener>
        )}
        {getTextBlock()}
      </div>
    );
  };

  if (itemData) {
    return <WallItem data={itemData} onClose={closeWallItem} />;
  }

  return (
    <Dialog
      fullScreen={true}
      open={Boolean(data)}
      onClose={onClose}
      className={classes.dialogContainerStyle}
    >
      <MuiDialogContent className={classes.dialogContentStyle}>
        <CloseLayout onClose={onClose}>{getContent()}</CloseLayout>
      </MuiDialogContent>
    </Dialog>
  );
};

export default React.memo(WallItem);
