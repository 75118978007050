import React from 'react';
import CloseHeader from './CloseHeader';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import useScrollPosition from '../hooks/useScrollPosition';

interface IProps {
  children: React.ReactNode;
  onClose?: (event: React.SyntheticEvent) => void;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      closeLayoutContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
      },
      contentContainerStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flex: 1,
        overflow: 'auto',
        boxSizing: 'border-box',
        paddingLeft: 0,
      },
      '@media screen and (orientation:landscape)': {
        closeLayoutContainerStyle: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        },
        contentContainerStyle: {
          paddingTop: 0,
        },
        [`@media (max-width: 1200px)`]: {},
      },
    })
);

const CloseLayout = ({ children, onClose }: IProps): JSX.Element => {
  const classes = useStyles();

  // const [scrollPosition, setScrollPosition] = useState(0);
  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setScrollPosition(position);
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // console.log('>>>>', scrollPosition);

  return (
    <div className={classes.closeLayoutContainerStyle}>
      <CloseHeader onClose={onClose} />
      <div className={classes.contentContainerStyle}>{children}</div>
    </div>
  );
};

export default CloseLayout;
