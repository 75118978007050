import React from 'react';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      containerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      wrapperStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        width: '100%',
        height: '100%',
        marginTop: '50px',
      },
    })
);

const NotFound = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.containerStyle}>
      <div className={classes.wrapperStyle}></div>
    </div>
  );
};

export default NotFound;
