import * as C from '../common/constants';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Routes } from '../common/routes';
import { fetchData } from '../common/app/actions';
import queryString from 'query-string';
import clsx from 'clsx';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';

interface IProps {
  title?: string;
  isActive?: boolean;
  customWrapperClass?: any;
  customButtonClass?: any;
  onClick?: (event: React.SyntheticEvent) => void;
  isCentered?: boolean;
  Icon?: any;
  disabled?: boolean;
  isScrolled?: boolean;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      buttonWrapperStyle: {
        position: 'relative',
        width: '100%',
        minHeight: '112px',
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '40px 50px',
        cursor: 'pointer',
        transition: '1s ease',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
        borderBottom: `1px solid ${COLORS.appBlack}`,
      },
      buttonWrapperStandaloneStyle: {
        width: '100%',
        minHeight: '180px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      buttonStyle: {
        width: '100%',
        flex: 1,
        fontSize: '22px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appBlack,
        // lineHeight: '64px',
        whiteSpace: 'normal',
        '& i': {
          fontStyle: 'italic',
        },
      },
      buttonStandaloneStyle: {
        maxWidth: '840px',
        fontSize: '43px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 60px',
        boxSizing: 'border-box',
      },
      activeStyle: {
        background: COLORS.appGold,
        transition: '1s ease',
      },
      centeredStyle: {
        justifyContent: 'center',
      },
      iconStyle: {
        width: '34px',
        height: '34px',
        minWidth: '34px',
        marginRight: '20px',
      },
      disabledContainerStyle: {
        border: `1px solid ${COLORS.appBlack30}`,
        cursor: 'default',
      },
      disabledIconStyle: {
        fill: COLORS.appBlack30,
      },
      disabledTextStyle: {
        color: COLORS.appBlack30,
      },
      buttonWrapperScrollStyle: {
        padding: '10px 28px',
        minHeight: '50px',
      },
      '@media screen and (max-device-width:360px)': {
        buttonWrapperStyle: {
          padding: '20px',
          minHeight: '50px',
        },
        buttonWrapperScrollStyle: {
          padding: '8px 20px',
          minHeight: '40px',
        },
        iconStyle: {
          width: '28px',
          height: '28px',
          minWidth: '28px',
        },
      },
    })
);

const UpButton = ({
  title,
  isActive,
  customWrapperClass,
  customButtonClass,
  onClick,
  isCentered,
  Icon,
  disabled,
  isScrolled,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const searchData = queryString.parse(search);
  const isStandalone = Boolean(searchData.standalone);

  return (
    <div
      className={clsx(
        classes.buttonWrapperStyle,
        isScrolled && classes.buttonWrapperScrollStyle,
        isActive && classes.activeStyle,
        isStandalone && classes.buttonWrapperStandaloneStyle,
        disabled && classes.disabledContainerStyle,
        customWrapperClass
      )}
      onClick={onClick && onClick}
    >
      {Icon && (
        <Icon
          className={clsx(
            classes.iconStyle,
            disabled && classes.disabledIconStyle
          )}
        />
      )}
      <div
        className={clsx(
          classes.buttonStyle,
          isStandalone && classes.buttonStandaloneStyle,
          isCentered && classes.centeredStyle,
          disabled && classes.disabledTextStyle,
          customButtonClass
        )}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
    </div>
  );
};

export default UpButton;
