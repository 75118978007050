import MarkerPopup from './MarkerPopup';
import Popover from '@mui/material/Popover';
import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as IconTarget } from '../assets/svg/type_target.svg';
import { ReactComponent as IconTargetGold } from '../assets/svg/type_target_gold.svg';
import { ReactComponent as IconTargetGreen } from '../assets/svg/type_target_green.svg';
import { ReactComponent as IconTargetRed } from '../assets/svg/type_target_red.svg';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';

interface IProps {
  data: any;
  onMarkerClick: (event: React.SyntheticEvent, markerData) => void;
  markerData: any;
  isMarkersSmall?: boolean;
}

const ICONS = {
  gold: IconTargetGold,
  green: IconTargetGreen,
  red: IconTargetRed,
  default: IconTarget,
};

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      iconContainerStyle: {
        position: 'absolute',
        fill: COLORS.white,
        transform: 'translate(-50%, -50%)',
        width: '100px',
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        '& > svg': {
          transform: 'translate3d(0,0,0)',
        },
      },
      iconStyle: {
        width: '38px',
        height: '38px',
        pointerEvents: 'auto',
      },
      iconSmallStyle: {
        width: '20px',
        height: '20px',
      },
      iconStandaloneStyle: {
        width: '60px',
        height: '60px',
      },
      newStyle: {
        opacity: 0.7,
      },
      targetAnimationStyle: {
        // animation: pulse-animation 2s infinite;
        animationName: '$target-animation',
        animationDuration: '5s',
        animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
        animationIterationCount: 'infinite',
        // animationDelay: '2s',
      },
      '@keyframes target-animation': {
        '0%': {
          transform: 'scale(1)',
        },
        // '40%': {
        //   transform: 'scale(1)',
        // },
        '50%': {
          transform: 'scale(1.2)',
        },
        // '60%': {
        //   transform: 'scale(1)',
        // },
        '100%': {
          transform: 'scale(1)',
        },
      },
    })
);

const ImageTargetMarker = ({
  markerData,
  data,
  onMarkerClick,
  isMarkersSmall,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const { search } = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = useRef(null);

  const searchData = queryString.parse(search);
  const isStandalone = Boolean(searchData.standalone);

  const pageData = data?.pages?.find(
    (page) => page?.content?.id === markerData.markerid
  );

  const onLocalMarkerClick = (e) => {
    const isPopup = Boolean(pageData?.content?.popup === 'true');

    if (isPopup) {
      setAnchorEl(divRef.current);
    } else {
      onMarkerClick(e, markerData);
    }
  };

  function handleClose() {
    setAnchorEl(null);
  }

  const getPopover = (markerPageData) => {
    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popoverPaperStyle }}
      >
        <MarkerPopup
          data={markerPageData}
          onClose={handleClose}
          noTextSizes
          isStandalone={isStandalone}
        />
      </Popover>
    );
  };

  const left = `${markerData.x_percent}%`;
  const top = `${markerData.y_percent}%`;

  const getIcon = () => {
    const iconType = pageData?.content?.type;
    if (iconType) {
      return ICONS[iconType];
    }

    return ICONS.default;
  };

  const Icon = getIcon();

  return (
    <>
      <div
        className={clsx(
          classes.iconContainerStyle,
          isStandalone && classes.iconContainerStandaloneStyle
        )}
        style={{ left, top }}
      >
        <Icon
          className={clsx(
            classes.iconStyle,
            isMarkersSmall && classes.iconSmallStyle,
            isStandalone && classes.iconStandaloneStyle,
            classes.targetAnimationStyle
          )}
          onClick={onLocalMarkerClick}
          ref={divRef}
        />
      </div>
      {getPopover(pageData)}
    </>
  );
};

export default ImageTargetMarker;
