import * as C from '../common/constants';
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Routes } from '../common/routes';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as SearchIcon } from '../assets/svg/search.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData, clearData } from '../common/app/actions';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { RootState, AppDispatch } from '../redux/configureStore';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      searchWrapperStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxHeight: '112px',
        minHeight: '112px',
        width: '100%',
        height: '100%',
        borderBottom: `1px solid ${COLORS.appBlack}`,
        background: 'none',
        // borderRadius: '20px',
        boxSizing: 'border-box',
        padding: '40px 50px',
        marginBottom: '0px',
      },
      searchInputStyle: {
        width: '100%',
        height: '100%',
        lineHeight: '24px',
        border: 'none',
        background: 'transparent',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        fontSize: '22px',
        // padding: '0 6px',
      },
      iconStyle: {
        width: '34px',
        height: '34px',
        minWidth: '34px',
        marginRight: '20px',
        fill: COLORS.appBlack,
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      '@media screen and (max-device-width:360px)': {
        searchWrapperStyle: {
          padding: '20px',
          maxHeight: '75px',
          minHeight: '75px',
        },
        iconStyle: {
          width: '28px',
          height: '28px',
          minWidth: '28px',
        },
      },
    })
);

const SearchInput = ({ texts }): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const lng = useSelector((state: RootState) => state.app.lng);

  const [searchInput, setSearchInput] = useState('');

  const isOutside: boolean = pathname.includes(Routes.OUTSIDE);

  useEffect(() => {
    const { input } = queryString.parse(search);
    const sanitizedInput: string = (input as string) || '';
    setSearchInput(sanitizedInput);
  }, [search]);

  const onSearch = (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    event?.preventDefault();

    const searchAsObject = queryString.parse(search);
    const newQuery = { ...searchAsObject };
    newQuery.input = searchInput;
    const newSearch = queryString.stringify(newQuery);

    navigate({
      pathname,
      search: newSearch,
    });

    const searchData = {
      ...(isOutside
        ? { searchOutside: searchInput }
        : { searchInside: searchInput }),
      lng,
    };

    dispatch(clearData(C.SEARCH_DATA));
    dispatch<AppDispatch>(fetchData(C.SEARCH_DATA, searchData));
  };

  const onSearchEnter = (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const onChange = (event) => {
    setSearchInput(event?.target?.value);
  };

  return (
    <>
      <div className={classes.searchWrapperStyle}>
        <SearchIcon className={classes.iconStyle} onClick={onSearch} />
        <input
          type="search"
          placeholder={texts?.search}
          className={classes.searchInputStyle}
          onChange={onChange}
          value={searchInput}
          onKeyDown={onSearchEnter}
        />
      </div>
    </>
  );
};

export default SearchInput;
