import * as C from '../common/constants';
import CloseLayout from './CloseLayout';
import IdleTimerWrapper from './IdleTimerWrapper';
import React, { useState, useEffect } from 'react';
import UpButton from './UpButton';
import clsx from 'clsx';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as QR } from '../assets/svg/digitalaisgids_qr_code.svg';
import { RootState, AppDispatch } from '../redux/configureStore';
import { Routes } from '../common/routes';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { fetchData } from '../common/app/actions';
import { getPath } from '../common/utils/helpers';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      mobileContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: COLORS.appBackground,
      },
      mobileWrapperStyle: {
        width: '100%',
        flex: 1,
        display: 'flex',
      },
      itemContainerStyle: {
        width: '50%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid ${COLORS.appBlack}`,
        '&:last-child': {
          borderRight: 'none',
        },
        boxSizing: 'border-box',
      },
      titleStyle: {
        width: '100%',
        height: '200px',
        fontSize: '32px',
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
        fontWeight: FONT_WEIGHT.medium,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      textStyle: {
        width: '100%',
        height: '200px',
        fontSize: '27px',
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
        fontWeight: FONT_WEIGHT.medium,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      },
      dataStyle: {
        width: '100%',
        height: '380px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      linkStyle: {
        fontSize: '70px',
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
        fontWeight: FONT_WEIGHT.medium,
      },
      qrStyle: {
        width: '250px',
        height: '250px',
      },
    })
);

const Mobile = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const lng = useSelector((state: RootState) => state.app.lng);
  const mobileData = useSelector(
    (state: RootState) => state.app[lng]?.[C.MOBILE_DATA]
  );

  const searchParams = queryString.parse(search);
  const isStandalone = Boolean(searchParams.standalone);

  useEffect(() => {
    if (!mobileData) {
      dispatch<AppDispatch>(fetchData(C.MOBILE_DATA, { mobile: true, lng }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!mobileData) {
    return null;
  }

  const getData = (item) => {
    if (item.content.link) {
      const link = item.content.link.replace('https://', '');

      return <div className={classes.linkStyle}>{link}</div>;
    }

    // TODO: fix
    if (item.svg?.length) {
      // const imgUrl: string = `${urlPrefix}${item.svg?.[0].url}`;

      // return <img className={classes.qrStyle} src={imgUrl} alt="" />;
      return <QR className={classes.qrStyle} />;
    }

    return null;
  };

  const getItems = (item, index) => {
    const titleLabel = item.content.header;

    const rawText: string = item?.content?.text || '';
    const text: string = rawText.replace(/(?:\r\n|\r|\n)/g, '<br>');

    return (
      <div className={classes.itemContainerStyle} key={index}>
        <div className={classes.titleStyle}>{titleLabel}</div>
        <div className={classes.dataStyle}>{getData(item)}</div>
        <div
          className={classes.textStyle}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </div>
    );
  };

  const getContent = () => {
    const titleLabel = mobileData?.content.text;

    return (
      <>
        <UpButton title={titleLabel} isActive={true} isCentered={true} />
        <div className={classes.mobileWrapperStyle}>
          {mobileData.pages?.map(getItems)}
        </div>
      </>
    );
  };

  const onClose = () => {
    navigate(-1);
  };

  const onIdle = () => {
    onClose();
  };

  return (
    <div className={classes.mobileContainerStyle}>
      <CloseLayout onClose={onClose}>{getContent()}</CloseLayout>
      {isStandalone && <IdleTimerWrapper onIdle={onIdle} />}
    </div>
  );
};

export default Mobile;
