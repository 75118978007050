import * as C from '../common/constants';
import CircularProgress from '@mui/material/CircularProgress';
import GeoLocation from './GeoLocation';
import Layout from './Layout';
import MarkersLocal from './MarkersLocal';
import NavigationNote from './NavigationNote';
import React, { useRef, useEffect, useState } from 'react';
import RoomTitle from './RoomTitle';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as PalaceMapSVG } from '../assets/svg/rundale_map.svg';
import { RootState, AppDispatch } from '../redux/configureStore';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { fetchData } from '../common/app/actions';
import { getText } from '../common/utils/helpers';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useWindowDimensions } from '../hooks';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      containerStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      audioContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '15%',
        maxHeight: '200px',
        minHeight: '80px',
      },
      mapContainerStyle: {
        position: 'relative',
        width: '100%',
        // height: '85%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.appBackground,
        flex: 1,
        '& > div': {
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
      mapWrapperStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // transform: 'translate3d(0, 0, 0)',
        // transformOrigin: '-50% -50%',
        // left: 0,
        // top: 0,
      },
      mapHolderStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
      mapAdjustStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      mapStyle: {
        position: 'absolute',
      },
      markersAdjustStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      noteStyle: {
        background: COLORS.appBackground,
      },
      '@media screen and (max-device-width:360px)': {},
    })
);

const Outside = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lng = useSelector((state: RootState) => state.app.lng);
  const outsideData = useSelector(
    (state: RootState) => state.app[lng]?.[C.OUTSIDE_DATA]
  );
  const settingsData = useSelector(
    (state: RootState) => state.app[C.SETTINGS_DATA]
  );
  const translationsData = useSelector(
    (state: RootState) => state.app[lng]?.[C.TRANSLATIONS_DATA]
  );
  const { outsideId } = useParams();
  const navigate = useNavigate();
  // const containerRef = useRef(null);
  const ref = useRef(null);

  const { height, width } = useWindowDimensions();
  const [scale, setScale] = useState(1);

  // useEffect(() => {
  //   console.log(containerRef?.current?.getBoundingClientRect());
  // }, []);

  useEffect(() => {
    if (!outsideData) {
      dispatch<AppDispatch>(fetchData(C.OUTSIDE_DATA, { outside: true, lng }));
    }
    if (!settingsData) {
      dispatch<AppDispatch>(
        fetchData(C.SETTINGS_DATA, { settings: true, lng })
      );
    }

    if (!translationsData) {
      dispatch<AppDispatch>(
        fetchData(C.TRANSLATIONS_DATA, { translations: true, lng })
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const pos_x = (C.MAP_SIZE.width * 0.3) / 2; // 160
  const pos_y = (C.MAP_SIZE.height * 0.3) / 2; // 250

  // const animateMap = () => {
  //   const initialScale = 0.3;

  //   const x = width / 2 - pos_x + 100;
  //   const y = height / 2 - pos_y + 100;

  //   // ref?.current?.centerView(initialScale, 800);
  //   ref?.current?.setTransform(x, y, 0.3, 800);
  // };

  // useEffect(() => {
  //   let timer;
  //   if (ref?.current && settingsData) {
  //     timer = setTimeout(() => animateMap(), 1000);
  //   }
  //   return () => clearTimeout(timer);
  // }, [ref?.current, settingsData]);

  if (!outsideData || !settingsData || !translationsData) {
    return (
      <Layout>
        <CircularProgress />
      </Layout>
    );
  }

  const { minzoom, maxzoom } = settingsData.content;
  const minScale = parseFloat(minzoom) || 0.1;
  const maxScale = parseFloat(maxzoom) || 4;

  const onZoom = (event) => {
    const newScale = event.instance.transformState.scale;
    setScale(newScale);
  };

  const currentData = outsideData.find((item) => item.content.id === outsideId);
  const currentDataPages = currentData?.pages;

  const audioData = currentData?.audio?.[0];
  const title: string = currentData?.content?.header;

  const zoomText = translationsData.content.outzoom;

  return (
    <Layout>
      <div className={classes.containerStyle}>
        <div className={classes.audioContainerStyle}>
          <RoomTitle title={title} data={audioData} />
        </div>
        <div className={classes.mapContainerStyle}>
          <TransformWrapper
            initialScale={minScale}
            initialPositionX={pos_x}
            initialPositionY={pos_y}
            // centerZoomedOut
            centerOnInit
            minScale={minScale}
            maxScale={maxScale}
            limitToBounds={false}
            ref={ref}
            // onInit={onInit}
            onZoom={onZoom}
          >
            {(transformProps) => {
              // const { zoomIn, zoomOut, resetTransform, ...rest } = transformProps;
              // console.log('>>>ZOOM', transformProps);

              return (
                <TransformComponent>
                  <div
                    className={classes.mapWrapperStyle}
                    style={{ width: `${width}px`, height: `${height}px` }}
                  >
                    <div className={classes.mapAdjustStyle}>
                      <PalaceMapSVG
                        className={classes.mapStyle}
                        style={{
                          width: `${C.MAP_SIZE.width}px`,
                          height: `${C.MAP_SIZE.height}px`,
                        }}
                      />
                    </div>
                    <div className={classes.markersAdjustStyle}>
                      {currentDataPages && (
                        <MarkersLocal
                          outsideData={currentDataPages}
                          scale={scale}
                        />
                      )}
                    </div>
                  </div>
                </TransformComponent>
              );
            }}
          </TransformWrapper>
        </div>
        <NavigationNote
          customWrapperClass={classes.noteStyle}
          text={zoomText}
        />
      </div>
    </Layout>
  );
};

export default Outside;
