import * as C from '../common/constants';
import React, { useRef, useEffect, useState } from 'react';
import UpButton from './UpButton';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { InfoButton as InfoButtonEnum } from '../common/enums';
import { ReactComponent as IconQuiz } from '../assets/svg/icon_contact_support.svg';
import { ReactComponent as IconSurvey } from '../assets/svg/icon_chat.svg';
import { ReactComponent as IconTime } from '../assets/svg/icon_time.svg';
import { RootState, AppDispatch } from '../redux/configureStore';
import { fetchData } from '../common/app/actions';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

interface IProps {
  type: InfoButtonEnum;
  disabled?: boolean;
}

const icons = {
  [InfoButtonEnum.HOURS]: IconTime,
  [InfoButtonEnum.SURVEY]: IconSurvey,
  [InfoButtonEnum.QUIZ]: IconQuiz,
};

const InfoButton = ({ type, disabled }: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const lng = useSelector((state: RootState) => state.app.lng);
  const mainData = useSelector(
    (state: RootState) => state.app[lng]?.[C.MAIN_DATA]
  );

  const Icon = icons[type];

  useEffect(() => {
    if (!mainData) {
      dispatch<AppDispatch>(fetchData(C.MAIN_DATA, { main: true, lng }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!mainData) {
    return null;
  }

  const currentData = mainData.find((item) => item.content?.id === type);
  const title = currentData?.content?.title;

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    navigate({
      pathname: `/${type}`,
      search,
    });
  };

  return (
    <UpButton Icon={Icon} title={title} onClick={onClick} disabled={disabled} />
  );
};

export default InfoButton;
