import * as C from '../common/constants';
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as IconClose } from '../assets/svg/icon_close.svg';
import Popover from '@mui/material/Popover';
import { getText, getPath } from '../common/utils/helpers';
import { clearActiveMarker } from '../common/app/actions';
import MarkerPopup from './MarkerPopup';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { getImportedIcon } from '../common/icons';
import { RootState, AppDispatch } from '../redux/configureStore';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      markerContainerStyle: {
        position: 'absolute',
        // width: '100%',
        // height: '100%',
        // display: 'flex',
        // justifyContent: 'center',
      },
      markerStyle: {
        position: 'absolute',
        width: '6px',
        height: '6px',
        pointerEvents: 'all',
        cursor: 'pointer',
        transform: `rotate(-${C.MAP_ROTATION_ANGLE}deg)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      iconStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        fill: COLORS.appBlack,
      },
      sideNumberStyle: {
        position: 'absolute',
        width: '50%',
        height: '50%',
        left: '1px',
        top: '1px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        fontSize: '50%',
        color: COLORS.appMapSand,
        // borderRadius: '50%',
        // border: `2px solid ${COLORS.appBlack}`,
        // background: COLORS.appBlack,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      centerNumberStyle: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        fontSize: '80%',
        color: COLORS.appMapSand,
      },
      parkingNumberStyle: {
        position: 'absolute',
        width: '50%',
        height: '50%',
        left: 'unset',
        top: 'unset',
        right: '-2px',
        bottom: '-2px',
        // border: `1px solid ${COLORS.appBlack}`,
        borderRadius: '50%',
      },
      circleStyle: {
        position: 'absolute',
        width: '30px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        // border: `1px solid ${COLORS.appBlack}`,
        // borderRadius: '8px',
        '&:before': {
          content: '""',
          position: 'absolute',
          display: 'block',
          width: '300%',
          height: '300%',
          boxSizing: 'border-box',
          // marginLeft: '-100%',
          // marginTop: '-100%',
          borderRadius: '50%',
          backgroundColor: '#01a4e9',
          animationName: '$pulse-ring',
          animationDuration: '1.25s',
          animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
          animationIterationCount: 'infinite',
        },
        // '&:after': {
        //   content: '""',
        //   position: 'absolute',
        //   left: 0,
        //   top: 0,
        //   display: 'block',
        //   width: '100%',
        //   height: '100%',
        //   backgroundColor: 'white',
        //   borderRadius: '15px',
        //   boxShadow: '0 0 8px rgba(0,0,0,.3)',
        //   animationName: '$pulse-dot',
        //   animationDuration: '1.25s',
        //   animationTimingFunction: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
        //   animationDelay: '-.4s',
        //   animationIterationCount: 'infinite',
        // },
      },
      popoverPaperStyle: {
        borderRadius: 0,
        // overflow: 'hidden',
        width: 'calc(100% - 32px)',
        maxWidth: '500px !important',
      },
      '@keyframes pulse-ring': {
        '0%': {
          transform: 'scale(.33)',
        },
        '80%': {
          opacity: 0,
        },
        '100%': {
          opacity: 0,
        },
      },
      '@keyframes pulse-dot': {
        '0%': {
          transform: 'scale(.8)',
        },
        '50%': {
          transform: 'scale(1)',
        },
        '100%': {
          transform: 'scale(.8)',
        },
      },
      targetAnimationStyle: {
        // animation: pulse-animation 2s infinite;
        animationName: '$target-animation',
        animationDuration: '5s',
        animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
        animationIterationCount: 'infinite',
        // animationDelay: '2s',
      },
      '@keyframes target-animation': {
        '0%': {
          transform: 'scale(1)',
        },
        // '40%': {
        //   transform: 'scale(1)',
        // },
        '50%': {
          transform: 'scale(1.4)',
        },
        // '60%': {
        //   transform: 'scale(1)',
        // },
        '100%': {
          transform: 'scale(1)',
        },
      },
    })
);

const MarkerLocal = ({ marker, settings, scale }: any): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeMarker = useSelector(
    (state: RootState) => state.app.activeMarker
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = useRef(null);

  // const { height, width } = useWindowDimensions();

  const { location: loc, type, colors, number } = marker.content;

  if (!loc?.latitude || !loc?.longitude) {
    return null;
  }

  const { latitude, longitude } = loc;

  const isBosket: boolean = type === 'bosket';
  const isParking: boolean = type === 'parking';
  const isTargetOutside: boolean = type === 'target_outside';
  const isTarget: boolean = type === 'target' || isTargetOutside;

  const sanitizedType = number && !isTarget ? `${type}Number` : type;
  const Icon = getImportedIcon(sanitizedType);

  // https://towardsdatascience.com/geotiff-coordinate-querying-with-javascript-5e6caaaf88cf
  // https://stackoverflow.com/questions/14329691/convert-latitude-longitude-point-to-a-pixels-x-y-on-mercator-projection
  // https://stackoverflow.com/questions/2651099/convert-long-lat-to-pixel-x-y-on-a-given-picture
  const minLong = 24.011526;
  const maxLong = 24.033475;
  const minLat = 56.403745;
  const maxLat = 56.418127;

  var bbox = [minLong, minLat, maxLong, maxLat];
  var pixelWidth = C.MAP_ROTATED_SIZE.width;
  var pixelHeight = C.MAP_ROTATED_SIZE.height;
  var bboxWidth = bbox[2] - bbox[0];
  var bboxHeight = bbox[3] - bbox[1];

  const convertToXY = (latitude, longitude) => {
    var widthPct = (longitude - bbox[0]) / bboxWidth;
    var heightPct = (latitude - bbox[1]) / bboxHeight;
    var x = Math.floor(pixelWidth * widthPct);
    var y = Math.floor(pixelHeight * (1 - heightPct));
    return { x, y };
  };

  const { x, y } = convertToXY(latitude, longitude);

  function handleClick() {
    // console.log('>>>>>', marker);
    setAnchorEl(divRef.current);
    dispatch(clearActiveMarker());
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const { markersize } = settings;
  const initialMarkerSize = parseFloat(markersize) || 8;
  const scaledMarkerSize = initialMarkerSize * scale + 4;

  const getMarkerSize = () => {
    if (scaledMarkerSize > 12) {
      return 12;
    }
    if (scaledMarkerSize < 4) {
      return 4;
    }

    return scaledMarkerSize;
  };

  const markerSize = getMarkerSize();
  const isActiveMarker = activeMarker === marker?.content?.id;
  // const delay = Math.floor(Math.random() * 50);

  const customColor = !isTargetOutside ? { fill: colors } : {};

  return (
    <div
      ref={divRef}
      className={classes.markerContainerStyle}
      style={{ left: x, top: y }}
    >
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popoverPaperStyle }}
      >
        <MarkerPopup data={marker} onClose={handleClose} />
      </Popover>
      <div
        className={classes.markerStyle}
        style={{ width: `${markerSize}px`, height: `${markerSize}px` }}
        onClick={handleClick}
      >
        {isActiveMarker && <div className={classes.circleStyle} />}
        <Icon
          data-name={type}
          className={clsx(
            classes.iconStyle,
            isTarget && classes.targetAnimationStyle
          )}
          style={{ ...customColor }} // animationDelay: `${delay}s`
        />
        {number && !isTarget && (
          <div
            className={clsx(
              classes.sideNumberStyle,
              isBosket && classes.centerNumberStyle,
              isParking && classes.parkingNumberStyle
            )}
            style={{ ...(isParking ? { background: colors } : {}) }}
          >
            {number}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(MarkerLocal);
