import * as C from '../common/constants';
import React from 'react';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import clsx from 'clsx';
import queryString from 'query-string';
import { ReactComponent as IconInfo } from '../assets/svg/icon_info.svg';
import { RootState } from '../redux/configureStore';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      infoIconStyle: {
        position: 'relative',
        width: '35px',
        height: '35px',
        minWidth: '35px',
        minHeight: '35px',
        border: `1px solid ${COLORS.appBlack}`,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
        fontSize: '20px',
        lineHeight: '30px',
        marginLeft: '10px',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      iconStyle: {
        width: '19px',
        height: '19px',
      },
      infoIconMarginStyle: {
        margin: '18px 0 0 10px',
      },
      infoStandaloneStyle: {
        position: 'relative',
        border: `1px solid ${COLORS.appBlack}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
        fontSize: '24px',
        lineHeight: '30px',
        whiteSpace: 'nowrap',
        marginBottom: '10px',
        padding: '8px 20px',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      '@media screen and (max-device-width:360px)': {
        infoIconStyle: {
          position: 'relative',
          width: '30px',
          height: '30px',
          minWidth: '30px',
          minHeight: '30px',
        },
      },
      '@media screen and (orientation:landscape)': {
        infoIconMarginStyle: {
          margin: '0 10px 0 0',
        },
      },
    })
);

const RoomTitleInfo = ({ onInfoClick, nr }): JSX.Element => {
  const classes = useStyles();
  const lng = useSelector((state: RootState) => state.app.lng);
  const translationsData = useSelector(
    (state: RootState) => state.app[lng]?.[C.TRANSLATIONS_DATA]
  );
  const { pathname, search } = useLocation();
  const searchData = queryString.parse(search);
  const isStandalone = Boolean(searchData.standalone);

  if (isStandalone) {
    const infoTitle = translationsData?.content?.roominfo;

    return (
      <div onClick={onInfoClick} className={clsx(classes.infoStandaloneStyle)}>
        {infoTitle}
      </div>
    );
  }

  return (
    <div
      onClick={onInfoClick}
      className={clsx(classes.infoIconStyle, nr && classes.infoIconMarginStyle)}
    >
      <IconInfo className={classes.iconStyle} />
    </div>
  );
};

export default RoomTitleInfo;
