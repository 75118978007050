import * as C from '../constants';

export function storeFavourite(payload) {
  return {
    type: C.SET_FAVOURITE,
    payload,
  };
}

export function storeSurveyID(payload) {
  return {
    type: C.SET_SURVEY_ID,
    payload,
  };
}

export function storeQuizID(payload) {
  return {
    type: C.SET_QUIZ_ID,
    payload,
  };
}
