import { Routes } from '../common/routes';
import * as C from '../common/constants';
import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { getText } from '../common/utils/helpers';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData, showAlert, resetData } from '../common/app/actions';
// import { ReactComponent as Logo } from '../assets/svg/rundale_logo.svg';
import { ReactComponent as Logo } from '../assets/svg/rpm_logo.svg';
import { gsap, Linear, Power4, Circ } from 'gsap';
// import { DrawSVGPlugin } from 'gsap/dist/DrawSVGPlugin';
import queryString from 'query-string';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';

//     "gsap": "npm:@gsap/member",

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      introContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.appBackground,
      },
      logoStyle: {
        // position: 'absolute',
        // left: 0,
        // top: 0,
        // maxWidth: '746px',
        width: '100%',
        maxWidth: '450px',
        padding: '50px',
        boxSizing: 'border-box',
        visibility: 'hidden',
        opacity: 0,
        fill: COLORS.appBlack,
        // opacity: 0,
        // visibility: 'hidden',
        // '& > #mask-R': {
        //   animation: '$strokeOffset 1s linear forwards',
        // },
      },
      // '@keyframes strokeOffset': {
      //   to: {
      //     strokeDashoffset: 0,
      //   },
      // },
      [`@media (max-width: 350px)`]: {
        logoStyle: {
          padding: '20px',
          boxSizing: 'border-box',
        },
      },
      '@global': {
        // '.logo': {
        //   position: 'absolute',
        //   left: 0,
        //   top: 0,
        //   opacity: 0,
        //   visibility: 'hidden',
        // },
        '.mask': {
          fill: 'none',
          stroke: '#fff',
          // strokeDasharray: 988.0, // 1119.16357421875,
          // strokeDashoffset: 988.0,
          strokeMiterlimit: 10,
        },
        // '@keyframes strokeOffset': {
        //   to: {
        //     strokeDashoffset: 0,
        //   },
        // },
        // '#mask-R': {
        //   animation: '$strokeOffset 1s linear forwards',
        // },
        // '.marketing-lab': {
        //   cursor: 'pointer',
        //   fill: '#d83e00',
        //   position: 'absolute',
        //   left: 0,
        //   top: 0,
        //   visibility: 'hidden',
        //   opacity: 0,
        // },
        // '.marketing-lab .mask': {
        //   fill: 'none',
        //   stroke: '#fff',
        //   strokeMiterlimit: 10,
        // },
      },
    })
);

const Intro = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const { introId } = useParams();

  const logo = useRef(null);
  const q = gsap.utils.selector(logo);

  const gotoNext = () => {
    const { lng, ...searchParams } = queryString.parse(search);
    const paramString = queryString.stringify({ ...searchParams });

    if (pathname === Routes.ROOT) {
      navigate({
        pathname: Routes.LANGUAGE,
        search: paramString,
      });
    } else {
      window.location.reload();
    }
  };

  const animation = () => {
    let tl = gsap.timeline({
      defaults: { duration: 0.3, ease: Linear.easeNone }, // Linear.easeNone Power4.easeInOut
    });

    const duration = introId ? parseInt(introId) / 10 : 1;

    tl.to(q('#mask-rundales'), { autoAlpha: 0, duration: 0 })
      .to(q('#mask-pils'), { autoAlpha: 0, duration: 0 })
      .to(q('#mask-muzejs'), { autoAlpha: 0, duration: 0 })
      .to(logo.current, { autoAlpha: 1 })
      .add('rundales')
      .fromTo(
        q('#mask-R'),
        {
          drawSVG: '0% 0%',
        },
        { drawSVG: true, duration },
        'rundales'
      )
      // .fromTo(
      //   q('#mask-rundales'),
      //   {
      //     drawSVG: '0% 0%',
      //   },
      //   { drawSVG: true },
      //   'rundales'
      // )
      .to(q('#mask-R'), { autoAlpha: 0.3, duration: 0.3 })
      .add('pils')
      .fromTo(
        q('#mask-P'),
        {
          drawSVG: '0% 0%',
        },
        { drawSVG: true, duration },
        'pils'
      )
      // .fromTo(
      //   q('#mask-pils'),
      //   {
      //     drawSVG: '0% 0%',
      //   },
      //   { drawSVG: true },
      //   'pils'
      // )
      .to(q('#mask-P'), { autoAlpha: 0.3, duration: 0.3 })
      .add('muzejs')
      .fromTo(
        q('#mask-M'),
        {
          drawSVG: '0% 0%',
        },
        { drawSVG: true, duration },
        'muzejs'
      )
      // .fromTo(
      //   q('#mask-muzejs'),
      //   {
      //     drawSVG: '0% 0%',
      //   },
      //   { drawSVG: true },
      //   'muzejs'
      // )
      .add('circle')
      .fromTo(
        q('#mask-inside'),
        {
          drawSVG: '0% 0%',
        },
        { drawSVG: true, duration }, // ease: Circ.easeInOut
        'circle'
      )
      .fromTo(
        q('#mask-outside'),
        {
          drawSVG: '0% 0%',
        },
        { drawSVG: true, duration }, // ease: Circ.easeInOut
        'circle'
      )
      // .add('fade')
      .to(q('#mask-R'), { autoAlpha: 1, duration: 0.3 }, 'circle')
      .to(q('#mask-P'), { autoAlpha: 1, duration: 0.3 }, 'circle')
      .to(q('#mask-rundales'), { autoAlpha: 1, duration: 0.7 }, 'circle')
      .to(q('#mask-pils'), { autoAlpha: 1, duration: 0.7 }, 'circle')
      .to(q('#mask-muzejs'), { autoAlpha: 1, duration: 0.7 }, 'circle')
      .to(logo.current, { autoAlpha: 0, duration: 0.3, onComplete: gotoNext }); // , '+=0.18'

    // return tl;
  };

  useEffect(() => {
    dispatch(resetData());
    // gsap.fromTo(
    //   logo.current,
    //   {
    //     opacity: 0,
    //     // scale: 1,
    //   },
    //   {
    //     // scale: 1.3,
    //     opacity: 1,
    //     duration: 2,
    //     // repeat: 1,
    //     // yoyo: true,
    //     onComplete: gotoNext,
    //   }
    // );
    gsap.registerPlugin(DrawSVGPlugin);
    gsap.set(logo.current, { autoAlpha: 0 });
    animation();

    // const masks = ['R'];

    // masks.forEach((mask, index, el) => {
    //   const id = `#mask-${mask}`; // Prepend #mask- to each mask element name
    //   let path: any = document.querySelector(id);
    //   const length = path.getTotalLength(); // Calculate the length of a path
    //   path.style.strokeDasharray = length; // Set the length to stroke-dasharray in the styles
    //   path.style.strokeDashoffset = length; // Set the length to stroke-dashoffset in the styles

    //   console.log('>>>>', length);
    // });
  }, [pathname]);

  return (
    <div className={classes.introContainerStyle}>
      <Logo className={classes.logoStyle} ref={logo} />
      <div className={classes.homeScreenStyle}></div>
    </div>
  );
};

export default Intro;
