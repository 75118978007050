import Automator from './Automator';
import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import AddToHomeScreen from '@ideasio/add-to-homescreen-react';
import ReactGA from 'react-ga4';

interface IProps {
  children: React.ReactNode;
}

const GlobalLayout = ({ children }: IProps): JSX.Element => {
  const location = useLocation();
  const { pathname, search } = location;

  const searchData = queryString.parse(search);
  const isStandalone = Boolean(searchData.standalone);

  useEffect(() => {
    const page = pathname + search;
    const title = isStandalone ? 'STENDS' : 'MOBILE';
    ReactGA.send({ hitType: 'pageview', page, title });
  }, [location]);

  const getAdditional = () => {
    if (isStandalone) {
      return <Automator />;
    }

    // return (
    //   <AddToHomeScreen appId={'rundalespils'} activateLogging startDelay={3} />
    // );

    return null;
  };

  return (
    <>
      {children}
      {getAdditional()}
    </>
  );
};

export default GlobalLayout;
