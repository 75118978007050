import * as C from '../common/constants';
import * as yaml from 'js-yaml';
import CircularProgress from '@mui/material/CircularProgress';
import CloseLayout from './CloseLayout';
import Flickity from 'react-flickity-component';
import QuizAnswer from './QuizAnswer';
import React, { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import UpButton from './UpButton';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { InfoButton } from '../common/enums';
import { RootState, AppDispatch } from '../redux/configureStore';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { fetchData, clearData } from '../common/app/actions';
import { getPath } from '../common/utils/helpers';
import { storeQuizID } from '../common/persistent/actions';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const urlPrefix = getPath();

const flickityOptions = {
  initialIndex: 0,
  draggable: false,
  freeScroll: false,
  prevNextButtons: false,
  // adaptiveHeight: true,
  accessibility: true,
};

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      surveyContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        background: COLORS.appBackground,
        fontFamily: FONTS.alegreya,
      },
      centerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        background: COLORS.appBackground,
      },
      sliderStyle: {
        width: '100%',
      },
      itemContainerStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px',
        background: COLORS.appBackground,
        boxSizing: 'border-box',
      },
      headerStyle: {
        fontSize: '22px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appBlack,
        lineHeight: '28px',
        marginBottom: '40px',
      },
      textStyle: {
        fontSize: '18px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appBlack,
        lineHeight: '24px',
        marginBottom: '6px',
      },
      inputContainerStyle: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '20px',
      },
      buttonStyle: {
        position: 'absolute',
        bottom: 0,
        border: `1px solid ${COLORS.appBlack}`,
        padding: '12px 24px 10px 24px',
        boxSizing: 'border-box',
        color: COLORS.appBlack,
        fontFamily: FONTS.alegreya,
        fontSize: '18px',
        lineHeight: '16px',
        whiteSpace: 'nowrap',
        marginBottom: '80px',
        // textTransform: 'uppercase',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            background: COLORS.appGold,
            transition: '0.5s ease',
          },
        },
      },
      '@global': {
        '.fcarousel': {
          width: '100%',
          display: 'flex',
          flex: 1,
        },
        '.flickity-viewport': {
          width: '100%',
          height: '100% !important',
        },
        '.flickity-page-dots': {
          bottom: '20px !important',
          pointerEvents: 'none !important',
        },
        // '& .Mui-focused': {
        //   borderColor: COLORS.appGold,
        // },
      },
      '@media screen and (max-device-width:360px)': {
        itemContainerStyle: {
          padding: '20px',
        },
      },
    })
);

const Quiz = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const lng = useSelector((state: RootState) => state.app.lng);
  const quizData = useSelector(
    (state: RootState) => state.app[lng]?.[C.QUIZ_DATA]
  );

  const [isLastSlide, setLastSlide] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const flickityRef: any = useRef();

  const completedQuiz =
    useSelector((state: RootState) => state.persistent.completedQuiz) || [];

  useEffect(() => {
    if (!quizData) {
      dispatch<AppDispatch>(fetchData(C.QUIZ_DATA, { quiz: true, lng }));
    }

    return () => {
      dispatch(clearData(C.QUIZ_DATA));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onNext = (isAnswerCorrect) => {
    if (!isAnswerCorrect) {
      setFailed(true);
    }

    if (isLastSlide) {
      const isQuizFailed = !isAnswerCorrect || isFailed;
      const currentQuizId = quizData?.content?.sid;

      const sid = quizData?.content?.sid;
      const completed = isQuizFailed ? 'failed' : 'success';
      const quizSID = `${sid}_${completed}`;

      ReactGA.event({
        category: InfoButton.QUIZ,
        action: currentQuizId,
        label: completed,
      });

      dispatch(storeQuizID(quizSID));
    } else {
      flickityRef?.current?.flkty?.next();
    }
  };

  const getVariant = (item, itemIndex, slideIndex) => {
    const answerData = {
      item,
      itemIndex,
    };

    return (
      <QuizAnswer
        data={quizData}
        isLastSlide={isLastSlide}
        slideIndex={slideIndex}
        answerData={answerData}
        onNext={onNext}
        key={itemIndex}
      />
    );
  };

  const getSlides = (item, index) => {
    const rawTitle = item.content.title;
    const title: string = rawTitle?.replace(/(?:\r\n|\r|\n)/g, '<br>');

    const tableRaw = item.content.table;
    const table = tableRaw && yaml.load(tableRaw);

    // console.log('>>>>', table, item.content);

    return (
      <div className={classes.itemContainerStyle} key={index}>
        <div
          className={classes.headerStyle}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        {table.map((item, itemIndex) => getVariant(item, itemIndex, index))}
      </div>
    );
  };

  const onFlickityLoad = (flickity) => {
    const totalSlides = quizData.pages.length;

    flickity.on('change', (slideNr) => {
      const isLastSlide = totalSlides - 1 === slideNr;

      if (isLastSlide) {
        setLastSlide(isLastSlide);
      }
    });
  };

  const getSlider = () => {
    return (
      // @ts-ignore
      <Flickity
        className={'fcarousel'} // default ''
        elementType={'div'} // default 'div'
        options={flickityOptions} // takes flickity options {}
        // disableImagesLoaded={false} // default false
        // reloadOnUpdate // default false
        // static // default false
        ref={flickityRef}
        flickityRef={onFlickityLoad}
      >
        {quizData?.pages?.map(getSlides)}
      </Flickity>
    );
  };

  const getContent = () => {
    if (!quizData) {
      return (
        <div className={classes.centerStyle}>
          <CircularProgress />
        </div>
      );
    }

    const currentQuizId = quizData?.content?.sid;

    const storedQuizId = completedQuiz?.find((item) =>
      item?.includes(currentQuizId)
    );

    const isSuccess = storedQuizId?.includes('_success');

    const title = quizData.content.header;

    if (storedQuizId) {
      const rawSuccess = isSuccess
        ? quizData.content.success
        : quizData.content.failed;
      const success: string = rawSuccess?.replace(/(?:\r\n|\r|\n)/g, '<br>');

      return (
        <>
          <UpButton title={title} isActive={true} />
          <div className={classes.itemContainerStyle}>
            <div
              className={classes.headerStyle}
              dangerouslySetInnerHTML={{
                __html: success,
              }}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <UpButton title={title} isActive={true} />
        {getSlider()}
      </>
    );
  };

  return (
    <div className={classes.helpContainerStyle}>
      <CloseLayout>{getContent()}</CloseLayout>
    </div>
  );
};

export default Quiz;
