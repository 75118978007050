import * as C from '../common/constants';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { getText, getPath } from '../common/utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { RootState, AppDispatch } from '../redux/configureStore';

const urlPrefix = getPath();

interface IProps {
  url: string;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      circleStyle: {
        position: 'relative',
        width: '30px',
        height: '30px',
        border: `1px solid ${COLORS.appBlack}`,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
        fontSize: '20px',
        lineHeight: '30px',
        boxSizing: 'border-box',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      playStyle: {
        marginLeft: 0,
        background: COLORS.appBackground,
      },
      activePlayStyle: {
        background: COLORS.appGold,
      },
      speedStyle: {
        background: COLORS.appBackground,
        paddingBottom: '2px',
        boxSizing: 'border-box',
      },
      activeSpeedStyle: {
        background: COLORS.appGold,
      },
      '@global': {
        '.rhap_container': {
          background: 'unset !important',
          boxShadow: 'unset !important',
          padding: '0 !important',
          '-webkit-appearance': 'none !important',
          appearance: 'none !important',
        },
        '.rhap_current-left-time': {
          minWidth: '50px',
          fontFamily: `${FONTS.alegreya} !important`,
          fontSize: '20px !important',
          marginBottom: '4px',
          textAlign: 'left',
          fontVariantNumeric: 'lining-nums',
        },
        '.rhap_time': {
          minWidth: '50px',
          fontFamily: `${FONTS.alegreya} !important`,
          fontSize: '20px !important',
          marginBottom: '4px',
          textAlign: 'left',
        },
        '.rhap_controls-section': {
          flex: 'unset !important',
        },
        '.rhap_progress-indicator': {
          background: `${COLORS.appBlack} !important`,
          width: '16px !important',
          height: '16px !important',
          top: '-8px !important',
        },
        '.rhap_progress-filled': {
          background: `${COLORS.appBlack} !important`,
          height: '3px !important',
          top: '-1px !important',
          left: 0,
        },
        '.rhap_progress-bar': {
          background: `${COLORS.appBlack} !important`,
          height: '1px !important',
        },
        '.rhap_play-pause-button': {
          margin: '0 10px 0 0 !important',
          width: '30px !important',
          height: '30px !important',
        },
        '.rhap_button-clear': {
          overflow: 'visible !important',
        },
      },
    })
);

const AudioPlayerWrapper = ({ url }: IProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lng = useSelector((state: RootState) => state.app.lng);
  const audioRef = useRef(null);

  const [isSpeed, setSpeed] = useState(false);

  useEffect(() => {
    if (isSpeed) {
      audioRef.current.audio.current.playbackRate = 1.3;
    } else {
      audioRef.current.audio.current.playbackRate = 1;
    }
  }, [isSpeed]);

  const onSpeedToggle = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    setSpeed(!isSpeed);
  };

  const fullUrl = `${urlPrefix}${url}`;

  const playIcon = () => {
    return (
      <div className={clsx(classes.circleStyle, classes.playStyle)}>
        <PlayArrowIcon className={classes.iconStyle} />
      </div>
    );
  };

  const pauseIcon = () => {
    return (
      <div
        className={clsx(
          classes.circleStyle,
          classes.playStyle,
          classes.activePlayStyle
        )}
      >
        <PauseIcon className={classes.iconStyle} />
      </div>
    );
  };

  return (
    <AudioPlayer
      autoPlay={false}
      autoPlayAfterSrcChange={false}
      defaultCurrentTime={'00:00'}
      // defaultDuration={'00:00'}
      timeFormat={'auto'}
      ref={audioRef}
      src={fullUrl}
      showSkipControls={false}
      showJumpControls={false}
      showDownloadProgress={false}
      layout={'horizontal-reverse'}
      showFilledProgress={true}
      customControlsSection={[
        RHAP_UI.MAIN_CONTROLS,
        RHAP_UI.ADDITIONAL_CONTROLS,
        // RHAP_UI.VOLUME_CONTROLS,
      ]}
      customProgressBarSection={[
        RHAP_UI.PROGRESS_BAR,
        RHAP_UI.CURRENT_LEFT_TIME,
      ]} // RHAP_UI.CURRENT_TIME
      customAdditionalControls={[
        // RHAP_UI.LOOP,
        <div
          className={clsx(
            classes.circleStyle,
            classes.speedStyle,
            isSpeed && classes.activeSpeedStyle
          )}
          onClick={onSpeedToggle}
        >
          {'2x'}
        </div>,
      ]}
      customIcons={{
        play: playIcon(),
        pause: pauseIcon(),
      }}
    />
  );
};

export default AudioPlayerWrapper;
