import React from 'react';
import ImageTargetMarker from './ImageTargetMarker';

interface IProps {
  data: any;
  onMarkerClick: (event: React.SyntheticEvent, markerData) => void;
  isMarkersSmall?: boolean;
}

const ImageTargetMarkers = ({
  data,
  onMarkerClick,
  isMarkersSmall,
}: IProps): JSX.Element => {
  const markersData = data?.content?.markers;

  return markersData.map((markerData, index) => (
    <ImageTargetMarker
      key={index}
      data={data}
      markerData={markerData}
      onMarkerClick={onMarkerClick}
      isMarkersSmall={isMarkersSmall}
    />
  ));
};

export default ImageTargetMarkers;
