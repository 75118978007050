import * as C from '../common/constants';
import AudioPlayerWrapper from './AudioPlayerWrapper';
import IdleTimerWrapper from './IdleTimerWrapper';
import LanguageStandaloneSelect from './LanguageStandaloneSelect';
import React, { useEffect, useState } from 'react';
import RoomInfoDialog from './RoomInfoDialog';
import RoomTitleInfo from './RoomTitleInfo';
import clsx from 'clsx';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { RootState, AppDispatch } from '../redux/configureStore';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { fetchData } from '../common/app/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

interface IProps {
  title: string;
  data: any;
  nr?: string;
  sibling?: any;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      roomTitleContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '10px 50px',
        boxSizing: 'border-box',
        background: COLORS.appBackground,
      },
      titleContainerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '6px',
      },
      titleWrapperStyle: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
      },
      nrStyle: {
        position: 'relative',
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
        fontWeight: FONT_WEIGHT.medium,
        fontSize: '16px',
        lineHeight: '22px',
        whiteSpace: 'nowrap',
        fontVariantNumeric: 'lining-nums',
      },
      titleStyle: {
        position: 'relative',
        width: '100%',
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
        fontWeight: FONT_WEIGHT.medium,
        fontSize: '22px',
        lineHeight: '24px',
        whiteSpace: 'normal',
        '& i': {
          fontStyle: 'italic',
        },
      },
      nrContainerStyle: {
        fontFamily: FONTS.alegreya,
        display: 'flex',
        flexDirection: 'row',
      },
      otherRoomStyle: {
        fontSize: '32px',
        lineHeight: '32px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        margin: '0 0 20px 0',
        padding: '6px 14px',
        boxSizing: 'border-box',
        border: `1px solid ${COLORS.appBlack}`,
        fontVariantNumeric: 'lining-nums',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      '@media screen and (max-device-width:360px)': {
        roomTitleContainerStyle: {
          padding: '10px 20px',
        },
        titleContainerStyle: {
          margin: 0,
        },
      },
      '@media screen and (orientation:landscape)': {
        roomTitleContainerStyle: {
          padding: '10px 30px',
          boxSizing: 'border-box',
          flexDirection: 'row',
          alignItems: 'center',
        },
        roomTitleStandaloneContainerStyle: {
          padding: '48px 120px 40px 120px',
          boxSizing: 'border-box',
        },
        titleContainerStyle: {
          minWidth: '60%',
          alignItems: 'flex-end',
          margin: 0,
        },
        titleWrapperStyle: {
          marginRight: '100px',
          flex: 'unset',
          display: 'flex',
        },
        titleContainerStandaloneStyle: {},
        nrStyle: {
          marginRight: '10px',
          marginTop: '1px',
          display: 'flex',
          alignItems: 'flex-start',
        },
        nrStandaloneStyle: {
          width: 'fit-content',
          fontSize: '32px',
          lineHeight: '32px',
          fontWeight: FONT_WEIGHT.medium,
          margin: '0 0 20px 0',
          marginRight: '30px',
        },
        nrSiblingStyle: {
          background: COLORS.appGold,
          padding: '6px 14px',
          border: `1px solid ${COLORS.appBlack}`,
        },
        titleStyle: {
          // width: 'auto',
          margin: 0,
        },
        titleStandaloneStyle: {
          fontSize: '70px',
          lineHeight: '70px',
          fontWeight: FONT_WEIGHT.medium,
        },
      },
    })
);

const RoomTitle = ({ title, data, nr, sibling }: IProps): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lng = useSelector((state: RootState) => state.app.lng);
  const translationsData = useSelector(
    (state: RootState) => state.app[lng]?.[C.TRANSLATIONS_DATA]
  );
  const { pathname, search } = useLocation();

  const [isInfoOpen, setInfo] = useState(false);

  const searchData = queryString.parse(search);
  const isStandalone = Boolean(searchData.standalone);

  useEffect(() => {
    if (!translationsData) {
      dispatch<AppDispatch>(
        fetchData(C.TRANSLATIONS_DATA, { translations: true, lng })
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!translationsData) {
    return null;
  }

  const onIdle = () => {
    setInfo(false);
  };

  const getAudioPlayer = () => {
    if (isStandalone || !data) {
      return null;
    }

    return <AudioPlayerWrapper url={data?.url} />;
  };

  const getRoomText = (passedRoomText, passedNr) => {
    return passedRoomText?.replace('{x}', passedNr);
  };

  const nrText = getRoomText(translationsData?.content?.room, nr);

  const onInfoClick = (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    event?.preventDefault();

    setInfo(true);
  };

  const onInfoClose = () => {
    setInfo(false);
  };

  const onSinglingClick = (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    event?.preventDefault();

    const siblingId: any = event?.currentTarget?.getAttribute('data-id');

    const paramString = queryString.stringify({
      ...searchData,
      room: siblingId,
    });

    navigate({
      pathname,
      search: paramString,
    });
  };

  const getRoomNumber = () => {
    if (!nr) {
      return null;
    }

    const hasSibling = Boolean(sibling);
    // const siblingLabel = hasSibling && sibling?.content?.header;
    const siblingNrText = getRoomText(
      translationsData?.content?.room,
      sibling?.content?.nr
    );

    return (
      <div className={classes.nrContainerStyle}>
        <div
          className={clsx(
            classes.nrStyle,
            isStandalone && classes.nrStandaloneStyle,
            isStandalone && hasSibling && classes.nrSiblingStyle
          )}
        >
          {nrText}
        </div>
        {hasSibling && isStandalone && (
          <div
            className={classes.otherRoomStyle}
            data-id={sibling?.content?.autoid || sibling?.content?.uuid}
            onClick={onSinglingClick}
          >
            {siblingNrText}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={clsx(
        classes.roomTitleContainerStyle,
        isStandalone && classes.roomTitleStandaloneContainerStyle
      )}
    >
      <div
        className={clsx(
          classes.titleContainerStyle,
          isStandalone && classes.titleContainerStandaloneStyle
        )}
      >
        <div className={classes.titleWrapperStyle}>
          {getRoomNumber()}
          <div
            className={clsx(
              classes.titleStyle,
              isStandalone && classes.titleStandaloneStyle
            )}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </div>
        {Boolean(title) && <RoomTitleInfo nr={nr} onInfoClick={onInfoClick} />}
      </div>
      {getAudioPlayer()}
      {isStandalone && <LanguageStandaloneSelect />}
      <RoomInfoDialog isOpen={isInfoOpen} onClose={onInfoClose} />
      {isStandalone && <IdleTimerWrapper onIdle={onIdle} />}
    </div>
  );
};

export default RoomTitle;
