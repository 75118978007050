import * as C from '../common/constants';
import * as yaml from 'js-yaml';
import CircularProgress from '@mui/material/CircularProgress';
import CloseLayout from './CloseLayout';
import React, { useState, useEffect, useRef } from 'react';
import TextBlock from './TextBlock';
import UpButton from './UpButton';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Routes } from '../common/routes';
import { fetchData } from '../common/app/actions';
import { getPath } from '../common/utils/helpers';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { RootState, AppDispatch } from '../redux/configureStore';

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      hoursContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        background: COLORS.appBackground,
      },
      centerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      },
      hoursWrapperStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '50px 50px 0 50px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.regular,
        color: COLORS.appBlack,
        fontSize: '18px',
        lineHeight: '24px',
        flex: 1,
        boxSizing: 'border-box',
      },
      noteContainerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '30px',
        fontWeight: FONT_WEIGHT.medium,
        boxSizing: 'border-box',
      },
      noteIconStyle: {
        maxHeight: '60px',
        paddingRight: '20px',
        boxSizing: 'border-box',
      },
      noteStyle: {
        // fontSize: '18px',
        // lineHeight: '24px',
      },
      timesContainerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      itemContainerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderTop: `1px solid ${COLORS.appBlack}`,
        padding: '20px 0 30px 0',
      },
      itemTitleStyle: {
        fontWeight: FONT_WEIGHT.medium,
        paddingBottom: '20px',
        boxSizing: 'border-box',
      },
      itemTimeContainerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '10px',
        '&:first-child': {
          padding: 0,
          fontWeight: FONT_WEIGHT.medium,
        },
      },
      monthStyle: {
        // width: '60%',
      },
      timeStyle: {
        // width: '40%',
        textAlign: 'right',
        fontVariantNumeric: 'lining-nums',
      },
      symbolsContainerStyle: {
        display: 'flex',
        flexDirection: 'row',
      },
      symbolStyle: {
        width: '14px',
        height: '14px',
        minWidth: '14px',
        border: `1px solid ${COLORS.appBlack}`,
        borderRadius: '50%',
        margin: '4px 4px 0 0',
      },
      activeSymbolStyle: {
        background: COLORS.appBlack,
      },
      '@media screen and (max-device-width:360px)': {
        hoursWrapperStyle: {
          padding: '20px',
        },
      },
    })
);

const Hours = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lng = useSelector((state: RootState) => state.app.lng);
  const hoursData = useSelector(
    (state: RootState) => state.app[lng]?.[C.HOURS_DATA]
  );

  useEffect(() => {
    if (!hoursData) {
      dispatch<AppDispatch>(fetchData(C.HOURS_DATA, { hours: true, lng }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSymbol = (item, index) => {
    const isActive = item === 'x';

    return (
      <div
        key={index}
        className={clsx(
          classes.symbolStyle,
          isActive && classes.activeSymbolStyle
        )}
      />
    );
  };

  const getSymbols = (symbols) => {
    const sanitizedSymbols = symbols.replace(/\s/g, '');
    const symbolsArray = sanitizedSymbols.split('');

    return (
      <div className={classes.symbolsContainerStyle}>
        {symbolsArray.map(getSymbol)}
      </div>
    );
  };

  const getTime = ({ symbols, title, text }, index) => {
    return (
      <div className={classes.itemTimeContainerStyle} key={index}>
        <>
          {Boolean(symbols) ? (
            getSymbols(symbols)
          ) : (
            <div className={classes.monthStyle}>{title}</div>
          )}
        </>
        <div className={classes.timeStyle}>{text}</div>
      </div>
    );
  };

  const getTimes = (item, index) => {
    // const rawText = item.content.text;
    // const text: string = rawText?.replace(/(?:\r\n|\r|\n)/g, '<br>');
    // const headerLabel = item.content.header;

    const tableRaw = item.content.table;
    const table = tableRaw && yaml.load(tableRaw);
    const hasTable = Boolean(table.length);

    return (
      <div className={classes.itemContainerStyle} key={index}>
        {/* <div className={classes.itemTitleStyle}>{headerLabel}</div> */}
        {hasTable && table.map(getTime)}
      </div>
    );
  };

  const getContent = () => {
    if (!hoursData) {
      <div className={classes.centerStyle}>
        <CircularProgress />
      </div>;
    }

    const headerLabel = hoursData?.content.header;
    const rawText = hoursData?.content.text;
    const text: string = rawText?.replace(/(?:\r\n|\r|\n)/g, '<br>');

    const hasTimes: boolean = Boolean(hoursData?.pages?.length);
    const hasIcon: boolean = Boolean(hoursData?.svg?.length);
    const imgUrl: string = hasIcon && `${urlPrefix}${hoursData?.svg?.[0].url}`;

    return (
      <>
        <UpButton title={headerLabel} isActive={true} />
        <div className={classes.hoursWrapperStyle}>
          <div className={classes.noteContainerStyle}>
            {hasIcon && (
              <img className={classes.noteIconStyle} src={imgUrl} alt="" />
            )}
            <div className={classes.noteStyle}>{text}</div>
          </div>
          {hasTimes && (
            <div className={classes.timesContainerStyle}>
              {hoursData?.pages.map(getTimes)}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={classes.hoursContainerStyle}>
      <CloseLayout>{getContent()}</CloseLayout>
    </div>
  );
};

export default Hours;
