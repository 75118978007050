import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

interface IProps {
  customWrapperClass?: string;
  customContainerClass?: string;
  text?: string;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      navigationNoteContainerStyle: {
        position: 'absolute',
        width: '100%',
        bottom: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      navigationNoteWrapperStyle: {
        padding: '20px 30px',
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
        fontWeight: FONT_WEIGHT.medium,
        fontStyle: 'italic',
        fontSize: '20px',
        textAlign: 'center',
        boxSizing: 'border-box',
      },
      navigationNoteWrapperStandaloneStyle: {
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
          fontSize: '32px',
          maxWidth: '350px',
        },
      },
      hiddenNoteStyle: {
        opacity: 0,
        transition: '1s ease',
      },
    })
);

const NavigationNote = ({
  customContainerClass,
  customWrapperClass,
  text,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const { pathname, search } = useLocation();

  const searchData = queryString.parse(search);
  const isStandalone = Boolean(searchData.standalone);

  const currentText = text;

  const [isNoteVisible, setNote] = useState(true);

  const closeNote = () => {
    setNote(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => closeNote(), 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      className={clsx(
        classes.navigationNoteContainerStyle,
        !isNoteVisible && classes.hiddenNoteStyle,
        isStandalone && classes.navigationNoteWrapperStandaloneStyle,
        customContainerClass
      )}
    >
      <div
        className={clsx(classes.navigationNoteWrapperStyle, customWrapperClass)}
        dangerouslySetInnerHTML={{
          __html: currentText,
        }}
      />
    </div>
  );
};

export default NavigationNote;
