import * as C from '../common/constants';
import HamburgerAndClose from './HamburgerAndClose';
import React from 'react';
import clsx from 'clsx';
import queryString from 'query-string';
import { Arrow } from '../common/enums';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as IconHome } from '../assets/svg/icon_home.svg';
import { ReactComponent as IconLeft } from '../assets/svg/icon_arrow_left.svg';
import { ReactComponent as IconMarker } from '../assets/svg/icon_marker.svg';
import { ReactComponent as IconMobile } from '../assets/svg/icon_mobile.svg';
import { ReactComponent as IconRight } from '../assets/svg/icon_arrow_right.svg';
import { Routes } from '../common/routes';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRoomSwitch } from '../hooks';
import { useSelector, useDispatch } from 'react-redux';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { RootState } from '../redux/configureStore';
import { useHiddenRoomNr } from '../hooks/useHiddenRoomNr';
import {
  clearData,
  showRightMenu,
  setGeoStatus,
  showStandaloneLanguages,
} from '../common/app/actions';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      headerContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        background: COLORS.appBlack,
      },
      headerWrapperStyle: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: COLORS.appBlack,
        transition: '.5s ease-in-out',
        margin: 0,
      },
      homeContainerStyle: {
        width: '50px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      homeStyle: {
        width: '16px',
        fill: COLORS.appBackground,
      },
      centerStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
      markerStyle: {
        width: '16px',
        height: '26px',
        fill: COLORS.appBackground,
        margin: '13px',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      hammburgerContainerStyle: {
        width: '50px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      openStyle: {
        left: '-100%',
        marginLeft: '50px',
      },
      geoEnabledStyle: {
        fill: COLORS.appLakeBlue,
      },
      arrowStyle: {
        width: '22px',
        height: '50px',
        margin: '0 10px',
        fill: COLORS.appBackground,
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      '@media screen and (orientation:landscape)': {
        headerWrapperStyle: {
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
        homeContainerStyle: {
          width: '100%',
          height: '120px',
        },
        homeStyle: {
          width: '32px',
          fill: COLORS.appBackground,
        },
        hammburgerContainerStyle: {
          width: '100%',
          height: '120px',
        },
        markerStyle: {
          width: '35px',
          height: '56px',
          fill: COLORS.appBackground,
        },
        openStyle: {
          marginLeft: '120px',
        },
        centerStyle: {
          flexDirection: 'column',
        },
        markerContainerStandaloneStyle: {
          width: '100%',
          height: '50%',
          borderBottom: `1px solid ${COLORS.appBackground}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          '@media (hover: hover) and (pointer: fine)': {
            '&:hover': {
              opacity: '0.7',
              transition: '0.5s ease',
            },
          },
        },
        iconContainerStandaloneStyle: {
          width: '100%',
          height: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          '@media (hover: hover) and (pointer: fine)': {
            '&:hover': {
              opacity: '0.7',
              transition: '0.5s ease',
            },
          },
        },
        mobileStandaloneStyle: {
          width: '41px',
          height: '75px',
          fill: COLORS.appBackground,
        },
        arrowStyle: {
          width: '32px',
        },
        [`@media (max-width: 1200px)`]: {
          homeContainerStyle: {
            width: '100%',
            height: '60px',
          },
          homeStyle: {
            width: '16px',
            fill: COLORS.appBackground,
          },
          hammburgerContainerStyle: {
            width: '100%',
            height: '60px',
          },
          openStyle: {
            marginLeft: '60px',
          },
          markerStyle: {
            width: '16px',
            height: '26px',
            fill: COLORS.appBackground,
          },
          arrowStyle: {
            width: '22px',
          },
        },
      },
    })
);

const Header = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const isRightMenuVisible = useSelector(
    (state: RootState) => state.app.isRightMenuVisible
  );
  const searchData = useSelector(
    (state: RootState) => state.app[C.SEARCH_DATA]
  );
  const isGeoEnabled = useSelector(
    (state: RootState) => state.app.isGeoEnabled
  );

  const { currentExposition } = useHiddenRoomNr();

  const { onRoomSwitch } = useRoomSwitch();

  const isExpositions: boolean = pathname.includes(Routes.EXPOSITIONS);
  const hasMultipleRooms: boolean = currentExposition?.pages?.length > 1;

  const searchParams = queryString.parse(search);
  const isStandalone = Boolean(searchParams.standalone);

  const onHome = (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    event?.preventDefault();

    const searchParams = queryString.parse(search);
    const { room, ...rest } = searchParams;
    const paramString = queryString.stringify({ ...rest });

    navigate({
      pathname: Routes.EXCURSIONS,
      search: paramString,
    });

    if (isRightMenuVisible) {
      dispatch(showRightMenu(false));
    }
  };

  const onHamburgerClick = (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    event?.preventDefault();

    // clear search data on right menu close
    if (isRightMenuVisible && searchData) {
      dispatch(clearData(C.SEARCH_DATA));

      const searchAsObject = queryString.parse(search);
      const { input, ...newQuery } = searchAsObject;
      const newSearch = queryString.stringify(newQuery);

      navigate({
        pathname,
        search: newSearch,
      });
    }

    dispatch(showRightMenu(!isRightMenuVisible));
  };

  const onMarkerClick = (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    event?.preventDefault();

    if (isRightMenuVisible) {
      dispatch(showRightMenu(false));
    }

    if (isExpositions) {
      if (isStandalone) {
        dispatch(showStandaloneLanguages());
      }

      navigate({
        pathname: Routes.INSIDE,
        search,
      });
    } else {
      dispatch(setGeoStatus(!isGeoEnabled));
    }
  };

  const onMobileClick = (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    event?.preventDefault();

    dispatch(showStandaloneLanguages());

    navigate({
      pathname: Routes.MOBILE,
      search,
    });
  };

  const getHeaderIcons = () => {
    if (isStandalone) {
      return (
        <>
          <div
            className={classes.markerContainerStandaloneStyle}
            onClick={onMarkerClick}
          >
            <IconMarker
              className={clsx(
                classes.markerStyle,
                isGeoEnabled && classes.geoEnabledStyle
              )}
            />
          </div>

          <div
            className={classes.iconContainerStandaloneStyle}
            onClick={onMobileClick}
          >
            <IconMobile className={classes.mobileStandaloneStyle} />
          </div>
        </>
      );
    }

    return (
      <>
        <div className={classes.homeContainerStyle} onClick={onHome}>
          <IconHome className={classes.homeStyle} />
        </div>

        <div className={classes.centerStyle}>
          {hasMultipleRooms && (
            <>
              {isExpositions && (
                <IconLeft
                  className={classes.arrowStyle}
                  onClick={onRoomSwitch}
                  data-id={Arrow.PREV}
                />
              )}
              <IconMarker
                className={clsx(
                  classes.markerStyle,
                  isGeoEnabled && classes.geoEnabledStyle
                )}
                onClick={onMarkerClick}
              />
              {isExpositions && (
                <IconRight
                  className={classes.arrowStyle}
                  onClick={onRoomSwitch}
                  data-id={Arrow.NEXT}
                />
              )}
            </>
          )}
        </div>

        <div
          className={classes.hammburgerContainerStyle}
          onClick={onHamburgerClick}
        >
          <HamburgerAndClose />
        </div>
      </>
    );
  };

  return (
    <div className={classes.headerContainerStyle}>
      <div
        className={clsx(
          classes.headerWrapperStyle,
          isRightMenuVisible && classes.openStyle
        )}
      >
        {getHeaderIcons()}
      </div>
    </div>
  );
};

export default Header;
