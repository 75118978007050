import * as C from '../common/constants';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import GeoLocation from './GeoLocation';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import MarkerLocal from './MarkerLocal';
import { RootState, AppDispatch } from '../redux/configureStore';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      markersContainerStyle: {
        position: 'absolute',
      },
      markersWrapperStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        transform: `rotate(${C.MAP_ROTATION_ANGLE}deg)`,
        left: '-80px',
        top: '-74px',
      },
    })
);

const MarkersLocal = ({ outsideData, scale }: any): JSX.Element => {
  const classes = useStyles();
  const settingsData = useSelector(
    (state: RootState) => state.app[C.SETTINGS_DATA]
  );
  const isGeoEnabled = useSelector(
    (state: RootState) => state.app.isGeoEnabled
  );

  const getMarkers = () => {
    const markers = [];

    outsideData.forEach((map) => {
      const mapMarkers = map?.pages; // markers

      mapMarkers.forEach((currentMarker) => {
        const hasSubPages = Boolean(currentMarker?.pages?.length);

        if (scale > 6 && hasSubPages) {
          currentMarker.pages?.forEach((subMarker) => {
            const hasSubSubPages = Boolean(subMarker?.pages?.length);

            if (scale > 10 && hasSubSubPages) {
              subMarker.pages?.forEach((subSubMarker) => {
                markers.push(subSubMarker);
              });
            } else {
              markers.push(subMarker);
            }
          });
        } else {
          markers.push(currentMarker);
        }
      });

      //
    });

    return markers;
  };

  const markers = getMarkers();

  return (
    <div
      className={classes.markersContainerStyle}
      style={{
        width: `${C.MAP_ROTATED_SIZE.width}px`,
        height: `${C.MAP_ROTATED_SIZE.height}px`,
      }}
    >
      <div className={classes.markersWrapperStyle}>
        {isGeoEnabled && <GeoLocation />}
        {markers.map((marker, index) => {
          return (
            <MarkerLocal
              key={index}
              marker={marker}
              settings={settingsData.content}
              scale={scale}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MarkersLocal;
