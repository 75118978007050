import * as C from '../common/constants';
import CloseLayout from './CloseLayout';
import React, { useState, useEffect, useRef } from 'react';
import TextBlock from './TextBlock';
import UpButton from './UpButton';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';

interface IProps {
  data?: any;
  onClose?: (event: React.SyntheticEvent) => void;
  subTitle?: string;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      storyContainerStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        background: COLORS.appBackground,
      },
    })
);

const Story = ({ data, onClose, subTitle }: IProps): JSX.Element => {
  const classes = useStyles();

  const header = data?.content?.header;

  const [isScrolled, setScroll] = useState(false);

  const onScroll = (event: any) => {
    const scrollPos = event.target?.scrollTop;
    const isScroll = scrollPos > 70;

    setScroll(isScroll);
  };

  const getContent = () => {
    return (
      <>
        <UpButton
          title={header || subTitle}
          isActive={true}
          isScrolled={isScrolled}
        />
        <TextBlock
          data={data}
          noHeader
          subTitle={!header ? '' : subTitle}
          onScroll={onScroll}
        />
      </>
    );
  };

  const getLayout = () => {
    if (!onClose) {
      return getContent();
    }

    return <CloseLayout onClose={onClose}>{getContent()}</CloseLayout>;
  };

  return <div className={classes.storyContainerStyle}>{getLayout()}</div>;
};

export default Story;
