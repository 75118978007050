import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../redux/configureStore';
import { storeFavourite } from '../common/persistent/actions';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      favouriteIconStyle: {
        margin: '14px 12px 10px 12px',
        width: '24px',
        height: '24px',
        fill: `${COLORS.appGold} !important`,
        pointerEvents: 'all',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
    })
);

const FavouriteIcon = ({ data }): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lng: any = useSelector((state: RootState) => state.app.lng);
  const favourites = useSelector(
    (state: RootState) => state.persistent.favourites
  );

  const isFilled = () => {
    return favourites.includes(data?.content?.uuid || data?.content?.uri);
  };

  const [isIconFilled, setIcon] = useState(isFilled);

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isIconFilled) {
      setIcon(!isIconFilled);

      dispatch(
        storeFavourite({
          id: data?.content?.uuid || data?.content?.uri,
          enabled: true,
        })
      );

      ReactGA.event('LIKE', {
        category: 'LIKE',
        action: data?.content?.uri,
        label: data?.content?.title,
        lng,
      });
    }
  };

  const Icon = isIconFilled ? FavoriteIcon : FavoriteBorderIcon;

  return <Icon className={classes.favouriteIconStyle} onClick={onClick} />;
};

export default FavouriteIcon;
