import * as C from '../constants';

const INITIAL_STATE = Object.freeze({
  favourites: [],
  completedSurveys: [],
  completedQuiz: [],
});

const getFavourites = (existingFavourites, currentFavourite) => {
  const filteredFavourites = existingFavourites.filter(
    (item) => item !== currentFavourite.id
  );
  if (currentFavourite?.enabled) {
    return [...filteredFavourites, currentFavourite.id];
  }

  return filteredFavourites;
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case C.SET_FAVOURITE: {
      const existingFavourites = state.favourites;
      const currentFavourite = action.payload;

      const favourites = getFavourites(existingFavourites, currentFavourite);

      return {
        ...state,
        favourites,
      };
    }
    case C.SET_SURVEY_ID: {
      const existingSurveys = state.completedSurveys || [];
      const currentSurveyID = action.payload;

      const completedSurveys = [...existingSurveys, currentSurveyID];

      return {
        ...state,
        completedSurveys,
      };
    }
    case C.SET_QUIZ_ID: {
      const existingQuiz = state.completedQuiz || [];
      const currentQuizID = action.payload;

      const completedQuiz = [...existingQuiz, currentQuizID];

      return {
        ...state,
        completedQuiz,
      };
    }
    default:
      return state;
  }
};
