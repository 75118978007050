import * as C from '../common/constants';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Routes } from '../common/routes';
import { useNavigate, useLocation } from 'react-router-dom';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { showRightMenu } from '../common/app/actions';
import { Lng, AlertType } from '../common/enums';
import queryString from 'query-string';
import { setLanguage } from '../common/app/actions';

interface IProps {
  onActive?: () => void;
  onAction?: () => void;
  onIdle?: () => void;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      idleTimerContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        pointerEvents: 'none',
      },
    })
);

const IdleTimeWrapper = ({
  onActive,
  onAction,
  onIdle,
}: IProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { pathname, search } = useLocation();

  const handleOnIdle = (event) => {
    // console.log('user is idle', event);
    // console.log('last active', getLastActiveTime());

    onIdle?.();

    // if (pathname === !Routes.STANDALONE_LANGUAGE) {
    // navigate({
    //   pathname: `${Routes.EXPOSITIONS}${Routes.BASE}`,
    //   search,
    // });
    // }

    // dispatch(showRightMenu(false));
    const lng = Lng.LV;
    dispatch(setLanguage(lng));

    const searchParams = queryString.parse(search);
    const paramString = queryString.stringify({ ...searchParams, lng });

    navigate({
      pathname, //  `${Routes.EXPOSITIONS}${Routes.BASE}`,
      search: paramString,
    });
  };

  const handleOnActive = (event) => {
    // console.log('user is active', event);
    // console.log('time remaining', getRemainingTime());

    onActive?.();
  };

  const handleOnAction = (event) => {
    // console.log('user did something', event);
    onAction?.();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: C.IDLE_TIMER,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  return <div className={classes.idleTimerContainerStyle} />;
};

export default IdleTimeWrapper;
