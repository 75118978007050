import * as C from '../constants';
import { Lng, BlockType } from '../enums';
import queryString from 'query-string';

const getInitialLanguage = () => {
  const urlHash = window.location.hash;
  const urlParams = urlHash?.split('?');
  const searchParams = queryString.parse(urlParams?.[1]);

  if (urlHash && urlParams && searchParams && searchParams.lng) {
    return searchParams.lng;
  }

  return Lng.LV;
};

const INITIAL_STATE = Object.freeze({
  lng: getInitialLanguage(),
  errorData: [],
  isRightMenuVisible: false,
  isGeoEnabled: false,
  activeMarker: null,
  alertType: null,
  textSize: BlockType.SMALL,
  isStandaloneLanguagesVisible: false,
});

// eslint-disable-next-line
export default (state = INITIAL_STATE, action) => {
  const lng = action?.lng || Lng.LV;
  const currentData = state?.[lng] ? state?.[lng] : {};

  switch (action.type) {
    case C.ALERTS_DATA:
    case C.EXCURSIONS_DATA:
    case C.HELP_DATA:
    case C.HOURS_DATA:
    case C.INSIDE_DATA:
    case C.MAIN_DATA:
    case C.MOBILE_DATA:
    case C.OUTSIDE_DATA:
    case C.SURVEY_DATA:
    case C.QUIZ_DATA:
    case C.TRANSLATIONS_DATA: {
      return {
        ...state,
        [lng]: {
          ...currentData,
          [action.type]: action.payload,
        },
      };
    }
    case C.RESET_ALL_DATA: {
      return {
        ...INITIAL_STATE,
      };
    }
    case C.SET_LANGUAGE: {
      return {
        ...state,
        lng: action.payload,
      };
    }
    case C.STORE_ERROR: {
      const existingErrors = state.errorData;
      const latestError = [action.payload];
      const errorData = [...existingErrors, ...latestError];

      return {
        ...state,
        errorData,
      };
    }
    case C.CLOSE_ERROR: {
      const existingErrors = state.errorData;
      // eslint-disable-next-line
      const removedError = existingErrors.shift();
      const errorData = [...existingErrors];

      return {
        ...state,
        errorData,
      };
    }
    case C.SEARCH_DATA:
    case C.MEDIA_PLAYER_DATA:
    case C.LANGUAGES_DATA:
    case C.SETTINGS_DATA: {
      return {
        ...state,
        [action.type]: action.payload,
      };
    }
    case C.SET_RIGHT_MENU: {
      return {
        ...state,
        isRightMenuVisible: action.payload,
      };
    }
    case C.SET_GEO_STATUS: {
      return {
        ...state,
        isGeoEnabled: action.payload,
      };
    }
    case C.SET_ACTIVE_MARKER: {
      return {
        ...state,
        activeMarker: action.payload,
      };
    }
    case C.CLEAR_ACTIVE_MARKER: {
      return {
        ...state,
        activeMarker: null,
      };
    }
    case C.SHOW_ALERT: {
      return {
        ...state,
        alertType: action.payload,
      };
    }
    case C.SET_TEXT_SIZE: {
      return {
        ...state,
        textSize: action.payload,
      };
    }
    case C.SHOW_STANDALONE_LANGUAGES: {
      return {
        ...state,
        isStandaloneLanguagesVisible: action.payload,
      };
    }
    default:
      return state;
  }
};
