import React from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { showStandaloneLanguages } from '../common/app/actions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../redux/configureStore';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      lngContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        width: '56px',
        height: '56px',
        minWidth: '56px',
        minHeight: '56px',
      },
      lngStyle: {
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
        fontWeight: FONT_WEIGHT.medium,
        position: 'relative',
        width: '56px',
        height: '56px',
        minWidth: '56px',
        minHeight: '56px',
        background: COLORS.appBackground,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '32px',
        lineHeight: '32px',
        marginLeft: '10px',
        textTransform: 'uppercase',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
        '& > span': {
          marginTop: '2px',
        },
      },
    })
);

const LanguageStandaloneSelect = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lng = useSelector((state: RootState) => state.app.lng);

  const onClick = (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    event?.preventDefault();

    dispatch(showStandaloneLanguages(true));
  };

  const getLngCode = (lngKey) => {
    if (lngKey === 'li') {
      return 'lt';
    }

    return lngKey;
  };

  const lngCode = getLngCode(lng);

  return (
    <div className={classes.lngContainerStyle}>
      <div onClick={onClick} className={classes.lngStyle}>
        <span>{lngCode}</span>
        <KeyboardArrowDownIcon />
      </div>
    </div>
  );
};

export default LanguageStandaloneSelect;
