import * as C from '../common/constants';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import RoomInfo from './RoomInfo';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      buttonsContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top',
      },
      dialogContainerStyle: {
        '& > div > div': {
          width: '100%',
          height: '100%',
          background: COLORS.white,
        },
      },
      dialogContentStyle: {
        margin: '0 16px',
        color: COLORS.appBlack,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflow: 'hidden',
      },
    })
);

const StoryButtons = ({ onClose, isOpen }): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <Dialog
        fullScreen={true}
        open={isOpen}
        onClose={onClose}
        className={classes.dialogContainerStyle}
      >
        <MuiDialogContent className={classes.dialogContentStyle}>
          <RoomInfo onClose={onClose} />
        </MuiDialogContent>
      </Dialog>
    </>
  );
};

export default StoryButtons;
