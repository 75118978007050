import * as C from '../common/constants';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { RootState, AppDispatch } from '../redux/configureStore';
import { fetchDynamicData } from '../common/app/actions';
import { useHiddenRoomNr } from '../hooks/useHiddenRoomNr';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useRef } from 'react';

const serverUrl = 'https://digitalaisgids.rundale.net/admin';

const Automator = (): JSX.Element => {
  const dispatch = useDispatch();
  const mediaPlayerData = useSelector(
    (state: RootState) => state.app?.[C.MEDIA_PLAYER_DATA]
  );
  const hookData = useHiddenRoomNr();

  const [audioUrl, setAudioUrl] = useState(null);
  const audioRef = useRef(null);

  const fetchInterval = () => {
    dispatch<AppDispatch>(
      fetchDynamicData(C.MEDIA_PLAYER_DATA, { mediaplayer: true }, serverUrl)
    );
  };

  const getMediaRooms = () => {
    const rooms = mediaPlayerData?.content?.rooms;
    if (rooms) {
      return rooms.split(',').map((item) => item.trim());
    }

    return [];
  };

  useEffect(() => {
    if (mediaPlayerData && hookData?.currentRoomContent && !audioUrl) {
      const currentRoomNr = hookData?.currentRoomContent?.room;
      const mediaRooms = getMediaRooms();
      const isMediaPlayerEnabled = mediaRooms.includes(currentRoomNr);

      if (isMediaPlayerEnabled) {
        const url = mediaPlayerData?.audio?.[0].url;
        const fullUrl = `${serverUrl}${url}`;
        setAudioUrl(fullUrl);
      }
    }
  }, [hookData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!mediaPlayerData) {
      fetchInterval();
    }

    // const timer = setInterval(fetchInterval, 1000 * 5 * 60);
    // return () => clearInterval(timer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (audioUrl) {
  //     const currentItem: any = audioRef?.current;
  //     currentItem.play().catch((error) => {
  //       document.addEventListener(
  //         'click',
  //         () => {
  //           currentItem.play();
  //         },
  //         { once: true }
  //       );
  //     });
  //   }
  // }, [audioUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!mediaPlayerData || !hookData || !audioUrl) {
    return null;
  }

  // return (
  //   <>
  //     <audio ref={audioRef}>
  //       <source src={audioUrl} type="audio/mp3" />
  //     </audio>
  //   </>
  // );

  return (
    <AudioPlayer
      autoPlay={true}
      autoPlayAfterSrcChange={true}
      loop
      // defaultCurrentTime={'00:00'}
      // defaultDuration={'00:00'}
      timeFormat={'auto'}
      ref={audioRef}
      src={audioUrl}
      showSkipControls={false}
      showJumpControls={false}
      showDownloadProgress={false}
      showFilledProgress={false}
      customControlsSection={
        [
          // RHAP_UI.MAIN_CONTROLS,
          // RHAP_UI.ADDITIONAL_CONTROLS,
          // RHAP_UI.VOLUME_CONTROLS,
        ]
      }
      customProgressBarSection={
        [
          // RHAP_UI.PROGRESS_BAR,
          // RHAP_UI.CURRENT_TIME
        ]
      }
    />
  );
};

export default Automator;
