import * as C from '../common/constants';
import IdleTimerWrapper from './IdleTimerWrapper';
import ImageTargetMarkers from './ImageTargetMarkers';
import React, { useRef, useEffect, useState } from 'react';
import WallItem from './WallItem';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { RootState, AppDispatch } from '../redux/configureStore';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { getPath } from '../common/utils/helpers';
import { gsap, Linear, Power4, Circ } from 'gsap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      wallContainerStyle: {
        // width: '100%',
        height: '100%',
        float: 'left',
        // display: 'inline-block',
        maxWidth: 'fit-content',
        opacity: 0,
        // animation: `$fadeAnimation 2s`,
      },
      imageStyle: {
        // width: '100%',
        height: '100%',
        // display: 'inline-block',
        // objectFit: 'cover',
        // objectPosition: 'center',
        borderRight: `4px solid ${COLORS.appBackground}`,
      },
      // '@keyframes fadeAnimation': {
      //   from: { opacity: 0 },
      //   to: { opacity: 1 },
      // },
      '@global': {
        '.carousel.carousel-slider': {
          overflow: 'unset !important',
        },
        '.carousel .slider-wrapper': {
          overflow: 'unset !important',
        },
      },
    })
);

const Wall = ({ data }) => {
  const classes = useStyles();
  const lng = useSelector((state: RootState) => state.app.lng);
  const { search } = useLocation();

  const searchParams = queryString.parse(search);
  const isStandalone = Boolean(searchParams.standalone);

  const settingsData = useSelector(
    (state: RootState) => state.app[C.SETTINGS_DATA]
  );

  const VISIBLE_MARKERS = settingsData?.content?.circle_languages
    ?.replace(/\s/g, '')
    ?.split(';');

  const [itemData, setItemData] = useState(null);

  const [width, setWidth] = useState(null);
  const ref = useRef(null);

  const container = useRef(null);
  const q = gsap.utils.selector(container);

  const [image] = data.images;
  const imgUrl: string = `${urlPrefix}${image.url}`;

  let tl = gsap.timeline({
    defaults: { duration: 1, ease: Linear.easeNone },
  });

  const animation = () => {
    tl.to(container.current, { autoAlpha: 1, duration: 1 });
  };

  // let animated = false;

  // useEffect(() => {
  //   if (width && !animated) {
  //     animated = true;
  //     gsap.set(container.current, { autoAlpha: 0 });
  //     animation();
  //   }
  // }, [width]);

  const handleImageLoad = (event) => {
    const imageWidth = event.target.clientWidth;
    setWidth(imageWidth);

    gsap.set(container.current, { autoAlpha: 0 });
    animation();
    // console.log('>>>LOAD', imageWidth);
  };

  // useEffect(() => {
  //   const detectedWidth = ref.current?.clientWidth;

  //   if (detectedWidth) {
  //     setWidth(detectedWidth);
  //   }
  //   console.log('REF', detectedWidth);
  // }, [ref.current]); // data, ref.current

  const closeWallItem = (event?: React.SyntheticEvent) => {
    event?.preventDefault();
    event?.stopPropagation();

    setItemData(null);
  };

  const onMarkerClick = (event: React.SyntheticEvent, markerData) => {
    event.preventDefault();
    event.stopPropagation();

    const markerId = markerData.markerid;
    const markerItemData = data.pages.find(
      (item) => item.content.id === markerId
    );

    setItemData(markerItemData);
  };

  const onIdle = () => {
    closeWallItem();
  };

  const hasMarkers =
    Boolean(data.content?.markers?.length) && VISIBLE_MARKERS?.includes(lng);

  return (
    <div className={classes.wallContainerStyle} ref={container}>
      {hasMarkers && (
        <ImageTargetMarkers data={data} onMarkerClick={onMarkerClick} />
      )}
      <img
        src={imgUrl}
        alt=""
        className={classes.imageStyle}
        onLoad={handleImageLoad}
        ref={ref}
        style={{ width: `${width}px` }}
      />
      {itemData && <WallItem data={itemData} onClose={closeWallItem} />}
      {isStandalone && <IdleTimerWrapper onIdle={onIdle} />}
    </div>
  );
};

export default Wall;
