import * as C from '../common/constants';
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { RootState, AppDispatch } from '../redux/configureStore';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { fetchData } from '../common/app/actions';
import { setLanguage, showStandaloneLanguages } from '../common/app/actions';
import { sortLanguages } from '../common/utils/helpers';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      containerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
      },
      wrapperStyle: {
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '240px',
        height: '100%',
        boxSizing: 'border-box',
        padding: '80px',
        background: COLORS.appBackground,
      },
      bgStyle: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0,0.8)',
      },
      lngStyle: {
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
        fontWeight: FONT_WEIGHT.medium,
        fontSize: '22px',
        lineHeight: '22px',
        marginBottom: '40px',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
    })
);

const LanguageStandaloneChooser = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const languagesData = useSelector(
    (state: RootState) => state.app[C.LANGUAGES_DATA]
  );
  const settingsData = useSelector(
    (state: RootState) => state.app[C.SETTINGS_DATA]
  );

  useEffect(() => {
    if (!languagesData) {
      dispatch<AppDispatch>(fetchData(C.LANGUAGES_DATA, { languages: true }));
    }

    if (!settingsData) {
      dispatch<AppDispatch>(fetchData(C.SETTINGS_DATA, { settings: true }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!languagesData || !settingsData) {
    return null;
  }

  const onLngClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const newLng = event.currentTarget.getAttribute('data-lng');
    dispatch(setLanguage(newLng));

    const searchParams = queryString.parse(search);
    const paramString = queryString.stringify({ ...searchParams, lng: newLng });

    navigate(
      {
        pathname,
        search: paramString,
      },
      { replace: true }
    );

    dispatch(showStandaloneLanguages());
  };

  const onBgClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(showStandaloneLanguages());
  };

  const getList = () => {
    const sortedLanguageData = sortLanguages(languagesData);

    const { vi, ...restLng } = sortedLanguageData;

    return Object.keys(restLng).map((lngKey, index) => {
      const languageName = languagesData[lngKey];
      const isLanguageVisible = settingsData.content.languages.includes(lngKey);

      if (!isLanguageVisible) {
        return null;
      }

      return (
        <div
          className={classes.lngStyle}
          key={index}
          data-lng={lngKey}
          onClick={onLngClick}
        >
          <span>{languageName}</span>
        </div>
      );
    });
  };

  return (
    <div className={classes.containerStyle}>
      <div className={classes.bgStyle} onClick={onBgClick} />
      <div className={classes.wrapperStyle}>{getList()}</div>
    </div>
  );
};

export default LanguageStandaloneChooser;
