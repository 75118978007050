import * as C from '../constants';
import { get } from '../utils/requests';
import { getPath } from '../utils/helpers';
import queryString from 'query-string';
import { Arrow, BlockType, Lng } from '../enums';

export function storeData(payload: any, type: string, lng?: Lng) {
  return {
    payload,
    type,
    lng,
  };
}

export function setLanguage(payload) {
  return {
    type: C.SET_LANGUAGE,
    payload,
  };
}

export function storeError(payload: string) {
  return {
    type: C.STORE_ERROR,
    payload,
  };
}

export function closeError() {
  return {
    type: C.CLOSE_ERROR,
  };
}

export function resetData() {
  return {
    type: C.RESET_ALL_DATA,
  };
}

export function clearData(type: string, payload = null) {
  return {
    type,
    payload,
  };
}

const SINGLE_REQUESTS = [
  C.ALERTS_DATA,
  C.EXCURSIONS_DATA,
  C.HELP_DATA,
  C.HOURS_DATA,
  C.INSIDE_DATA,
  // C.LANGUAGES_DATA,
  C.MAIN_DATA,
  C.MOBILE_DATA,
  C.OUTSIDE_DATA,
  C.QUIZ_DATA,
  // C.SETTINGS_DATA,
  // C.MEDIA_PLAYER_DATA,
  C.SURVEY_DATA,
  C.TRANSLATIONS_DATA,
];

const REQUESTS_IN_PROGRESS = {};

export function fetchOfflineData(paramString) {
  return (dispatch, getState) => {
    const urlPrefix = getPath();
    const url = `${urlPrefix}/api?${paramString}`;

    return get(url)
      .then(({ data }) => {
        // const existingRequests = REQUESTS_IN_PROGRESS[lng];
        // const updateRequests =
        //   existingRequests.filter((item) => item !== type) || [];
        // REQUESTS_IN_PROGRESS[lng] = updateRequests;

        // console.log('>>>>>2222', data);

        return data;
      })
      .catch((error) => {
        // const errorData: IError = error.response && error.response.data;
        // return dispatch(throwGlobalError(errorData));
      });
  };
}

const getJSON = async (type, lng) => {
  const direct = [C.SETTINGS_DATA, C.LANGUAGES_DATA];

  const fileName = direct.includes(type) ? type : lng;
  const path = `./data/${fileName}.json`;

  const response = await fetch(path);
  const api = await response.json();

  const data = direct.includes(type) ? api[type] : api[lng][type];

  return data;
};

export function fetchData(type, passedParams) {
  const standalone = window.location.href.includes('standalone=true');

  const params = {
    ...passedParams,
    standalone,
  };
  const paramString = queryString.stringify(params);
  const lng = params?.lng || Lng.LV;

  return async (dispatch, getState) => {
    if (params?.standalone) {
      // for displays, load stored API data
      const data = await getJSON(type, lng);

      return dispatch(storeData(data, type, lng));
    }

    const state = getState();

    // do not make request which already is called
    const currentRequests = REQUESTS_IN_PROGRESS[lng];
    if (!currentRequests) {
      REQUESTS_IN_PROGRESS[lng] = [];
    }

    if (SINGLE_REQUESTS.includes(type)) {
      if (REQUESTS_IN_PROGRESS[lng].includes(type)) {
        // TODO: better stacked requests managing - to return parallel requests data
        return state?.[lng]?.[type] || null;
      } else {
        REQUESTS_IN_PROGRESS[lng].push(type);
      }
    }

    const existingData = state?.[lng]?.[type] && SINGLE_REQUESTS.includes(type);
    if (existingData) {
      return existingData;
    }

    const urlPrefix = getPath();
    const url = `${urlPrefix}/api?${paramString}`;

    return get(url)
      .then(({ data }) => {
        const existingRequests = REQUESTS_IN_PROGRESS[lng];
        const updateRequests =
          existingRequests.filter((item) => item !== type) || [];
        REQUESTS_IN_PROGRESS[lng] = updateRequests;

        return dispatch(storeData(data, type, lng));
      })
      .catch((error) => {
        // const errorData: IError = error.response && error.response.data;
        // return dispatch(throwGlobalError(errorData));
      });
  };
}

export function fetchDynamicData(type, passedParams, serverUrl) {
  const standalone = window.location.href.includes('standalone=true');
  const params = {
    ...passedParams,
    standalone,
  };
  const paramString = queryString.stringify(params);
  const lng = params?.lng || Lng.LV;

  const url = `${serverUrl}/api?${paramString}`;

  return (dispatch, getState) => {
    return get(url)
      .then(({ data }) => {
        return dispatch(storeData(data, type, lng));
      })
      .catch((error) => {
        // const errorData: IError = error.response && error.response.data;
        // return dispatch(throwGlobalError(errorData));
      });
  };
}

export function showAlert(payload = null) {
  return {
    type: C.SHOW_ALERT,
    payload,
  };
}

export function showRightMenu(payload: boolean = false) {
  return {
    type: C.SET_RIGHT_MENU,
    payload,
  };
}

export function setGeoStatus(payload) {
  return {
    type: C.SET_GEO_STATUS,
    payload,
  };
}

export function setActivateMarker(payload: string) {
  return {
    type: C.SET_ACTIVE_MARKER,
    payload,
  };
}

export function clearActiveMarker() {
  return {
    type: C.CLEAR_ACTIVE_MARKER,
  };
}

export function setTextSize(payload: BlockType) {
  return {
    type: C.SET_TEXT_SIZE,
    payload,
  };
}

export function showStandaloneLanguages(payload = false) {
  return {
    type: C.SHOW_STANDALONE_LANGUAGES,
    payload,
  };
}
