import * as C from '../common/constants';
import Header from './Header';
import React from 'react';
import RightMenu from './RightMenu';
import { COLORS } from '../common/theme';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';

interface IProps {
  children: React.ReactNode;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      layoutContainerStyle: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden',
      },
      layoutWrapperStyle: {
        position: 'absolute',
        width: '100%',
        height: `calc(100% - ${C.HEADER_PORTRAIT_HEIGHT}px)`,
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: `${C.HEADER_PORTRAIT_HEIGHT}px`,
        background: COLORS.appBackground,
        flex: 1,
      },
      headerParentStyle: {
        position: 'fixed',
        width: '100%',
        height: `${C.HEADER_PORTRAIT_HEIGHT}px`,
        left: 0,
        top: 0,
        zIndex: 100,
      },
      '@media screen and (orientation:landscape)': {
        layoutContainerStyle: {
          flexDirection: 'column',
        },
        layoutWrapperStyle: {
          width: `calc(100% - ${C.HEADER_LANDSCAPE_WIDTH * 2}px)`,
          height: '100%',
          margin: 0,
          marginLeft: `${C.HEADER_LANDSCAPE_WIDTH * 2}px`,
        },
        headerParentStyle: {
          position: 'fixed',
          width: `${C.HEADER_LANDSCAPE_WIDTH * 2}px`,
          height: '100%',
          left: 0,
          top: 0,
          zIndex: 100,
        },
        [`@media (max-width: 1200px)`]: {
          layoutWrapperStyle: {
            width: `calc(100% - ${C.HEADER_LANDSCAPE_WIDTH}px)`,
            marginLeft: `${C.HEADER_LANDSCAPE_WIDTH}px`,
          },
          headerParentStyle: {
            width: `${C.HEADER_LANDSCAPE_WIDTH}px`,
          },
        },
      },
    })
);

const Layout = ({ children }: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.layoutContainerStyle}>
      <div className={classes.headerParentStyle}>
        <Header />
      </div>
      <div className={classes.layoutWrapperStyle}>
        {children}
        <RightMenu />
      </div>
    </div>
  );
};

export default Layout;
