import * as C from '../common/constants';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { RootState, AppDispatch } from '../redux/configureStore';
import { Routes } from '../common/routes';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { getText, getPath } from '../common/utils/helpers';
import { showRightMenu, setActivateMarker } from '../common/app/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      containerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      wrapperStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        width: '100%',
        height: '100%',
      },
      searchItemStyle: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        marginTop: '30px',
        '&:last-child': {
          paddingBottom: '30px',
          boxSizing: 'border-box',
        },
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      contentContainerStyle: {
        position: 'relative',
        width: '100%',
        // minHeight: '80px',
        // backgroundColor: '#e0d0a4',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'flex-start',
        // marginRight: '20px',
      },
      titleStyle: {
        position: 'relative',
        width: '100%',
        height: 'auto',
        fontSize: '20px',
        // lineHeight: '22px',
        // alignSelf: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: FONT_WEIGHT.bold,
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
      },
      textStyle: {
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'justify',
        // height: '40px',
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
        marginBottom: '6px',
      },
      btnStyle: {
        fontSize: '14px',
        // maxWidth: '200px',
        // minHeight: '40px',
        // border: `1px solid ${COLORS.appBlack}`,
        // borderRadius: '8px',
        // background: COLORS.appGold,
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        fontWeight: FONT_WEIGHT.medium,
        textDecoration: 'underline',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
    })
);

const SearchResults = ({ texts }): JSX.Element => {
  const classes = useStyles();
  const searchData = useSelector(
    (state: RootState) => state.app[C.SEARCH_DATA]
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();

  const isOutside: boolean = pathname.includes(Routes.OUTSIDE);

  if (!searchData) {
    return null;
  }

  const onClick = (event: React.SyntheticEvent, item) => {
    event.preventDefault();
    event.stopPropagation();

    // temporary, prevent inside open
    if (!isOutside) {
      const id = event.currentTarget.getAttribute('data-id');

      if (id) {
        const searchParams = queryString.parse(search);
        const paramString = queryString.stringify({
          ...searchParams,
          room: id,
        });

        navigate({
          pathname,
          search: paramString,
        });

        dispatch(showRightMenu(false));
      }

      return;
    }

    const id = item?.content?.id;
    dispatch(setActivateMarker(id));
    dispatch(showRightMenu(false));
  };

  const actionLabel = isOutside ? texts.inmap : texts.inroom;

  const getHeader = (item) => {
    if (
      item?.parent?.content?.header &&
      item?.parent?.content?.template !== 'pages/wall'
    ) {
      return `${item?.parent?.content.header}: ${item?.content.header || ''}`;
    }

    return item?.content.header || '';
  };

  const getSearchItem = (item, index) => {
    const title: string = getHeader(item);
    const text: string = item?.content.text || '';
    // const imgUrl: string = `${urlPrefix}${item.images?.[0].url}`;

    return (
      <div
        className={classes.searchItemStyle}
        key={index}
        data-id={item?.parent?.content?.autoid || item?.parent?.content?.uuid}
        onClick={(e) => onClick(e, item)}
      >
        <div className={classes.imageWrapperStyle}>
          <div className={classes.photoStyle}>
            {/* <img
              className={classes.imageStyle}
              src={imgUrl}
              alt=""
              // onClick={(e) => openGallery(e, 0, images)}
            /> */}
          </div>
        </div>

        <div className={classes.contentContainerStyle}>
          <div
            className={classes.titleStyle}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {text && (
            <div
              className={classes.textStyle}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          <div className={classes.btnStyle}>{actionLabel}</div>
        </div>
      </div>
    );
  };

  // console.log('>>>>', searchData);

  return (
    <div className={classes.containerStyle}>
      <div className={classes.wrapperStyle}>
        {searchData.map(getSearchItem)}
      </div>
    </div>
  );
};

export default SearchResults;
