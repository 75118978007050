import * as C from '../common/constants';
import Alert from './Alert';
import AudioPlayerWrapper from './AudioPlayerWrapper';
import CircularProgress from '@mui/material/CircularProgress';
import CloseLayout from './CloseLayout';
import Flickity from 'react-flickity-component';
import React, { useState, useEffect, useRef } from 'react';
import UpButton from './UpButton';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Routes } from '../common/routes';
import { fetchData, clearData } from '../common/app/actions';
import { getPath } from '../common/utils/helpers';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { RootState, AppDispatch } from '../redux/configureStore';

const urlPrefix = getPath();

const flickityOptions = {
  initialIndex: 0,
  draggable: true,
  freeScroll: false,
  prevNextButtons: false,
  // adaptiveHeight: true,
  accessibility: true,
};

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      helpContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        background: COLORS.appBackground,
      },
      centerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      },
      customWrapperClass: {
        borderTop: `1px solid ${COLORS.appBlack}`,
        borderBottom: 'unset',
      },
      customButtonClass: {
        fontSize: '18px !important',
        textAlign: 'center',
        borderBottom: 'unset',
        textDecoration: 'underline',
      },
      sliderStyle: {
        width: '100%',
      },
      itemContainerStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '50px',
        boxSizing: 'border-box',
      },
      textContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
      },
      textWrapperStyle: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '20px',
      },
      itemTextStyle: {
        fontSize: '18px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appBlack,
        display: 'inline-block',
        lineHeight: '28px',
        marginBottom: '6px',
      },
      textIconStyle: {
        width: '22px',
        height: '22px',
        display: 'inline-block',
        margin: '0 4px -4px 4px',
      },
      itemIconContainerStyle: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '20px',
      },
      itemIconStyle: {
        width: '44px',
        height: '44px',
        marginRight: '20px',
      },
      '@global': {
        '.fcarousel': {
          width: '100%',
          display: 'flex',
          flex: 1,
        },
        '.flickity-viewport': {
          width: '100%',
          height: '100% !important',
        },
        '.flickity-page-dots': {
          bottom: '20px !important',
        },
      },
      imageWrapperStyle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      },
      imageStyle: {
        height: '100%',
        objectFit: 'contain',
      },
      '@media screen and (max-device-width:360px)': {
        itemContainerStyle: {
          padding: '20px',
        },
        textWrapperStyle: {
          marginBottom: '10px',
        },
      },
    })
);

const Help = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const lng = useSelector((state: RootState) => state.app.lng);
  const helpData = useSelector(
    (state: RootState) => state.app[lng]?.[C.HELP_DATA]
  );

  const [isLastSlide, setLastSlide] = useState(false);

  useEffect(() => {
    if (!helpData) {
      dispatch<AppDispatch>(fetchData(C.HELP_DATA, { help: true, lng }));
    }

    return () => {
      dispatch(clearData(C.HELP_DATA));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getIcon = (icon, index) => {
    const imgUrl: string = `${urlPrefix}${icon.url}`;

    return (
      <img className={classes.itemIconStyle} src={imgUrl} alt="" key={index} />
    );
  };

  const getImage = (item) => {
    const imgUrl: string = `${urlPrefix}${item[0].url}`;

    return (
      <div className={classes.imageWrapperStyle}>
        <img className={classes.imageStyle} src={imgUrl} alt="" />
      </div>
    );
  };

  const getText = (passedText, index, item) => {
    const imgUrl: string = `${urlPrefix}${item?.svg?.[index]?.url}`;

    const icon = `<img
    class="${classes.textIconStyle}"
    src="${imgUrl}"
    alt=""
    key={index}
    />`;

    const text = passedText.replace('{x}', icon);

    return (
      <div className={classes.textWrapperStyle} key={index}>
        <div
          className={classes.itemTextStyle}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </div>
    );
  };

  const getTextContent = (item) => {
    const rawText = item.content.text;
    const text: string = rawText?.replace(/(?:\r\n|\r|\n)/g, '{z}');
    const textArray = text?.split('{z}');

    return textArray?.map((textItem, index) => getText(textItem, index, item));
  };

  const getSlides = (item, index) => {
    const hasAudio = Boolean(item?.audio?.length);
    const hasImages = Boolean(item?.images?.length);
    const hasSvg = Boolean(item?.svg?.length);
    const showBigIcon: boolean =
      Boolean(item?.content?.icon === 'true') && hasSvg;

    return (
      <div className={classes.itemContainerStyle} key={index}>
        {showBigIcon && (
          <div className={classes.itemIconContainerStyle}>
            {item?.svg && item?.svg?.map(getIcon)}
          </div>
        )}
        <div className={classes.textContainerStyle}>{getTextContent(item)}</div>
        {hasAudio && <AudioPlayerWrapper url={item?.audio?.[0].url} />}
        {hasImages && getImage(item.images)}
      </div>
    );
  };

  const onFlickityLoad = (flickity) => {
    const totalSlides = helpData.pages.length;

    flickity.on('change', (slideNr) => {
      const isLastSlide = totalSlides - 1 === slideNr;

      if (isLastSlide) {
        setLastSlide(isLastSlide);
      }
    });
  };

  const getSlider = () => {
    return (
      // @ts-ignore
      <Flickity
        className={'fcarousel'} // default ''
        elementType={'div'} // default 'div'
        options={flickityOptions} // takes flickity options {}
        // disableImagesLoaded={false} // default false
        // reloadOnUpdate // default false
        // static // default false
        // ref={ref}
        flickityRef={onFlickityLoad}
      >
        {helpData?.pages?.map(getSlides)}
      </Flickity>
    );
  };

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    navigate({
      pathname: Routes.EXCURSIONS,
      search,
    });
  };

  const getContent = () => {
    if (!helpData) {
      return (
        <div className={classes.centerStyle}>
          <CircularProgress />
        </div>
      );
    }

    const title = helpData.content.header;
    const buttonText = isLastSlide
      ? helpData.content.end
      : helpData.content.skip;

    return (
      <>
        <UpButton title={title} isActive={true} />
        {getSlider()}
        <UpButton
          title={buttonText}
          customWrapperClass={classes.customWrapperClass}
          customButtonClass={classes.customButtonClass}
          onClick={onClick}
        />
      </>
    );
  };

  return (
    <div className={classes.helpContainerStyle}>
      <CloseLayout>{getContent()}</CloseLayout>
      <Alert />
    </div>
  );
};

export default Help;
