import * as C from '../common/constants';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import React, { useState, useEffect } from 'react';
import Story from './Story';
import UpButton from './UpButton';
import clsx from 'clsx';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Routes } from '../common/routes';
import { fetchData } from '../common/app/actions';
import { useHiddenRoomNr } from '../hooks/useHiddenRoomNr';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { RootState, AppDispatch } from '../redux/configureStore';

interface IProps {
  onParentClose?: () => void;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      buttonsContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
      dialogContainerStyle: {
        '& > div > div': {
          width: '100%',
          height: '100%',
          background: COLORS.white,
        },
      },
      dialogContentStyle: {
        margin: '0 16px',
        color: COLORS.appBlack,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflow: 'hidden',
      },
    })
);

const StoryButtons = ({ onParentClose }: IProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lng = useSelector((state: RootState) => state.app.lng);
  const outsideData = useSelector(
    (state: RootState) => state.app[lng]?.[C.OUTSIDE_DATA]
  );
  const insideData = useSelector(
    (state: RootState) => state.app[lng]?.[C.INSIDE_DATA]
  );
  const { expositionId, outsideId } = useParams();
  const { search, pathname } = useLocation();

  const isOutside: boolean = pathname.includes(Routes.OUTSIDE);

  const searchParams = queryString.parse(search);
  const isStandalone = Boolean(searchParams.standalone);

  const [currentData, setCurrentData] = useState(null);
  const [currentStoryNr, setCurrentStoryNr] = useState(0);
  const [isDialogOpen, setDialog] = useState(false);

  const { currentHiddenRoomId } = useHiddenRoomNr();

  const getData = () => {
    // if (isOutside) {
    //   return outsideData;
    // }
    const parentData = isOutside ? outsideData : insideData;
    const id = isOutside ? outsideId : expositionId;

    const data = parentData?.find((item) => item.content.id === id);

    return data;
  };

  useEffect(() => {
    const necessaryData = getData();

    if (necessaryData) {
      setCurrentData(necessaryData);
    }
  }, [outsideData, insideData]);

  const onClick = (event: React.SyntheticEvent, index) => {
    event.preventDefault();
    event.stopPropagation();

    setCurrentStoryNr(index);
    setDialog(true);
  };

  if (!currentData?.pages) {
    return null;
  }

  const getCurrentPages = () => {
    if (isOutside) {
      return currentData.pages.filter(
        (item) => item.content.template === 'pages/story'
      );
    }

    const currentPageData = currentData.pages.find(
      (item) =>
        item.content.autoid === currentHiddenRoomId ||
        item.content.uuid === currentHiddenRoomId
    );

    return currentPageData?.pages?.filter((item) => !item.content.src);
  };

  const currentPages = getCurrentPages();
  const currentStory = currentPages?.[currentStoryNr];

  const onClose = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setDialog(false);
  };

  const getSubTitle = () => {
    if (isOutside) {
      return currentData.content.header || '';
    }

    const currentPageData = currentData.pages.find(
      (item) =>
        item.content?.autoid === currentHiddenRoomId ||
        item.content?.uuid === currentHiddenRoomId
    );

    return currentPageData?.content?.header || '';
  };

  const hasOneStory = Boolean(currentPages?.length === 1);
  if (hasOneStory) {
    const subTitle = getSubTitle();

    return (
      <Story data={currentStory} onClose={onParentClose} subTitle={subTitle} />
    );
  }

  const getButtons = (item, index) => {
    const title = item.content.header || item.content.title;
    const isButtonHidden = false;

    if (isButtonHidden) {
      return null;
    }

    return (
      <UpButton title={title} key={index} onClick={(e) => onClick(e, index)} />
    );
  };

  return (
    <>
      <div
        className={clsx(
          classes.buttonsContainerStyle,
          isStandalone && classes.buttonsContainerStandaloneStyle
        )}
      >
        {currentPages?.map(getButtons)}
      </div>
      <Dialog
        fullScreen={true}
        open={isDialogOpen}
        onClose={onClose}
        className={classes.dialogContainerStyle}
      >
        <MuiDialogContent className={classes.dialogContentStyle}>
          <Story data={currentStory} onClose={onClose} />
        </MuiDialogContent>
      </Dialog>
    </>
  );
};

export default StoryButtons;
