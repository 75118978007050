import { Arrow } from '../common/enums';
import { useHiddenRoomNr } from './useHiddenRoomNr';
import { useRoomSetUrl } from './useRoomSetUrl';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../redux/configureStore';
import { showRightMenu } from '../common/app/actions';

export const useRoomSwitch = () => {
  const dispatch = useDispatch();
  const { hiddenRoomIDs, hiddenRoomArrayIndex } = useHiddenRoomNr();
  const { setRoomInUrl } = useRoomSetUrl();
  const isRightMenuVisible = useSelector(
    (state: RootState) => state.app.isRightMenuVisible
  );

  const getArrayNumber = (arrayNr) => {
    if (arrayNr < 0) {
      return hiddenRoomIDs.length - 1;
    }

    if (arrayNr > hiddenRoomIDs.length - 1) {
      return 0;
    }

    return arrayNr;
  };

  const setRoom = (count) => {
    const newArrayNr = hiddenRoomArrayIndex + count;
    const nextArrayNumber = getArrayNumber(newArrayNr);
    const newRoomId = hiddenRoomIDs[nextArrayNumber];

    setRoomInUrl(newRoomId);
  };

  const onRoomSwitch = (event?: React.SyntheticEvent) => {
    event?.stopPropagation();
    event?.preventDefault();

    if (isRightMenuVisible) {
      dispatch(showRightMenu(false));
    }

    const arrowId: any = event?.currentTarget?.getAttribute('data-id');
    const count = arrowId === Arrow.PREV ? -1 : +1;

    setRoom(count);
  };

  return {
    onRoomSwitch,
  };
};
