import CssBaseline from '@mui/material/CssBaseline';
import Excursions from './components/Excursions';
import Expositions from './components/Expositions';
import GlobalLayout from './components/GlobalLayout';
import Help from './components/Help';
import Hours from './components/Hours';
import Inside from './components/Inside';
import Intro from './components/Intro';
import Language from './components/Language';
import LanguageStandalone from './components/LanguageStandalone';
import Mobile from './components/Mobile';
import NotFound from './components/NotFound';
import Outside from './components/Outside';
import Quiz from './components/Quiz';
import Survey from './components/Survey';
import Info from './components/Info';
import { useEffect } from 'react';
import {
  HashRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { Routes as R } from './common/routes';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { theme } from './common/theme';
import useFontFaceObserver from 'use-font-face-observer';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

ReactGA.initialize('UA-212824756-1');
ReactGA4.initialize('G-PSB5RY669F');

const ExpositionsWrapper = () => {
  const { search } = useLocation();

  return <Expositions key={search} />;
};

const App = () => {
  const isFontListLoaded = useFontFaceObserver([{ family: 'Alegreya Sans' }]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <StylesProvider injectFirst /> */}
      <Router>
        <GlobalLayout>
          <Routes>
            <Route path={R.ROOT} element={<Intro />} />
            <Route path={`${R.INTRO}/:introId`} element={<Intro />} />
            <Route path={R.LANGUAGE} element={<Language />} />
            <Route path={R.HELP} element={<Help />} />
            <Route path={R.SURVEY} element={<Survey />} />
            <Route path={R.QUIZ} element={<Quiz />} />
            <Route path={R.EXCURSIONS} element={<Excursions />} />
            <Route path={R.HOURS} element={<Hours />} />
            <Route path={R.MOBILE} element={<Mobile />} />
            <Route path={`${R.OUTSIDE}/:outsideId`} element={<Outside />} />
            <Route path={R.INSIDE} element={<Inside />} />
            {/* <Route path={R.INFO} element={<Info />} /> */}
            {/* <Route
              path={R.STANDALONE_LANGUAGE}
              element={<LanguageStandalone />}
            /> */}
            <Route
              path={`${R.EXPOSITIONS}/:expositionId`}
              element={<ExpositionsWrapper />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </GlobalLayout>
      </Router>
    </ThemeProvider>
  );
};

export default App;
