import * as C from '../common/constants';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Lng, AlertType } from '../common/enums';
import { Routes } from '../common/routes';
import { fetchData, showAlert, resetData } from '../common/app/actions';
import { setLanguage } from '../common/app/actions';
import { sortLanguages } from '../common/utils/helpers';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { RootState, AppDispatch } from '../redux/configureStore';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      languageContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: COLORS.appBackground,
      },
      languageWrapperStyle: {
        position: 'relative',
        width: '100%',
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      textStyle: {
        fontSize: '30px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appBlack,
        lineHeight: '64px',
      },
      '@media screen and (max-device-width:360px)': {
        languageWrapperStyle: {
          height: '70px',
        },
        textStyle: {
          fontSize: '24px',
          fontFamily: FONTS.alegreya,
          fontWeight: FONT_WEIGHT.medium,
          color: COLORS.appBlack,
          lineHeight: '48px',
        },
      },
    })
);

const Language = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const languagesData = useSelector(
    (state: RootState) => state.app[C.LANGUAGES_DATA]
  );
  const settingsData = useSelector(
    (state: RootState) => state.app[C.SETTINGS_DATA]
  );

  useEffect(() => {
    // this breaks
    // dispatch(resetData());

    if (!languagesData) {
      dispatch<AppDispatch>(fetchData(C.LANGUAGES_DATA, { languages: true }));
    }

    if (!settingsData) {
      dispatch<AppDispatch>(fetchData(C.SETTINGS_DATA, { settings: true }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    // if (!document.fullscreenElement) {
    //   document.documentElement.requestFullscreen();
    // } else {
    //   if (document.exitFullscreen) {
    //     document.exitFullscreen();
    //   }
    // }

    const newLng = event.currentTarget.getAttribute('data-lng');
    dispatch(setLanguage(newLng));

    const searchParams = queryString.parse(search);
    const paramString = queryString.stringify({ ...searchParams, lng: newLng });

    navigate({
      pathname: Routes.HELP,
      search: paramString,
    });

    dispatch(showAlert(AlertType.WIFI));
  };

  const getContent = () => {
    if (!languagesData || !settingsData) {
      return <CircularProgress />;
    }

    const sortedLanguageData = sortLanguages(languagesData);

    return Object.keys(sortedLanguageData).map((lngKey, index) => {
      const languageName = languagesData[lngKey];
      const isLanguageVisible = settingsData.content.languages.includes(lngKey);

      if (!isLanguageVisible) {
        return null;
      }

      return (
        <div
          className={classes.languageWrapperStyle}
          key={index}
          data-lng={lngKey}
          onClick={onClick}
        >
          <div className={classes.textStyle}>{languageName}</div>
        </div>
      );
    });
  };

  return <div className={classes.languageContainerStyle}>{getContent()}</div>;
};

export default Language;
