import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Rating from '@mui/material/Rating';
import React from 'react';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { SurveyType } from '../common/enums';
import { useSelector, useDispatch } from 'react-redux';

interface IProps {
  data?: any;
  setRating?: (value: number) => void;
  ratingValue?: any;
  surveyComment?: any;
  setSurveyComment?: (value: any) => void;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      inputContainerStyle: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '20px',
      },
    })
);

const SurveyAnswerType = ({
  data,
  setRating,
  ratingValue,
  surveyComment,
  setSurveyComment,
}: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const type: SurveyType = data.content.type as SurveyType;

  if (type === SurveyType.RATING || type === SurveyType.HEARTS) {
    const isHearts = type === SurveyType.HEARTS;
    const iconProps = isHearts
      ? { icon: <FavoriteIcon fontSize="inherit" /> }
      : {};

    const emptyIconProps = isHearts
      ? { emptyIcon: <FavoriteBorderIcon fontSize="inherit" /> }
      : {};

    return (
      <div className={classes.inputContainerStyle}>
        <Rating
          defaultValue={5}
          precision={0.5}
          value={ratingValue}
          onChange={(event, newValue) => {
            setRating(newValue as number);
          }}
          max={5}
          size="large"
          sx={{
            fontSize: '3rem',
            color: COLORS.appGold,
          }}
          {...iconProps}
          {...emptyIconProps}
        />
      </div>
    );
  }

  if (type === SurveyType.SLIDER) {
    return (
      <Slider
        defaultValue={30}
        // getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        value={ratingValue}
        onChange={(event, newValue) => {
          setRating(newValue as number);
        }}
        step={1}
        marks
        min={1}
        max={10}
        sx={{ color: COLORS.appBlack, zIndex: 1000 }}
      />
    );
  }

  const handleSurveyComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSurveyComment(event.target.value);
  };

  return (
    <TextField
      multiline
      rows={4}
      value={surveyComment}
      onChange={handleSurveyComment}
      sx={{
        color: COLORS.appBlack,
        zIndex: 1000,
        width: '100%',
        maxWidth: '640px',
      }}
    />
  );
};

export default SurveyAnswerType;
