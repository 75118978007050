import * as C from '../common/constants';
import InfoButton from './InfoButton';
import React, { useRef, useEffect, useState } from 'react';
import SearchInput from './SearchInput';
import SearchResults from './SearchResults';
import StoryButtons from './StoryButtons';
import clsx from 'clsx';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { InfoButton as InfoButtonEnum } from '../common/enums';
import { fetchData } from '../common/app/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { RootState, AppDispatch } from '../redux/configureStore';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      rightMenuContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        pointerEvents: 'none',
        overflow: 'hidden',
        zIndex: 100,
      },
      rightMenuWrapperStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '100%',
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: COLORS.appBackground,
        transition: '.5s ease-in-out',
        pointerEvents: 'auto',
        boxSizing: 'border-box',
        overflowY: 'auto',
      },
      openStyle: {
        left: 0,
      },
      upStyle: {
        position: 'relative',
        flex: 1,
      },
      searchInputUpStyle: {},
      searchResultsContainerStyle: {
        width: '100%',
        height: '100%',
        // padding: '0 50px',
        boxSizing: 'border-box',
      },
      searchResultsContentStyle: {
        width: '100%',
        // height: 'calc(100% - 112px)',
        overflowX: 'auto',
        overflowY: 'scroll',
        padding: '0 28px',
        boxSizing: 'border-box',
        background: COLORS.white,
      },
      // [`@media (max-width: 600px)`]: {
      //   rightMenuWrapperStyle: {
      //     padding: '20px',
      //   },
      // },
    })
);

const RightMenu = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isRightMenuVisible = useSelector(
    (state: RootState) => state.app.isRightMenuVisible
  );
  const searchData = useSelector(
    (state: RootState) => state.app[C.SEARCH_DATA]
  );
  const lng = useSelector((state: RootState) => state.app.lng);
  const translationsData = useSelector(
    (state: RootState) => state.app[lng]?.[C.TRANSLATIONS_DATA]
  );
  const settingsData = useSelector(
    (state: RootState) => state.app[C.SETTINGS_DATA]
  );

  const { search } = useLocation();

  const searchQueryData = queryString.parse(search);
  const isStandalone = Boolean(searchQueryData.standalone);
  // const isSurvey = Boolean(searchQueryData.survey);
  // const isQuiz = Boolean(searchQueryData.quiz);
  const isGame = Boolean(searchQueryData.game);

  useEffect(() => {
    if (!translationsData) {
      dispatch<AppDispatch>(
        fetchData(C.TRANSLATIONS_DATA, { translations: true, lng })
      );
    }
    if (!settingsData) {
      dispatch<AppDispatch>(
        fetchData(C.SETTINGS_DATA, { settings: true, lng })
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getContent = () => {
    if (isStandalone) {
      return (
        <div className={classes.upStyle}>
          <StoryButtons />
        </div>
      );
    }

    if (!translationsData || !settingsData) {
      return null;
    }

    if (searchData) {
      return (
        <div className={classes.searchResultsContainerStyle}>
          <div className={classes.searchInputUpStyle}>
            <SearchInput texts={translationsData.content} />
          </div>
          <div className={classes.searchResultsContentStyle}>
            <SearchResults texts={translationsData.content} />
          </div>
        </div>
      );
    }

    const isSurveyVisible =
      settingsData?.content?.languages_survey?.includes(lng);
    const isQuizVisible = settingsData?.content?.languages_quiz?.includes(lng);

    return (
      <>
        <div className={classes.upStyle}>
          <SearchInput texts={translationsData.content} />
          <InfoButton type={InfoButtonEnum.HOURS} />
          {isSurveyVisible && <InfoButton type={InfoButtonEnum.SURVEY} />}
          {isQuizVisible && <InfoButton type={InfoButtonEnum.QUIZ} />}
          {isGame && <InfoButton type={InfoButtonEnum.GAME} />}
        </div>
      </>
    );
  };

  return (
    <div className={classes.rightMenuContainerStyle}>
      <div
        className={clsx(
          classes.rightMenuWrapperStyle,
          isRightMenuVisible && classes.openStyle
        )}
      >
        {getContent()}
      </div>
    </div>
  );
};

export default RightMenu;
