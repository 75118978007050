import * as C from '../common/constants';
import queryString from 'query-string';
import { RootState, AppDispatch } from '../redux/configureStore';
import { Routes } from '../common/routes';
import { fetchData } from '../common/app/actions';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

export const useHiddenRoomNr = () => {
  const dispatch = useDispatch();
  const { expositionId } = useParams();
  const { search, pathname } = useLocation();
  const lng = useSelector((state: RootState) => state.app.lng);
  const insideData = useSelector(
    (state: RootState) => state.app[lng]?.[C.INSIDE_DATA]
  );

  const searchParams = queryString.parse(search);

  const [hookData, setHookData] = useState(null);

  const isExpositions: boolean = pathname.includes(Routes.EXPOSITIONS);

  useEffect(() => {
    if (!insideData) {
      dispatch<AppDispatch>(fetchData(C.INSIDE_DATA, { inside: true, lng }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (insideData && isExpositions && !hookData) {
      const currentExposition = insideData?.find(
        (item) => item.content.id === (expositionId || 'base')
      );

      const hiddenRoomIDs = currentExposition?.pages?.map(
        (item) => item.content.autoid || item.content.uuid
      );

      const getHiddenRoomArrayIndex = () => {
        const currentRoomId = searchParams.room || hiddenRoomIDs?.[0];
        const currentArrayIndex =
          hiddenRoomIDs?.findIndex((item) => item === currentRoomId) || 0;

        return currentArrayIndex;
      };

      const hiddenRoomArrayIndex = getHiddenRoomArrayIndex();

      const currentHiddenRoomId = hiddenRoomIDs?.[hiddenRoomArrayIndex];

      const currentData = currentExposition?.pages?.[hiddenRoomArrayIndex];
      const currentRoomContent = currentData?.content;

      const data = {
        currentExposition,
        hiddenRoomIDs,
        hiddenRoomArrayIndex,
        currentHiddenRoomId,
        currentRoomContent,
      };

      setHookData(data);
    }
  }, [insideData, search]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!insideData || !isExpositions) {
    return {};
  }

  return hookData ? hookData : {};
};
