// export const ROOT: string = '/';
// export const LANGUAGE: string = '/language';
// export const EXCURSIONS: string = '/excursions';
// export const INSIDE: string = '/inside';
// export const OUTSIDE: string = '/outside';
// export const ROOMS: string = '/rooms';

// export const GARDEN: string = '/garden';
// export const HOURS: string = '/hours';
// export const SURVEY: string = '/survey';

export enum Routes {
  ROOT = '/',
  INTRO = '/intro',
  LANGUAGE = '/language',
  HELP = '/help',
  EXCURSIONS = '/excursions',
  INSIDE = '/inside',
  OUTSIDE = '/outside',
  ROOMS = '/rooms',
  EXPOSITIONS = '/expositions',
  BASE = '/base',
  HOURS = '/hours',
  SURVEY = '/survey',
  QUIZ = '/quiz',
  MOBILE = '/mobile',
  STANDALONE_LANGUAGE = '/standalone-language',
  STANDALONE_IDLE = '/standalone-idle',
  INFO = '/info',
}
