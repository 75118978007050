import * as C from '../common/constants';
import queryString from 'query-string';
import { RootState, AppDispatch } from '../redux/configureStore';
import { Routes } from '../common/routes';
import { fetchData } from '../common/app/actions';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';

export const useRoomData = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const lng = useSelector((state: RootState) => state.app.lng);
  const insideData = useSelector(
    (state: RootState) => state.app[lng]?.[C.INSIDE_DATA]
  );

  const searchParams = queryString.parse(search);
  const roomId: string = searchParams.room as string;

  useEffect(() => {
    if (!insideData) {
      dispatch<AppDispatch>(fetchData(C.INSIDE_DATA, { inside: true, lng }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!insideData) {
    return null;
  }

  console.log('>>>', insideData);

  const pages = [];
  insideData?.forEach((item) => {
    pages.push(...item.pages);
  });

  const currentRoomData = pages?.find(
    (item) => item.content.autoid === roomId || item.content.uuid === roomId
  );

  return currentRoomData;
};
