import * as C from '../common/constants';
import React from 'react';
import clsx from 'clsx';
import { COLORS } from '../common/theme';
import { ReactComponent as IconClose } from '../assets/svg/icon_close.svg';
import { ReactComponent as IconWorld } from '../assets/svg/icon_world.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { Routes } from '../common/routes';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';

interface IProps {
  onClose?: (event: React.SyntheticEvent) => void;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      closeHeaderContainerStyle: {
        position: 'relative',
        width: '100%',
        height: `${C.HEADER_PORTRAIT_HEIGHT}px`,
        minHeight: `${C.HEADER_PORTRAIT_HEIGHT}px`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: COLORS.appBlack,
        zIndex: 10,
      },
      iconCloseContainerStyle: {
        width: `${C.HEADER_PORTRAIT_HEIGHT}px`,
        height: `${C.HEADER_PORTRAIT_HEIGHT}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      iconCloseStyle: {
        width: '16px',
        height: '16px',
        fill: COLORS.appMapSand,
      },
      iconWorldStyle: {
        width: '24px',
        height: '24px',
        fill: COLORS.appMapSand,
      },
      '@media screen and (orientation:landscape)': {
        closeHeaderContainerStyle: {
          width: `${C.HEADER_LANDSCAPE_WIDTH * 2}px`,
          minWidth: `${C.HEADER_LANDSCAPE_WIDTH * 2}px`,
          height: '100%',
          minHeight: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start',
        },
        iconCloseContainerStyle: {
          width: `${C.HEADER_LANDSCAPE_WIDTH * 2}px`,
          height: `${C.HEADER_LANDSCAPE_WIDTH * 2}px`,
        },
        iconCloseStyle: {
          width: '35px',
          height: '35px',
          fill: COLORS.appMapSand,
        },
        iconWorldStyle: {
          width: '35px',
          height: '35px',
          fill: COLORS.appMapSand,
        },
        [`@media (max-width: 1200px)`]: {
          closeHeaderContainerStyle: {
            width: `${C.HEADER_LANDSCAPE_WIDTH}px`,
            minWidth: `${C.HEADER_LANDSCAPE_WIDTH}px`,
          },
          iconCloseContainerStyle: {
            width: `${C.HEADER_LANDSCAPE_WIDTH}px`,
            height: `${C.HEADER_LANDSCAPE_WIDTH}px`,
          },
          iconCloseStyle: {
            width: '16px',
            height: '16px',
            fill: COLORS.appMapSand,
          },
          iconWorldStyle: {
            width: '24px',
            height: '24px',
            fill: COLORS.appMapSand,
          },
        },
      },
    })
);

const CloseHeader = ({ onClose }: IProps): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const onClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (onClose) {
      onClose(event);
    } else {
      navigate(-1);
    }
  };

  const getIcon = () => {
    const isExcursions: boolean = pathname.includes(Routes.EXCURSIONS);

    if (isExcursions) {
      return <IconWorld className={clsx(classes.iconWorldStyle)} />;
    }

    return <IconClose className={classes.iconCloseStyle} />;
  };

  return (
    <div className={classes.closeHeaderContainerStyle}>
      <div className={classes.iconCloseContainerStyle} onClick={onClick}>
        {getIcon()}
      </div>
    </div>
  );
};

export default CloseHeader;
