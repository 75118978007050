import { createTheme } from '@mui/material/styles';

export const COLORS = {
  // primary: '#5380a3',
  // appBlue: '#5380a3',
  // secondary: '#565351',
  // appGray: '#565351',
  white: '#FFFFFF',
  white85: 'rgba(255,255,255,0.85)',
  red: 'red',
  green: 'green',
  // appBlack: '#3b3938',
  // appLightGray: '#c8c8c9',
  // appBlockLightGray: '#6e6d6e',
  // appBlockGray: '#565351',
  // appTextBlack: '#3c3b3b',
  // textBlack: '#4a4a4a',
  // textGray: '#999999',
  // textDescription: '#333333',
  // background: '#009ab9',
  // backgroundGray: '#f4f4f4',
  // backgroundGray85: 'rgba(244,244,244,0.85)',
  // backgroundGray65: 'rgba(244,244,244,0.65)',
  // circleGray: '#E6E6E6',
  // black: '#000000',
  // socialCircleGray: '#666666',
  // previewGray: '#666666',
  // black80: 'rgba(0,0,0,0.8)',
  appGold: '#D6BC50',
  appBackground: '#EBEADB',
  appBackgroundTransparent: 'rgba(235, 234, 219, 0.9)',
  appBlack: '#221F20',
  appBlack30: 'rgba(34,31,32,0.3)',
  appSecondBackground: 'rgba(104,121,81,0.4)', //'#C3C9B9',
  appMapSand: '#f5eaca',
  appMapGray: '#c3c9b9',
  appLakeBlue: '#94bebf',
  appLightGreen: '#C3C9B9',
  appDarkGreen: '#687951',

  newRingGreen: '#908542',
  newRingGold: '#d6bc50',
  newRingRed: '#963b27',
};

export const FONTS = {
  alegreya: 'Alegreya Sans',
};

export const FONT_WEIGHT = {
  regular: 400,
  medium: 500,
  bold: 700,
};

// export const SIZES = {
//   maxSearchBlockWidth: 440,
// };

// const fontFamily: string = ['"Alegreya Sans"', 'Arial', 'sans'].join(',');

export const theme = createTheme({
  palette: {
    primary: { main: COLORS.appGold },
    secondary: { main: COLORS.appBackground },
    // background: {
    //   paper: COLORS.background,
    // },
    // type: 'dark',
  },
  typography: {
    // fontFamily,
    // fontWeightRegular: 400,
    // fontWeightMedium: 500,
    // fontWeightBold: 700,
    // h2: {
    //   fontSize: '50px',
    // },
    h3: {
      // verticalAlign: 'middle',
      // alignItems: 'center',
      // textAlign: 'center',
      // fontFamily: FONTS.garamond,
      // fontWeight: FONT_WEIGHT.medium,
      // fontSize: '20px',
      // color: COLORS.textBlack,
      // textTransform: 'uppercase',
    },
    // h1: {
    //   fontSize: '86px',
    //   color: COLORS.white,
    //   verticalAlign: 'middle',
    //   alignItems: 'center',
    //   textAlign: 'center',
    // },
  },
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       '@font-face': [alegreyaSans, ebGaramond],
  //     },
  //   },
  // },
});
