import React, { useState, useEffect, useRef } from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { gsap, Linear, Power4, Circ } from 'gsap';

interface IProps {
  data?: any;
  isLastSlide?: boolean;
  slideIndex?: any;
  answerData?: any;
  onNext?: (isAnswerCorrect: boolean) => void;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      variantStyle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px 24px 10px 24px',
        border: `1px solid ${COLORS.appBlack}`,
        color: COLORS.appBlack,
        fontFamily: FONTS.alegreya,
        fontSize: '18px',
        lineHeight: '16px',
        whiteSpace: 'nowrap',
        marginBottom: '20px',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            background: COLORS.appGold,
            transition: '0.5s ease',
          },
        },
      },
    })
);

const QuizAnswer = ({
  data,
  isLastSlide,
  slideIndex,
  answerData,
  onNext,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const box = useRef(null);

  const animation = (isAnswerCorrect: boolean) => {
    const background = isAnswerCorrect ? '#96c78b' : '#d58080';
    gsap.to(box.current, {
      color: 'white',
      background,
      onComplete: () => onNext(isAnswerCorrect),
    });
  };

  const onSubmitClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const answerNrString: any =
      event.currentTarget.getAttribute('data-answer-nr');
    const answerNr = parseInt(answerNrString);
    const slideNrString: any =
      event.currentTarget.getAttribute('data-slide-nr');
    const slideNr = parseInt(slideNrString);

    // console.log('>>>>', answerNr, slideNr, quizData);

    const currentQuestionData = data?.pages[slideNr - 1];
    const correctAnswerNr = parseInt(currentQuestionData?.content?.correct);
    const isAnswerCorrect = answerNr === correctAnswerNr;

    animation(isAnswerCorrect);
  };

  return (
    <div
      className={classes.variantStyle}
      onClick={onSubmitClick}
      data-answer-nr={answerData?.itemIndex + 1}
      data-slide-nr={slideIndex + 1}
      key={answerData?.itemIndex}
      ref={box}
    >
      {answerData?.item?.variant}
    </div>
  );
};

export default QuizAnswer;
