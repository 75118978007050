import * as C from '../common/constants';
import CircularProgress from '@mui/material/CircularProgress';
import CloseLayout from './CloseLayout';
import IdleTimerWrapper from './IdleTimerWrapper';
import Layout from './Layout';
import NavigationNote from './NavigationNote';
import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Floor } from '../common/enums';
import { ReactComponent as Floor0 } from '../assets/maps/rundale_floors_floor0.svg';
import { ReactComponent as Floor1 } from '../assets/maps/rundale_floors_floor1.svg';
import { ReactComponent as Floor2 } from '../assets/maps/rundale_floors_floor2.svg';
import { ReactComponent as MapMegaMarker } from '../assets/svg/map_mega_marker.svg';
import { RootState, AppDispatch } from '../redux/configureStore';
import { Routes } from '../common/routes';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { Svg, Polyline } from 'react-svg-path';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { fetchData } from '../common/app/actions';
import { useHiddenRoomNr } from '../hooks/useHiddenRoomNr';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRoomData } from '../hooks/useRoomData';
import { useSelector, useDispatch } from 'react-redux';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

import route0 from '../assets/maps/rundale_floors_route0.png';
import route1 from '../assets/maps/rundale_floors_route1.png';
import route2 from '../assets/maps/rundale_floors_route2.png';

const MAPS = {
  [Floor.FIRST]: Floor1, // floor1Bg,
  [Floor.SECOND]: Floor2, // floor2Bg,
  [Floor.BASEMENT]: Floor0, // floor0Bg,
};

const MAPS_ROUTES = {
  [Floor.FIRST]: route1, // floor1Bg,
  [Floor.SECOND]: route2, // floor2Bg,
  [Floor.BASEMENT]: route0, // floor0Bg,
};

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      insideContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // background: COLORS.appSecondBackground,
      },
      btnsContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '100px',
        minHeight: '100px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      },
      btnsContainerStandaloneStyle: {
        height: '170px',
        background: COLORS.appMapGray,
        padding: '40px 60px',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
      },
      btnStyle: {
        width: '100%',
        fontSize: '24px',
        lineHeight: '26px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appBlack,
        background: COLORS.appBackground,
        borderBottom: `1px solid ${COLORS.appBlack}`,
        borderRight: `1px solid ${COLORS.appBlack}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'normal',
        padding: '0 10px',
        boxSizing: 'border-box',
        textAlign: 'center',
        '&:last-child': {
          borderRight: 'none',
        },
      },
      btnStandaloneStyle: {
        // boxSizing: 'border-box',
        // margin: '40px 0 30px 0',
        width: '32%',
        border: `1px solid ${COLORS.appBlack}`,
        '&:last-child': {
          border: `1px solid ${COLORS.appBlack}`,
        },
      },
      activeStyle: {
        background: COLORS.appGold,
      },
      separatorStyle: {
        width: '1px',
        background: COLORS.appBlack,
      },
      floorStyle: {
        width: '100%',
        // height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '20px',
        '& > svg': {
          width: '90%',
          // height: '50%',
          flex: 1,
        },
      },
      floorStandaloneStyle: {
        height: 'calc(100% - 100px)',
      },

      mapContainerStyle: {
        position: 'relative',
        width: '100%',
        height: '85%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: COLORS.appMapGray,
        flex: 1,
        overflow: 'hidden',
        '& > div': {
          overflow: 'visible',
        },
      },
      mapWrapperStyle: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transform: 'translate3d(0, 0, 0)',
      },
      mapHolderStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
      mapAdjustStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      mapStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
      markersAdjustStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      markerContainerStyle: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      roomNumberStyle: {
        position: 'absolute',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.white,
        fontSize: '80px',
        paddingBottom: '60px',
        boxSizing: 'border-box',
        textAlign: 'center',
        whiteSpace: 'nowrap',
      },
      noteStyle: {
        bottom: 'auto',
        marginBottom: '50px',
      },
      roomTitleStyle: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appBlack,
        fontSize: '16px',
        lineHeight: '24px',
        padding: '30px',
        whiteSpace: 'normal',
        boxSizing: 'border-box',
      },
      roomTitleStandaloneStyle: {
        padding: '50px 60px',
        boxSizing: 'border-box',
        fontSize: '26px',
        lineHeight: '34px',
        maxWidth: '600px',
      },
      '@media screen and (max-device-width:360px)': {
        btnsContainerStyle: {
          height: '60px',
          minHeight: '60px',
        },
        btnStyle: {
          fontSize: '18px',
          lineHeight: '21px',
        },
      },
    })
);

const Inside = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const lng = useSelector((state: RootState) => state.app.lng);
  const translationsData = useSelector(
    (state: RootState) => state.app[lng]?.[C.TRANSLATIONS_DATA]
  );
  const settingsData = useSelector(
    (state: RootState) => state.app[C.SETTINGS_DATA]
  );
  const insideData = useSelector(
    (state: RootState) => state.app[lng]?.[C.INSIDE_DATA]
  );

  const { height, width } = useWindowDimensions();

  const searchParams = queryString.parse(search);
  const isStandalone = Boolean(searchParams.standalone);

  const [currentFloor, setFloor] = useState(Floor.FIRST);
  const containerRef = useRef(null);

  const currentRoomData = useRoomData();

  useEffect(() => {
    if (!translationsData) {
      dispatch<AppDispatch>(
        fetchData(C.TRANSLATIONS_DATA, { translations: true, lng })
      );
    }
    if (!insideData) {
      dispatch<AppDispatch>(fetchData(C.INSIDE_DATA, { inside: true, lng }));
    }
    if (!settingsData) {
      dispatch<AppDispatch>(
        fetchData(C.SETTINGS_DATA, { settings: true, lng })
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentRoomData) {
      const floor = currentRoomData?.content?.floor;

      if (floor) {
        setFloor(floor);
      }
    }
  }, [currentRoomData]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!translationsData || !insideData || !settingsData || !currentRoomData) {
    return (
      <Layout>
        <CircularProgress />
      </Layout>
    );
  }

  const { minzoom, maxzoom } = settingsData.content;
  const minScale = isStandalone ? 0.7 : 0.25;
  const maxScale = 4;
  const initialScale = isStandalone ? 0.7 : minScale;

  const onClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const buttonId = event.currentTarget.getAttribute('data-id');

    setFloor(buttonId as Floor);
  };

  const {
    floor1: floor1Label,
    floor2: floor2Label,
    basement: basementLabel,
  } = translationsData?.content;
  const mapData = [
    {
      title: basementLabel,
      id: Floor.BASEMENT,
    },
    {
      title: floor1Label,
      id: Floor.FIRST,
    },
    {
      title: floor2Label,
      id: Floor.SECOND,
    },
  ];

  const getMapButton = (item, index) => {
    const isCurrentFloor = item.id === currentFloor;

    return (
      <div
        className={clsx(
          classes.btnStyle,
          isCurrentFloor && classes.activeStyle,
          isStandalone && classes.btnStandaloneStyle
        )}
        data-id={item.id}
        onClick={onClick}
        key={index}
      >
        {item.title}
      </div>
    );
  };

  const CurrentFloorMap = MAPS[currentFloor];
  const CurrentFloorRoute = MAPS_ROUTES[currentFloor];
  const { nr, x_pos, y_pos, floor, header, area } = currentRoomData?.content;
  const isMarkerVisible = x_pos && y_pos && currentFloor === floor;

  const zoomText = translationsData.content.inzoom;

  const roomTitle = nr ? `${nr}. ${header}` : header;

  const getField = () => {
    if (!area) {
      return null;
    }

    const pointsArray = JSON.parse(area);

    return (
      <Svg width={'100%'} height={'100%'}>
        <Polyline points={pointsArray} relative={false} fill={COLORS.appGold} />
      </Svg>
    );
  };

  const getContent = () => {
    return (
      <>
        <div
          className={clsx(
            classes.btnsContainerStyle,
            isStandalone && classes.btnsContainerStandaloneStyle
          )}
        >
          {mapData.map(getMapButton)}
        </div>

        <div className={classes.mapContainerStyle} ref={containerRef}>
          {isMarkerVisible && (
            <div
              className={clsx(
                classes.roomTitleStyle,
                isStandalone && classes.roomTitleStandaloneStyle
              )}
            >
              {roomTitle}
            </div>
          )}

          <TransformWrapper
            initialScale={initialScale}
            initialPositionX={0}
            initialPositionY={0}
            centerZoomedOut={true}
            centerOnInit={true}
            minScale={minScale}
            maxScale={maxScale}
            limitToBounds={true}
            // onZoom={onZoom}
          >
            {(transformProps) => {
              // const { zoomIn, zoomOut, resetTransform, ...rest } =
              //   transformProps;
              // console.log('>>>ZOOM', transformProps);

              return (
                <TransformComponent>
                  <div
                    className={classes.mapWrapperStyle}
                    // style={{ width: `${width}px`, height: `${height}px` }}
                    style={{
                      width: `${C.FLOOR_SIZE.width}px`,
                      height: `${C.FLOOR_SIZE.height}px`,
                    }}
                  >
                    <div className={classes.mapAdjustStyle}>
                      <CurrentFloorMap className={classes.mapStyle} />

                      {isMarkerVisible && (
                        <div className={classes.mapStyle}>{getField()}</div>
                      )}

                      <img
                        src={CurrentFloorRoute}
                        className={classes.mapStyle}
                      />

                      {isMarkerVisible && Boolean(nr) && (
                        <div
                          className={classes.markerContainerStyle}
                          style={{ left: `${x_pos}px`, top: `${y_pos}px` }}
                        >
                          <div
                            className={classes.roomNumberStyle}
                          >{`${nr}.`}</div>
                          <MapMegaMarker />
                        </div>
                      )}
                    </div>
                  </div>
                </TransformComponent>
              );
            }}
          </TransformWrapper>
          <NavigationNote
            customContainerClass={classes.noteStyle}
            text={zoomText}
          />
        </div>
      </>
    );
  };

  const onClose = () => {
    navigate(-1);
  };

  const onIdle = () => {
    onClose();
  };

  return (
    <div className={classes.insideContainerStyle}>
      <CloseLayout onClose={onClose}>{getContent()}</CloseLayout>
      {isStandalone && <IdleTimerWrapper onIdle={onIdle} />}
    </div>
  );
};

export default Inside;
