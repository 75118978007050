import React from 'react';
import WallImage from './WallImage';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Navigation, Pagination } from 'swiper';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import FavouriteIcon from './FavouriteIcon';

interface IProps {
  data: any;
  onChange: (event?: any) => void;
  onZoom: (event?: any) => void;
  onMarkerClick?: (event: React.SyntheticEvent, markerData) => void;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      imageWrapperStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      imageStyle: {
        '& > div': {
          position: 'relative',
          width: '100%',
          overflow: 'unset !important',
        },
      },
      imagePortraitStyle: {
        '& > div': {
          height: '100%',
        },
      },
      favouriteContainerStyle: {
        position: 'absolute',
        top: 0,
        right: 0,
        pointerEvents: 'none',
        display: 'flex',
        justifyContent: 'flex-end',
      },
      '@global': {
        '.swiper': {
          width: '100%',
          height: '100%',
          overflow: 'unset',
        },
        '.swiper-slide': {
          display: 'flex',
          alignItems: 'center',
        },
      },
      '@media screen and (orientation: landscape)': {
        '@global': {
          '.swiper-pagination-bullet': {
            border: `2px solid ${COLORS.appBlack} !important`,
            background: 'none !important',
            width: '24px !important',
            height: '24px !important',
            transform: 'scale(1) !important',
          },
          '.swiper-pagination-bullet-active': {
            border: 'none !important',
            background: `${COLORS.appBlack} !important`,
          },
        },
      },
      '@media screen and (orientation: portrait)': {
        '@global': {
          '.swiper-pagination-bullet': {
            border: `1px solid ${COLORS.appBlack} !important`,
            background: 'none !important',
            opacity: '1 !important',
            width: '13px !important',
            height: '13px !important',
            boxSizing: 'border-box',
            transform: 'scale(1) !important',
          },
          '.swiper-pagination-bullet-active': {
            border: 'none !important',
            background: `${COLORS.appBlack} !important`,
          },
        },
      },
    })
);

const WallImages = ({
  data,
  onZoom,
  onMarkerClick,
  onChange,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const [swiper, setSwiper] = useState(null);

  // const [imageRefs, ref] = useArrayRef();

  // useEffect(() => {
  //   if (isZoomed) {
  //     swiper?.disable();
  //   } else {
  //     swiper?.enable();
  //   }
  // }, [isZoomed]);

  const getImages = (item, index) => {
    const isPortrait = item?.dimensions?.orientation === 'portrait';
    const key = index + '_' + item?.url;

    if (data?.images.length === 1) {
      return (
        <>
          <WallImage
            key={key}
            item={item}
            data={data}
            onZoom={onZoom}
            onMarkerClick={onMarkerClick}
            swiper={swiper}
            className={clsx(
              classes.imageStyle,
              isPortrait && classes.imagePortraitStyle
            )}
          />
          <div className={classes.favouriteContainerStyle}>
            <FavouriteIcon data={data} />
          </div>
        </>
      );
    }

    return (
      <SwiperSlide key={key}>
        <div
          className={clsx(
            classes.imageWrapperStyle,
            isPortrait && classes.imageWrapperPortraitStyle
          )}
        >
          <WallImage
            key={key}
            item={item}
            data={data}
            onZoom={onZoom}
            onMarkerClick={onMarkerClick}
            swiper={swiper}
            className={clsx(
              classes.imageStyle,
              isPortrait && classes.imagePortraitStyle
            )}
          />
          <div className={classes.favouriteContainerStyle}>
            <FavouriteIcon data={data} />
          </div>
        </div>
      </SwiperSlide>
    );
  };

  if (data?.images.length === 1) {
    return <>{getImages(data?.images[0], 0)}</>;
  }

  return (
    <Swiper
      direction={'horizontal'}
      loop={true}
      spaceBetween={0}
      slidesPerView={1}
      modules={[Pagination]}
      onSlideChange={onChange}
      onSwiper={setSwiper}
      scrollbar={{ draggable: true }}
      threshold={100}
      pagination={{ clickable: true, dynamicBullets: false }}
    >
      {data?.images?.map(getImages)}
    </Swiper>
  );
};

export default WallImages;
