import { COLORS } from '../common/theme';
import { ReactComponent as IconClose } from '../assets/svg/icon_close.svg';
import { ReactComponent as IconHamburger } from '../assets/svg/icon_hamburger.svg';
import { useSelector } from 'react-redux';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { RootState } from '../redux/configureStore';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      iconCloseStyle: {
        width: '16px',
        height: '16px',
        fill: COLORS.appBackground,
      },
      iconHamburgerStyle: {
        width: '20px',
        height: '14px',
        fill: COLORS.appBackground,
      },
      '@media screen and (orientation:landscape)': {
        iconCloseStyle: {
          width: '28px',
          height: '28px',
        },
        iconHamburgerStyle: {
          width: '32px',
          height: '24px',
        },
        [`@media (max-width: 1200px)`]: {
          iconCloseStyle: {
            width: '16px',
            height: '16px',
          },
          iconHamburgerStyle: {
            width: '20px',
            height: '14px',
          },
        },
      },
    })
);

const HamburgerAndClose = (): JSX.Element => {
  const classes = useStyles();
  const isRightMenuVisible = useSelector(
    (state: RootState) => state.app.isRightMenuVisible
  );

  return isRightMenuVisible ? (
    <IconClose className={classes.iconCloseStyle} />
  ) : (
    <IconHamburger className={classes.iconHamburgerStyle} />
  );
};

export default HamburgerAndClose;
