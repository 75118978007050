export enum Lng {
  DE = 'de',
  EE = 'ee',
  EN = 'en',
  FR = 'fr',
  LV = 'lv',
  RU = 'ru',
  VI = 'vi',
}

export enum EmitStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum ActionType {
  GET = 'GET',
  SEND = 'SEND',
}

export enum Direction {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum BlockType {
  BIG = 'big',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum Color {
  GRAY = 'gray',
}

export enum Orientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export enum Floor {
  FIRST = 'first',
  SECOND = 'second',
  BASEMENT = 'basement',
}

export enum Arrow {
  NEXT = 'next',
  PREV = 'prev',
}

export enum InfoButton {
  HOURS = 'hours',
  SURVEY = 'survey',
  QUIZ = 'quiz',
  GAME = 'game',
}

export enum AlertType {
  WIFI = 'wifi',
  PHONES = 'phones',
}

export enum MenuType {
  HAMBURGER = 'hamburger',
  INFO = 'info',
}

export enum SurveyType {
  RATING = 'rating',
  INPUT = 'input',
  SLIDER = 'slider',
  HEARTS = 'hearts',
}
