import * as C from '../common/constants';
import CloseLayout from './CloseLayout';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import StoryButtons from './StoryButtons';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      roomInfoContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        background: COLORS.appBackground,
      },
    })
);

const RoomInfo = ({ onClose }): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.roomInfoContainerStyle}>
      <CloseLayout onClose={onClose}>
        <StoryButtons onParentClose={onClose} />
      </CloseLayout>
    </div>
  );
};

export default RoomInfo;
