import * as C from '../common/constants';
import FavouriteIcon from './FavouriteIcon';
import React from 'react';
import TextBlock from './TextBlock';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as IconClose } from '../assets/svg/icon_close.svg';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { getPath } from '../common/utils/helpers';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

interface IProps {
  noTextSizes?: boolean;
  data?: any;
  onClose?: () => void;
  isStandalone?: boolean;
}

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      popStyle: {
        display: 'flex',
        width: '100%',
        boxSizing: 'border-box',
        flexDirection: 'column',
        flex: 1,
        maxWidth: '500px',
      },
      closeContainerStyle: {
        width: '100%',
        height: `${C.HEADER_PORTRAIT_HEIGHT}px`,
        minHeight: `${C.HEADER_PORTRAIT_HEIGHT}px`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: COLORS.white,
      },
      iconCloseContainerStyle: {
        width: `${C.HEADER_PORTRAIT_HEIGHT}px`,
        height: `${C.HEADER_PORTRAIT_HEIGHT}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      iconCloseStyle: {
        width: '16px',
        height: '16px',
        fill: COLORS.appBlack,
      },
      contentContainerStyle: {
        width: '100%',
        flex: 1,
      },
      imageContainerStyle: {
        maxWidth: '100%',
        maxHeight: '220px',
        width: '100%',
        height: '220px',
        display: 'flex',
        flexDirection: 'column',
      },
      imageStyle: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      },
      favouriteContainerStyle: {
        position: 'absolute',
        top: 0,
        right: 0,
      },
      '@global': {
        '.swiper': {
          width: '100%',
          height: '100%',
          maxHeight: '220px',
          overflow: 'unset',
        },
        '.swiper-slide': {
          display: 'flex',
          alignItems: 'center',
        },
        '.swiper-pagination-bullet': {
          border: `2px solid ${COLORS.appBlack} !important`,
          background: 'none !important',
          width: '16px !important',
          height: '16px !important',
          transform: 'scale(1) !important',
        },
        '.swiper-pagination-bullet-active': {
          border: 'none !important',
          background: `${COLORS.appBlack} !important`,
        },
      },
      // '@media screen and (orientation: landscape)': {
      //   '@global': {
      //     '.swiper-pagination-bullet': {
      //       border: `2px solid ${COLORS.appBlack} !important`,
      //       background: 'none !important',
      //       width: '24px !important',
      //       height: '24px !important',
      //       transform: 'scale(1) !important',
      //     },
      //     '.swiper-pagination-bullet-active': {
      //       border: 'none !important',
      //       background: `${COLORS.appBlack} !important`,
      //     },
      //   },
      // },
      // '@media screen and (orientation: portrait)': {
      //   '@global': {
      //     '.swiper-pagination-bullet': {
      //       border: `1px solid ${COLORS.appBlack} !important`,
      //       background: 'none !important',
      //       opacity: '1 !important',
      //       width: '13px !important',
      //       height: '13px !important',
      //       boxSizing: 'border-box',
      //       transform: 'scale(1) !important',
      //     },
      //     '.swiper-pagination-bullet-active': {
      //       border: 'none !important',
      //       background: `${COLORS.appBlack} !important`,
      //     },
      //   },
      // },
    })
);

const MarkerPopup = ({ data, onClose, noTextSizes, isStandalone }: IProps) => {
  const classes = useStyles();

  const getImages = (currentImage, index) => {
    const imgUrl: string = `${urlPrefix}${currentImage.url}`;

    if (data?.images.length === 1) {
      return (
        <div className={classes.imageContainerStyle} key={index}>
          <img src={imgUrl} alt="" className={classes.imageStyle} />
        </div>
      );
    }

    return (
      <SwiperSlide key={index}>
        <div className={classes.imageContainerStyle} key={index}>
          <img src={imgUrl} alt="" className={classes.imageStyle} />
        </div>
      </SwiperSlide>
    );
  };

  const getImageSwiper = () => {
    return (
      <Swiper
        direction={'horizontal'}
        loop={true}
        spaceBetween={0}
        slidesPerView={1}
        modules={[Pagination]}
        scrollbar={{ draggable: true }}
        threshold={100}
        pagination={{ clickable: true, dynamicBullets: false }}
      >
        {data?.images?.map(getImages)}
      </Swiper>
    );
  };

  return (
    <div className={classes.popStyle}>
      <div className={classes.closeContainerStyle}>
        <div className={classes.iconCloseContainerStyle} onClick={onClose}>
          <IconClose className={classes.iconCloseStyle} />
        </div>
      </div>
      <div className={classes.contentContainerStyle}>
        {!isStandalone && (
          <div className={classes.favouriteContainerStyle}>
            <FavouriteIcon data={data} />
          </div>
        )}
        {getImageSwiper()}
        <TextBlock data={data} padding={20} noTextSizes={noTextSizes} />
      </div>
    </div>
  );
};

export default React.memo(MarkerPopup);
