import queryString from 'query-string';
import { useHiddenRoomNr } from './useHiddenRoomNr';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export const useRoomSetUrl = () => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const searchParams = queryString.parse(search);
  const { hiddenRoomIDs } = useHiddenRoomNr();

  if (!hiddenRoomIDs) {
    return {};
  }

  const setRoomInUrl = (hiddenRoomId: string) => {
    const paramString = queryString.stringify({
      ...searchParams,
      room: hiddenRoomId,
    });

    navigate({
      pathname,
      search: paramString,
    });
  };

  return {
    setRoomInUrl,
  };
};
