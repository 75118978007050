import * as C from '../common/constants';
import createRootReducer from '../common/reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import { save, load } from 'redux-localstorage-simple';
// import LogRocket from 'logrocket';

const middleware = [
  thunk,
  save({
    states: [C.PERSISTENT],
    namespace: C.RUNDALE,
  }),
  // LogRocket.reduxMiddleware(),
];
const logger = createLogger({
  collapsed: true,
});

if (process.env.NODE_ENV !== C.PRODUCTION) middleware.push(logger);

const enhancer = composeWithDevTools(applyMiddleware(...middleware));
const store = createStore(
  createRootReducer(),
  load({
    states: [C.PERSISTENT],
    namespace: C.RUNDALE,
  }),
  enhancer
);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch | any;
