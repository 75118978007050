import ReactPlayer from 'react-player';
import clsx from 'clsx';
import queryString from 'query-string';
import { BlockType } from '../common/enums';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { RootState, AppDispatch } from '../redux/configureStore';
import { Scrollbars } from '@rastopyr/react-custom-scrollbar';
import TextSizeIcons from './TextSizeIcons';
import { getPath } from '../common/utils/helpers';

const urlPrefix = getPath();

interface IProps {
  data: any;
  noHeader?: boolean;
  padding?: number;
  subTitle?: string;
  noTextSizes?: boolean;
  hasBackground?: boolean;
  showFavourite?: boolean;
  onScroll?: (event) => void;
}

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      itemContainerStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        overflowY: 'auto',
        flex: 1,
        background: COLORS.white,
      },
      textContainerStyle: {
        width: '100%',
        height: '100%',
        padding: '28px 0 0 28px',
        fontFamily: FONTS.alegreya,
        color: COLORS.appBlack,
        boxSizing: 'border-box',
        // paddingBottom: '80px',
        overflow: 'hidden',
        flex: 1,
      },
      bigStyle: {
        fontSize: '30px',
        lineHeight: '36px',
        '& br': {
          content: '""',
          margin: '30px',
          display: 'block',
        },
        '& i': {
          fontStyle: 'italic',
        },
      },
      mediumStyle: {
        fontSize: '26px',
        lineHeight: '32px',
        '& br': {
          content: '""',
          margin: '24px',
          display: 'block',
        },
        '& i': {
          fontStyle: 'italic',
        },
      },
      smallStyle: {
        fontSize: '20px',
        lineHeight: '24px',
        '& br': {
          content: '""',
          margin: '14px',
          display: 'block',
        },
        '& i': {
          fontStyle: 'italic',
        },
      },
      authorStyle: {
        fontWeight: FONT_WEIGHT.regular,
        fontStyle: 'italic',
      },
      headerStyle: {
        fontWeight: FONT_WEIGHT.medium,
        marginBottom: '12px',
        '& $bigStyle': {
          fontSize: '34px',
          lineHeight: '38px',
        },
        '& $mediumStyle': {
          fontSize: '30px',
          lineHeight: '34px',
        },
        '& $smallStyle': {
          fontSize: '24px',
          lineHeight: '28px',
        },
      },
      dateSizeContainerStyle: {
        marginBottom: '20px',
      },
      dateStyle: {
        fontWeight: FONT_WEIGHT.regular,
        '& $bigStyle': {
          fontSize: '24px',
          lineHeight: '28px',
        },
        '& $mediumStyle': {
          fontSize: '20px',
          lineHeight: '24px',
        },
        '& $smallStyle': {
          fontSize: '16px',
          lineHeight: '20px',
        },
      },
      sizeStyle: {
        fontWeight: FONT_WEIGHT.regular,
        '& $bigStyle': {
          fontSize: '24px',
          lineHeight: '28px',
        },
        '& $mediumStyle': {
          fontSize: '20px',
          lineHeight: '24px',
        },
        '& $smallStyle': {
          fontSize: '16px',
          lineHeight: '20px',
        },
      },
      subTitleStyle: {
        fontWeight: FONT_WEIGHT.bold,
        marginBottom: '10px',
      },
      textStyle: {
        fontWeight: FONT_WEIGHT.regular,
        padding: '0 0 20px 0',
      },
      itemContainerStandaloneStyle: {
        alignItems: 'center',
        '& > div': {
          height: '100%',
          minHeight: 'unset',
          maxHeight: 'unset',
          maxWidth: '840px',
        },
      },
      // itemContainerInfoStandaloneStyle: {
      //   alignItems: 'center',
      //   '& > div': {
      //     height: '100%',
      //     minHeight: 'unset',
      //     maxHeight: 'unset',
      //     maxWidth: '840px',
      //   },
      // },
      textContainerStandaloneStyle: {
        padding: '40px 60px 0 60px',
        boxSizing: 'border-box',
        // maxWidth: '840px',
      },
      textStandaloneStyle: {
        '& > $bigStyle': {
          fontSize: '36px',
          lineHeight: '44px',
          '& br': {
            content: '""',
            margin: '26px',
            display: 'block',
          },
        },
        '& > $mediumStyle': {
          fontSize: '30px',
          lineHeight: '36px',
          '& br': {
            content: '""',
            margin: '22px',
            display: 'block',
          },
        },
        '& > $smallStyle': {
          fontSize: '24px',
          lineHeight: '32px',
          '& br': {
            content: '""',
            margin: '16px',
            display: 'block',
          },
        },
      },
      backgroundStyle: {
        background: COLORS.appBackground,
      },
      transparentBackgroundStyle: {
        background: COLORS.appBackgroundTransparent,
      },
      favouriteContainerStyle: {
        position: 'absolute',
        top: 0,
        right: 0,
      },
      scrollContainerStyle: {
        width: '100%',
        height: '100%',
        paddingTop: '52px',
      },
      scrollContainerStandaloneStyle: {
        paddingTop: '84px',
      },
      scrollContainerNoSizesStyle: {
        paddingTop: 0,
      },
      scrollStyle: {
        paddingRight: '14px',
        overflow: 'hidden',
        height: '100% !important',
        maxHeight: 'unset !important',
        '& > div': {
          overflow: 'auto !important',
        },
        '& > div:first-child': {
          maxHeight: '100% !important',
          margin: '0 !important',
        },
      },
      textSizeStyle: {
        position: 'absolute',
      },
      '@media screen and (max-device-width:360px)': {
        textContainerStyle: {
          padding: '20px 0 0 20px',
        },
      },
      videoStyle: {
        marginTop: '20px',
      },
    })
);

const TextBlock = ({
  data,
  noHeader,
  padding,
  subTitle,
  noTextSizes,
  hasBackground,
  showFavourite,
  onScroll,
}: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const textSize: BlockType = useSelector(
    (state: RootState) => state.app.textSize
  );
  const { pathname, search } = useLocation();

  const searchData = queryString.parse(search);
  const isStandalone = Boolean(searchData.standalone);

  const rawHeader: string = data?.content?.header || '';
  const header: string = rawHeader.replace(/(?:\r\n|\r|\n)/g, '<br>');

  const rawText: string = data?.content?.text || '';
  const text: string = rawText.replace(/(?:\r\n|\r|\n)/g, '<br>');

  const hasYoutubeVideo = Boolean(data?.pages?.length);
  const hasMedia = Boolean(data?.images?.length);

  const getMedia = (item, index) => {
    const videoUrl = `${urlPrefix}${item?.url}`;

    if (!videoUrl || item?.type !== 'video') {
      return null;
    }

    return (
      <ReactPlayer
        url={videoUrl}
        width="100%"
        height="500px"
        key={index}
        className={classes.videoStyle}
        controls
        config={{
          file: {
            attributes: {
              crossOrigin: 'true',
              // autoPlay: true,
              preload: 'auto',
              onContextMenu: (e) => e.preventDefault(),
              controlsList: 'noplaybackrate nodownload noremoteplayback',
              disablePictureInPicture: true,
            },
          },
        }}
      />
    );
  };

  const getYoutubeVideo = (item, index) => {
    const videoUrl = item?.content?.link;

    if (!videoUrl) {
      return null;
    }

    const controls = isStandalone ? 0 : 1;
    const autoplay = index === 0 ? 1 : 0;

    return (
      <ReactPlayer
        url={videoUrl}
        width="100%"
        height="500px"
        key={index}
        config={{
          youtube: {
            playerVars: { showinfo: 0, autoplay, controls, disablekb: 1 },
          },
        }}
        className={classes.videoStyle}
      />
    );
  };

  const author: string = data?.content?.author || '';
  const date: string = data?.content?.date || '';
  const size: string = data?.content?.size || '';

  return (
    <>
      {/* {!isStandalone && showFavourite && (
        <div className={classes.favouriteContainerStyle}>
          <FavouriteIcon />
        </div>
      )} */}
      <div
        className={clsx(
          classes.itemContainerStyle,
          isStandalone && classes.itemContainerStandaloneStyle,
          isStandalone && hasBackground && classes.backgroundStyle,
          !isStandalone && hasBackground && classes.transparentBackgroundStyle
        )}
      >
        {((!noHeader && header) || text) && (
          <div
            className={clsx(
              classes.textContainerStyle,
              isStandalone && classes.textContainerStandaloneStyle
            )}
            style={{ padding }}
          >
            {!noTextSizes && (
              <div className={classes.textSizeStyle}>
                <TextSizeIcons />
              </div>
            )}
            <div
              className={clsx(
                classes.scrollContainerStyle,
                isStandalone && classes.scrollContainerStandaloneStyle,
                noTextSizes && classes.scrollContainerNoSizesStyle
              )}
            >
              <Scrollbars
                // style={{ width: 840 }}
                universal={true}
                thumbSize={50}
                autoHeight
                onScroll={onScroll}
                renderThumbVertical={(props) => (
                  <div {...props} style={{ background: COLORS.appBlack }} />
                )}
                className={classes.scrollStyle}
              >
                {author && (
                  <div className={classes.authorStyle}>
                    <div
                      className={classes[`${textSize}Style`]}
                      dangerouslySetInnerHTML={{
                        __html: author,
                      }}
                    />
                  </div>
                )}
                {!noHeader && header && (
                  <div className={classes.headerStyle}>
                    <div
                      className={classes[`${textSize}Style`]}
                      dangerouslySetInnerHTML={{
                        __html: header,
                      }}
                    />
                  </div>
                )}
                {(date || size) && (
                  <div className={classes.dateSizeContainerStyle}>
                    {date && (
                      <div className={classes.dateStyle}>
                        <div
                          className={classes[`${textSize}Style`]}
                          dangerouslySetInnerHTML={{
                            __html: date,
                          }}
                        />
                      </div>
                    )}
                    {size && (
                      <div className={classes.sizeStyle}>
                        <div
                          className={classes[`${textSize}Style`]}
                          dangerouslySetInnerHTML={{
                            __html: size,
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
                {Boolean(text || subTitle) && (
                  <div
                    className={clsx(
                      classes.textStyle,
                      isStandalone && classes.textStandaloneStyle
                    )}
                  >
                    {subTitle && (
                      <div
                        className={clsx(
                          classes.subTitleStyle,
                          classes[`${textSize}Style`]
                        )}
                        dangerouslySetInnerHTML={{
                          __html: subTitle,
                        }}
                      />
                    )}
                    <div
                      className={classes[`${textSize}Style`]}
                      dangerouslySetInnerHTML={{
                        __html: text,
                      }}
                    />
                  </div>
                )}
                {hasYoutubeVideo && data?.pages?.map(getYoutubeVideo)}
                {hasMedia && data?.images?.map(getMedia)}
              </Scrollbars>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TextBlock;
