import * as C from '../common/constants';
import CircularProgress from '@mui/material/CircularProgress';
import CloseLayout from './CloseLayout';
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { Lng, AlertType } from '../common/enums';
import { Routes } from '../common/routes';
import { fetchData, showAlert, resetData } from '../common/app/actions';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { RootState, AppDispatch } from '../redux/configureStore';
import { ReactComponent as IconArrow } from '../assets/svg/icon_arrow_down.svg';
import { setGeoStatus } from '../common/app/actions';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';

// LogRocket.init('rundale/rundale');
// setupLogRocketReact(LogRocket);

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      excursionsContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        background: COLORS.appBackground,
      },
      centerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      },
      excursionsWrapperStyle: {
        position: 'relative',
        width: '100%',
        minHeight: '74px',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        boxSizing: 'border-box',
        cursor: 'pointer',
        // transition: '1s ease',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      headerStyle: {
        width: '100%',
        minHeight: '74px',
        fontSize: '22px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appBlack,
        // lineHeight: '64px',
        whiteSpace: 'nowrap',
        padding: '40px 50px',
        borderBottom: `1px solid ${COLORS.appBlack}`,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      activeHeaderStyle: {
        borderBottom: 'unset',
      },
      arrowStyle: {
        width: '30px',
        height: '20px',
      },
      activeOutsideHeaderStyle: {
        padding: '40px 50px 20px 50px',
        borderBottom: 'unset',
        boxSizing: 'border-box',
      },
      activeStyle: {
        background: COLORS.appGold,
        // transition: '1s ease',
      },
      textStyle: {
        width: '100%',
        fontSize: '16px',
        lineHeight: '20px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.regular,
        color: COLORS.appBlack,
        // lineHeight: '64px',
        whiteSpace: 'normal',
        // margin: '30px 0 10px 0',
        padding: '0 0',
        boxSizing: 'border-box',
      },
      linkStyle: {
        width: '100%',
        fontSize: '16px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appBlack,
        // lineHeight: '64px',
        whiteSpace: 'nowrap',
        textDecoration: 'underline',
        textUnderlinePosition: 'under',
        cursor: 'pointer',
        padding: '20px 0',
        boxSizing: 'border-box',
      },
      activeLinkStyle: {
        padding: '10px 0 20px 0',
      },
      subExcursionsWrapperStyle: {
        position: 'relative',
        width: '100%',
        minHeight: '56px',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderBottom: `1px solid ${COLORS.appBlack}`,
        boxSizing: 'border-box',
        padding: '20px 50px',
        cursor: 'pointer',
        // transition: '1s ease',
        background: COLORS.appGold,
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      subHeaderStyle: {
        width: '100%',
        fontSize: '20px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appBlack,
        // padding: '20px 50px',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
      },
      activeSubHeaderStyle: {
        padding: '0 0 20px 0',
        textDecoration: 'underline',
        textUnderlinePosition: 'under',
        cursor: 'pointer',
        // transition: '1s ease',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            opacity: '0.7',
            transition: '0.5s ease',
          },
        },
      },
      expandStyle: {
        width: '100%',
        boxSizing: 'border-box',
        padding: '20px 50px',
      },
      insideExpandStyle: {
        borderBottom: `1px solid ${COLORS.appBlack}`,
        background: COLORS.appGold,
        '& > :last-child': {
          paddingBottom: 0,
        },
      },
      '@media screen and (max-device-width:360px)': {
        headerStyle: {
          padding: '20px',
        },
        arrowStyle: {
          width: '24px',
          height: '20px',
        },
        subHeaderStyle: {
          padding: '20px',
        },
        activeSubHeaderStyle: {
          padding: '0 20px',
        },
        textStyle: {
          padding: '20px',
        },
        linkStyle: {
          padding: '20px',
        },
        activeLinkStyle: {
          padding: '4px 20px 20px 20px',
        },
      },
    })
);

const Excursions = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [activeButton, setActive] = useState(null);
  const [activeSubButton, setSubActive] = useState(null);

  const lng = useSelector((state: RootState) => state.app.lng);
  const excursionsData = useSelector(
    (state: RootState) => state.app[lng]?.[C.EXCURSIONS_DATA]
  );

  useEffect(() => {
    if (!excursionsData) {
      dispatch<AppDispatch>(
        fetchData(C.EXCURSIONS_DATA, { excursions: true, lng })
      );
    }

    /*
    // let's assume we don't have any headphones connected
    let headphonesConnected = null;

    const updateDevices = () => {
      navigator.mediaDevices.enumerateDevices().then(function (devices) {
        // check to see if we have any device with "head" in its name connected
        // like "headset" or "headphones"
        headphonesConnected = devices
          .filter((device) => /audio\w+/.test(device.kind))
          .find((device) => device.label.toLowerCase().includes('head'));

        console.log('>>>>', headphonesConnected);
      });
    };

    updateDevices();

    // add an ondevicechange event listener so we can tell when
    // an input device is connected and disconnected
    navigator.mediaDevices.ondevicechange = updateDevices;
    */

    // LogRocket.identify(navigator.userAgent, {
    //   lng,
    //   platform: navigator.platform,
    //   productSub: navigator.productSub,
    //   appVersion: navigator.appVersion,
    // });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const buttonId = event.currentTarget.getAttribute('data-id');

    const selectedData = excursionsData[buttonId]?.pages[0];
    const subId = selectedData?.content?.id;

    setSubActive({ buttonId: subId, parentId: buttonId }); // null
    setActive(buttonId);
  };

  const onLinkClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const parentId = activeSubButton?.parentId;
    const buttonId = event.currentTarget.getAttribute('data-id');
    const route = Routes.OUTSIDE.includes(parentId)
      ? Routes.OUTSIDE
      : Routes.EXPOSITIONS;
    const pathname = `${route}/${buttonId}`;

    // (navigator as any).bluetooth
    //   .requestDevice({ filters: [{ services: ['battery_service'] }] })
    //   .then((device) => {
    //     /* … */
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });

    navigate({
      pathname,
      search,
    });

    dispatch(showAlert(AlertType.PHONES));
  };

  const getExpandedData = (item, index) => {
    const rawText: string = item?.content?.text || '';
    const text: string = rawText.replace(/(?:\r\n|\r|\n)/g, '<br>');
    const subHeader: string = item?.content?.header || '';
    const dataId = item?.content?.id;

    const isSubActive = activeSubButton?.buttonId === dataId;

    return (
      <div
        key={index}
        className={clsx(classes.expandStyle, classes.insideExpandStyle)}
      >
        <div
          className={clsx(
            classes.subHeaderStyle,
            isSubActive && classes.activeSubHeaderStyle
          )}
          onClick={onLinkClick}
          data-id={dataId}
        >
          {subHeader}
        </div>
        <div
          className={classes.textStyle}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        <div
          className={clsx(
            classes.linkStyle,
            isSubActive && classes.activeLinkStyle
          )}
          onClick={onLinkClick}
          data-id={dataId}
        >
          {item.content.additional}
        </div>
      </div>
    );
  };

  const onSubClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();

    const buttonId = event.currentTarget.getAttribute('data-id');
    const parentId = event.currentTarget.getAttribute('parent-id');

    dispatch(setGeoStatus(false));

    setSubActive({ buttonId, parentId });
  };

  const getActiveItem = (item, index, parentId) => {
    const dataId = item?.content?.id;
    const isSubActive = activeSubButton?.buttonId === dataId;

    if (!isSubActive) {
      const subHeader: string = item?.content?.header || '';

      return (
        <div
          key={index}
          className={classes.subExcursionsWrapperStyle}
          onClick={onSubClick}
          data-id={dataId}
          parent-id={parentId}
        >
          <div className={classes.subHeaderStyle}>{subHeader}</div>
        </div>
      );
    }

    return getExpandedData(item, index);
  };

  const getActiveData = (currentData, parentId) => {
    if (currentData?.pages?.length) {
      return currentData?.pages.map((item, index) =>
        getActiveItem(item, index, parentId)
      );
    }

    return getActiveItem(currentData, 1, parentId);
  };

  const getContent = () => {
    if (!excursionsData) {
      return (
        <div className={classes.centerStyle}>
          <CircularProgress />
        </div>
      );
    }

    const isSubActive = Boolean(activeSubButton?.buttonId);

    const excursionsButtons = Object.keys(excursionsData)
      .map((excursionKey, index) => {
        const currentData = excursionsData[excursionKey];
        const dataId = currentData?.content?.id;
        const isActive: boolean = activeButton === dataId;

        // TEMPORARY
        // if (dataId === 'inside' && inside !== 'true') {
        //   return null;
        // }

        return (
          <div
            className={clsx(
              classes.excursionsWrapperStyle,
              isActive && classes.activeStyle
            )}
            key={index}
            data-id={dataId}
            onClick={onClick}
          >
            <div
              className={clsx(
                classes.headerStyle,
                isActive && isSubActive && classes.activeHeaderStyle
              )}
            >
              {currentData.content.header}
              {isActive && isSubActive && (
                <IconArrow className={clsx(classes.arrowStyle)} />
              )}
            </div>
            {isActive && getActiveData(currentData, dataId)}
          </div>
        );
      })
      .filter((item) => item);

    return excursionsButtons;
  };

  const onClose = () => {
    const { lng, ...searchParams } = queryString.parse(search);
    const paramString = queryString.stringify({ ...searchParams });

    // dispatch(resetData());

    navigate({
      pathname: Routes.LANGUAGE,
      search: paramString,
    });
  };

  return (
    <div className={classes.excursionsContainerStyle}>
      <CloseLayout onClose={onClose}>{getContent()}</CloseLayout>
    </div>
  );
};

export default Excursions;
