import * as C from '../common/constants';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import React, { useState, useEffect, useRef } from 'react';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { ReactComponent as IconClose } from '../assets/svg/icon_close.svg';
import { RootState, AppDispatch } from '../redux/configureStore';
import { fetchData, showAlert } from '../common/app/actions';
import { getPath } from '../common/utils/helpers';
import { makeStyles, createStyles, StyleRules } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      centerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      },
      dialogContainerStyle: {
        '& > .MuiDialog-container': {
          padding: '30px',
          boxSizing: 'border-box',
        },
        '& > div > div': {
          background: 'none',
        },
      },
      dialogContentStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        color: COLORS.appBlack,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        background: COLORS.appGold,
        padding: '0 !important',
      },
      itemContainerStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '50px',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
      },
      textContainerStyle: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '30px',
      },
      textWrapperStyle: {
        display: 'flex',
        flexDirection: 'row',
      },
      itemTextStyle: {
        fontSize: '18px',
        fontStyle: 'italic',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appBlack,
        display: 'inline-block',
        lineHeight: '28px',
        marginBottom: '6px',
        textAlign: 'center',
      },
      textIconStyle: {
        width: '22px',
        height: '22px',
        display: 'inline-block',
        marginLeft: '4px',
        marginBottom: '-4px',
      },
      itemIconContainerStyle: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '20px',
        justifyContent: 'center',
      },
      itemIconStyle: {
        width: '44px',
        height: '44px',
      },
      closeIconStyle: {
        position: 'absolute',
        left: 0,
        top: 0,
        margin: '6px',
        padding: '10px',
        width: '40px',
        height: '40px',
        zIndex: 1000,
        fill: COLORS.appBlack,
        boxSizing: 'border-box',
      },
      '@global': {
        '.MuiDialog-paperFullScreen': {
          width: 'calc(100% - 60px)',
          height: 'calc(100% - 60px)',
          boxSizing: 'border-box',
        },
      },
    })
);

const Alert = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lng = useSelector((state: RootState) => state.app.lng);
  const alertType = useSelector((state: RootState) => state.app.alertType);
  const alertsData = useSelector(
    (state: RootState) => state.app[lng]?.[C.ALERTS_DATA]
  );

  useEffect(() => {
    if (alertType) {
      dispatch<AppDispatch>(fetchData(C.ALERTS_DATA, { alerts: true, lng }));
    }
  }, [alertType]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!alertType || !alertsData) {
    return null;
  }

  const onClose = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(showAlert());
  };

  const getText = (passedText, index, item) => {
    const imgUrl: string = `${urlPrefix}${item?.svg?.[index]?.url}`;

    const icon = `<img
    class="${classes.textIconStyle}"
    src="${imgUrl}"
    alt=""
    key={index}
    />`;

    const text = passedText.replace('{x}', icon);

    return (
      <div className={classes.textWrapperStyle} key={index}>
        <div
          className={classes.itemTextStyle}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </div>
    );
  };

  const getIcon = (icon, index) => {
    const imgUrl: string = `${urlPrefix}${icon.url}`;

    return (
      <img className={classes.itemIconStyle} src={imgUrl} alt="" key={index} />
    );
  };

  const getSlide = (item, index) => {
    const rawText = item.content.text;
    const text: string = rawText?.replace(/(?:\r\n|\r|\n)/g, '{z}');
    const textArray = text?.split('{z}');

    return (
      <div className={classes.itemContainerStyle} key={index}>
        <div className={classes.itemIconContainerStyle}>
          {item?.svg && item?.svg?.map(getIcon)}
        </div>
        <div className={classes.textContainerStyle}>
          {textArray?.map((textItem, index) => getText(textItem, index, item))}
        </div>
      </div>
    );
  };

  const currentItem = alertsData.pages.find(
    (item) => item.content.id === alertType
  );

  return (
    <Dialog
      fullScreen={true}
      open={Boolean(alertType)}
      onClose={onClose}
      className={classes.dialogContainerStyle}
    >
      <MuiDialogContent className={classes.dialogContentStyle}>
        {getSlide(currentItem, 1)}
        <IconClose className={classes.closeIconStyle} onClick={onClose} />
      </MuiDialogContent>
    </Dialog>
  );
};

export default Alert;
