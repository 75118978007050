import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'core-js/features/string/repeat';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/configureStore';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import * as ReactDOMClient from 'react-dom/client';

import './assets/styles/reset.css';
import './assets/styles/fonts.css';

import 'react-h5-audio-player/lib/styles.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'flickity/css/flickity.css';
import './index.css';

const container = document.getElementById('app');
const root = ReactDOMClient.createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();
// serviceWorker.register();
