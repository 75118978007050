import * as C from '../common/constants';
import React from 'react';
import clsx from 'clsx';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { getText } from '../common/utils/helpers';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as IconMarker } from '../assets/svg/icon_marker.svg';
import { useGeolocated } from 'react-geolocated';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      geoContainerStyle: {
        position: 'absolute',
        // width: '100%',
        // height: '100%',
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        left: 0,
        top: 0,
        // transformOrigin: '-100% -100%',
        // transform: 'translate(-50%, -90%)',
        transform: `rotate(-${C.MAP_ROTATION_ANGLE}deg)`,
      },
      markerStyle: {
        width: '20px',
        height: '32px',
        fill: COLORS.appLakeBlue,
      },
    })
);

// interface IProps {
//   coords?: {
//     latitude: number;
//     longitude: number;
//     altitude: number;
//     accuracy: number;
//     altitudeAccuracy: number;
//     heading: any;
//     speed: number;
//   };
//   timestamp?: number; // timestamp of when the last position was retrieved
//   isGeolocationAvailable?: boolean; // boolean flag indicating that the browser supports the Geolocation API
//   isGeolocationEnabled?: boolean; // boolean flag indicating that the user has allowed the use of the Geolocation API
//   positionError?: any; // object with the error returned from the Geolocation API call
// }

// rundale
// LATITUDE 56,41395460989257
// LONGITUDE 24,025550202736532

// home
// latitude: 56,899051199999995
// longitude: 24,0575506

// 0,485096590107425
// 0,032000397263468

const minLong = 24.011526;
const maxLong = 24.033475;
const minLat = 56.403745;
const maxLat = 56.418127;

var bbox = [minLong, minLat, maxLong, maxLat];
var pixelWidth = C.MAP_ROTATED_SIZE.width;
var pixelHeight = C.MAP_ROTATED_SIZE.height;
var bboxWidth = bbox[2] - bbox[0];
var bboxHeight = bbox[3] - bbox[1];

const convertToXY = (latitude, longitude) => {
  var widthPct = (longitude - bbox[0]) / bboxWidth;
  var heightPct = (latitude - bbox[1]) / bboxHeight;
  var x = Math.floor(pixelWidth * widthPct);
  var y = Math.floor(pixelHeight * (1 - heightPct));
  return { x, y };
};

const GeoLocation = (): JSX.Element => {
  const classes = useStyles();

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: 5000,
    });

  if (!coords || !isGeolocationAvailable) {
    return null;
  }

  const { x, y } = convertToXY(coords.latitude, coords.longitude);

  return (
    <div className={classes.geoContainerStyle} style={{ left: x, top: y }}>
      <IconMarker className={classes.markerStyle} />
    </div>
  );
};

export default GeoLocation;

// {
//   positionOptions: {
//       enableHighAccuracy: true,
//       maximumAge: 0,
//       timeout: Infinity,
//   },
//   watchPosition: false,
//   userDecisionTimeout: null,
//   suppressLocationOnMount: false,
//   geolocationProvider: navigator.geolocation,
//   isOptimisticGeolocationEnabled: true
// }
