import ImageTargetMarkers from './ImageTargetMarkers';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { getPath } from '../common/utils/helpers';
import { useCallback, useRef, useEffect, useState } from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      markersContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
      '@keyframes zoomAnimation': {
        from: { transform: 'scale(70%, 70%)' },
        to: { transform: 'scale(100%, 100%)' },
      },
      imageStyle: {
        // position: 'absolute',
        // width: '100%',
        // maxHeight: '100%',
        // height: '100%',
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        alignSelf: 'center',
        animation: `$zoomAnimation 1.5s`,
      },
    })
);

const WallImage = ({
  item,
  data,
  onZoom,
  onMarkerClick,
  swiper,
  className,
}): JSX.Element => {
  const classes = useStyles();
  const imgRef = useRef(null);
  const imgUrl = `${urlPrefix}${item?.url}`;

  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      // const _x = Math.round(x);
      // const _y = Math.round(y);
      // const _scale = Math.round(scale);

      const value = make3dTransformValue({
        x,
        y,
        scale,
      });

      img.style.setProperty('transform', value);
    }
  }, []);

  const onChange = () => {
    if (imgRef?.current) {
      // const value = make3dTransformValue({ x: 0, y: 0, scale: 1 });
      // imgRef.current.style.setProperty(
      //   'transform',
      //   'scale3d(1, 1, 1) translate3d(0px, 0px, 0px)'
      // );
      imgRef.current.style.transform =
        'scale3d(1, 1, 1) translate3d(0px, 0px, 0px)';
    }
    // onUpdate({ x: 0, y: 0, scale: 1 });
  };

  useEffect(() => {
    if (swiper) {
      swiper.on('transitionStart', onChange);
    }

    return () => {
      swiper?.off('transitionStart', onChange);
    };
  }, [swiper]);

  const onZoomUpdate = () => {
    const transformProps = imgRef?.current?.style?.transform;

    if (transformProps) {
      const [, zoomScale] = transformProps.replace(/\s/g, '').split(',');
      onZoom(zoomScale);
    }
  };

  // const onZoomStart = () => {
  //   console.log('onZoomStart');
  // };

  // const onZoomEnd = () => {
  //   console.log('onZoomEnd');
  // };

  return (
    <div className={className}>
      <QuickPinchZoom
        onUpdate={onUpdate}
        minZoom={1}
        maxZoom={3}
        inertiaFriction={0.5}
        onZoomUpdate={onZoomUpdate}
        // onZoomStart={onZoomStart}
        // onZoomEnd={onZoomEnd}
      >
        <div ref={imgRef}>
          {Boolean(onMarkerClick) && (
            <div className={classes.markersContainerStyle}>
              <ImageTargetMarkers
                data={data}
                onMarkerClick={onMarkerClick}
                isMarkersSmall
              />
            </div>
          )}
          <img src={imgUrl} alt="" className={classes.imageStyle} />
        </div>
      </QuickPinchZoom>
    </div>
  );

  // return (
  //   <TransformWrapper
  //     // initialScale={1}
  //     initialPositionX={0}
  //     initialPositionY={0}
  //     centerZoomedOut={true}
  //     centerOnInit={true}
  //     minScale={0.7}
  //     maxScale={3}
  //     limitToBounds={true}
  //     onZoom={onZoom}
  //     // ref={ref}
  //   >
  //     {(transformProps) => {
  //       const { zoomIn, zoomOut, resetTransform, state, ...rest } =
  //         transformProps;
  //       // console.log('>>>ZOOM', transformProps);

  //       return (
  //         <TransformComponent
  //           wrapperStyle={{
  //             overflow: 'unset', // overflow: isFullImage ? 'unset' : 'hidden',
  //             width: '100%',
  //             height: '100%',
  //             display: 'flex',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //           }}
  //           contentStyle={{ width: '100%', position: 'absolute' }}
  //         >
  //           {Boolean(onMarkerClick) && (
  //             <div className={classes.markersContainerStyle}>
  //               <ImageTargetMarkers
  //                 data={data}
  //                 onMarkerClick={onMarkerClick}
  //                 isMarkersSmall
  //               />
  //             </div>
  //           )}
  //           <img src={imgUrl} alt="" className={classes.imageStyle} />
  //         </TransformComponent>
  //       );
  //     }}
  //   </TransformWrapper>
  // );
};

export default WallImage;
