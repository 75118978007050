import * as C from '../common/constants';
import Alert from './Alert';
import CircularProgress from '@mui/material/CircularProgress';
import FlickityWrapper from './FlickityWrapper';
import LanguageStandaloneChooser from './LanguageStandaloneChooser';
import Layout from './Layout';
import RoomTitle from './RoomTitle';
import queryString from 'query-string';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { RootState, AppDispatch } from '../redux/configureStore';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { fetchData } from '../common/app/actions';
import { getPath } from '../common/utils/helpers';
import { useHiddenRoomNr } from '../hooks/useHiddenRoomNr';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';
import { useRoomSetUrl } from '../hooks/useRoomSetUrl';
import { useSelector, useDispatch } from 'react-redux';

const urlPrefix = getPath();

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      expositionsContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        background: COLORS.appBackground,
      },
      roomTitleParentStyle: {
        position: 'relative',
        width: '100%',
        // height: '15%',
        // maxHeight: '200px',
      },
      sliderContainerStyle: {
        width: '100%',
        flex: 1,
        // height: '100%',
      },
      imageStyle: {
        // width: '100%',
        height: '100%',
        // objectFit: 'cover',
        // objectPosition: 'center',
      },
      fakeEndStyle: {
        width: '0px',
        height: '100%',
      },
    })
);

const Expositions = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const lng = useSelector((state: RootState) => state.app.lng);
  const translationsData = useSelector(
    (state: RootState) => state.app[lng]?.[C.TRANSLATIONS_DATA]
  );
  const isStandaloneLanguagesVisible = useSelector(
    (state: RootState) => state.app.isStandaloneLanguagesVisible
  );
  const { search } = useLocation();

  const searchParams = queryString.parse(search);
  const isStandalone = Boolean(searchParams.standalone);

  const { currentExposition, hiddenRoomArrayIndex, currentHiddenRoomId } =
    useHiddenRoomNr();
  const { setRoomInUrl } = useRoomSetUrl();

  useEffect(() => {
    if (!translationsData) {
      dispatch<AppDispatch>(
        fetchData(C.TRANSLATIONS_DATA, { translations: true, lng })
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentExposition) {
      if (!searchParams.room) {
        setRoomInUrl(currentHiddenRoomId);
      }
    }
  }, [search, currentExposition]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!currentExposition || !translationsData) {
    return (
      <Layout>
        <CircularProgress />
      </Layout>
    );
  }

  const currentData = currentExposition.pages[hiddenRoomArrayIndex];
  const currentWallData = currentData?.pages?.filter(
    (item) => item.content.template === 'pages/wall'
  );
  const header = currentData?.content?.header || '';

  const siblingRoomId = currentData?.content?.links;
  const siblingData =
    siblingRoomId &&
    currentExposition.pages.find(
      (item) =>
        item.content?.autoid === siblingRoomId ||
        item.content?.uuid === siblingRoomId
    );

  return (
    <Layout>
      <div className={classes.expositionsContainerStyle}>
        <div className={classes.roomTitleParentStyle}>
          <RoomTitle
            title={header}
            data={currentData?.audio?.[0]}
            nr={currentData?.content?.nr}
            sibling={siblingData}
          />
        </div>
        <div className={classes.sliderContainerStyle}>
          {Boolean(currentWallData?.length) && (
            <FlickityWrapper currentWallData={currentWallData} />
          )}
        </div>
      </div>
      {isStandalone && isStandaloneLanguagesVisible && (
        <LanguageStandaloneChooser />
      )}
      <Alert />
    </Layout>
  );
};

export default Expositions;
