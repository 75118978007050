export const PRODUCTION: string = 'production';

export const SET_LANGUAGE: string = 'SET_LANGUAGE';
export const STORE_ERROR: string = 'STORE_ERROR';
export const CLOSE_ERROR: string = 'CLOSE_ERROR';

export const RESET_ALL_DATA: string = 'RESET_ALL_DATA';

export const SET_RIGHT_MENU: string = 'SET_RIGHT_MENU';
export const SET_GEO_STATUS: string = 'SET_GEO_STATUS';
export const SEARCH_DATA: string = 'SEARCH_DATA';

export const SHOW_ALERT: string = 'SHOW_ALERT';

export const SET_ACTIVE_MARKER = 'SET_ACTIVE_MARKER';
export const CLEAR_ACTIVE_MARKER = 'CLEAR_ACTIVE_MARKER';

export const LANGUAGES_DATA: string = 'LANGUAGES_DATA';
export const OUTSIDE_DATA: string = 'OUTSIDE_DATA';
export const INSIDE_DATA: string = 'INSIDE_DATA';
export const SETTINGS_DATA: string = 'SETTINGS_DATA';
export const MAIN_DATA: string = 'MAIN_DATA';
export const EXCURSIONS_DATA: string = 'EXCURSIONS_DATA';
export const TRANSLATIONS_DATA: string = 'TRANSLATIONS_DATA';
export const HELP_DATA: string = 'HELP_DATA';
export const ALERTS_DATA: string = 'ALERTS_DATA';
export const HOURS_DATA: string = 'HOURS_DATA';
export const SURVEY_DATA: string = 'SURVEY_DATA';
export const QUIZ_DATA: string = 'QUIZ_DATA';

export const MEDIA_PLAYER_DATA: string = 'MEDIA_PLAYER_DATA';

export const MOBILE_DATA: string = 'MOBILE_DATA';

export const SET_TEXT_SIZE: string = 'SET_TEXT_SIZE';
export const SHOW_STANDALONE_LANGUAGES: string = 'SHOW_STANDALONE_LANGUAGES';

export const PERSISTENT: string = 'persistent';
export const RUNDALE: string = 'rundale';

export const SET_FAVOURITE: string = 'SET_FAVOURITE';
export const SET_SURVEY_ID: string = 'SET_SURVEY_ID';
export const SET_QUIZ_ID: string = 'SET_QUIZ_ID';

export const MAP_SIZE = {
  width: 950,
  height: 1758,
};

export const FLOOR_SIZE = {
  width: 970,
  height: 1146,
};

export const MAP_ROTATED_SIZE = {
  width: 1304,
  height: 1561,
};

export const MAP_ROTATION_ANGLE = 152;

export const HEADER_PORTRAIT_HEIGHT: number = 50;
export const HEADER_LANDSCAPE_WIDTH: number = 60;

export const IDLE_TIMER: number = 1000 * 60 * 3; // 10 min;
