import * as C from '../common/constants';
import CircularProgress from '@mui/material/CircularProgress';
import CloseLayout from './CloseLayout';
import Flickity from 'react-flickity-component';
import React, { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import SurveyAnswerType from './SurveyAnswerType';
import UpButton from './UpButton';
import { COLORS, FONTS, FONT_WEIGHT } from '../common/theme';
import { InfoButton } from '../common/enums';
import { RootState, AppDispatch } from '../redux/configureStore';
import { StyleRules, createStyles, makeStyles } from '@mui/styles';
import { fetchData, clearData } from '../common/app/actions';
import { getPath } from '../common/utils/helpers';
import { storeSurveyID } from '../common/persistent/actions';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const urlPrefix = getPath();

const flickityOptions = {
  initialIndex: 0,
  draggable: false,
  freeScroll: false,
  prevNextButtons: false,
  // adaptiveHeight: true,
  accessibility: true,
};

const useStyles = makeStyles(
  (theme): StyleRules =>
    createStyles({
      surveyContainerStyle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        background: COLORS.appBackground,
        fontFamily: FONTS.alegreya,
      },
      centerStyle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        background: COLORS.appBackground,
      },
      sliderStyle: {
        width: '100%',
      },
      itemContainerStyle: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px',
        boxSizing: 'border-box',
        background: COLORS.appBackground,
      },
      headerStyle: {
        fontSize: '22px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appBlack,
        lineHeight: '28px',
        marginBottom: '40px',
      },
      textStyle: {
        fontSize: '18px',
        fontFamily: FONTS.alegreya,
        fontWeight: FONT_WEIGHT.medium,
        color: COLORS.appBlack,
        lineHeight: '24px',
        marginBottom: '6px',
        fontVariantNumeric: 'lining-nums',
      },
      buttonStyle: {
        position: 'absolute',
        bottom: 0,
        border: `1px solid ${COLORS.appBlack}`,
        padding: '12px 24px 10px 24px',
        boxSizing: 'border-box',
        color: COLORS.appBlack,
        fontFamily: FONTS.alegreya,
        fontSize: '18px',
        lineHeight: '16px',
        whiteSpace: 'nowrap',
        marginBottom: '80px',
        zIndex: 100,
        // textTransform: 'uppercase',
        '@media (hover: hover) and (pointer: fine)': {
          '&:hover': {
            background: COLORS.appGold,
            transition: '0.5s ease',
          },
        },
      },
      '@global': {
        '.fcarousel': {
          width: '100%',
          display: 'flex',
          flex: 1,
        },
        '.flickity-viewport': {
          width: '100%',
          height: '100% !important',
        },
        '.flickity-page-dots': {
          bottom: '20px !important',
        },
        '.flickity-page-dots > .flickity-page-dot:only-child': {
          display: 'none !important',
        },
        '.flickity-button': {
          background: 'none',
        },
        '.flickity-button:hover': {
          background: 'none',
        },
        // '& .Mui-focused': {
        //   borderColor: COLORS.appGold,
        // },
      },
      '@media screen and (max-device-width:360px)': {
        itemContainerStyle: {
          padding: '20px',
        },
        buttonStyle: {
          marginBottom: '40px',
        },
      },
    })
);

const Survey = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const lng = useSelector((state: RootState) => state.app.lng);
  const surveyData = useSelector(
    (state: RootState) => state.app[lng]?.[C.SURVEY_DATA]
  );

  const completedSurveys =
    useSelector((state: RootState) => state.persistent.completedSurveys) || [];

  const [isLastSlide, setLastSlide] = useState(false);
  const flickityRef: any = useRef();

  const [ratingValue, setRating] = useState(null);
  const [surveyComment, setSurveyComment] = useState('');

  useEffect(() => {
    if (!surveyData) {
      dispatch<AppDispatch>(fetchData(C.SURVEY_DATA, { survey: true, lng }));
    }

    return () => {
      dispatch(clearData(C.SURVEY_DATA));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (surveyData) {
      const totalSlides = surveyData.pages.length;

      if (totalSlides === 1) {
        setLastSlide(true);
      }
    }
  }, [surveyData]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!surveyData) {
    return null;
  }

  const onSubmitClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const slideNr: any = event.currentTarget.getAttribute('data-nr');

    if (isLastSlide) {
      const currentSurveyId = surveyData?.content?.sid;
      ReactGA.event({
        category: InfoButton.SURVEY,
        action: currentSurveyId,
        label: ratingValue,
      });
      ReactGA.event({
        category: InfoButton.SURVEY,
        action: currentSurveyId,
        label: surveyComment,
      });

      const sid = surveyData?.content?.sid;
      dispatch(storeSurveyID(sid));
    } else {
      flickityRef?.current?.flkty?.next();
    }
  };

  const submitLabel = surveyData?.content?.submit;

  const getSlides = (item, index) => {
    const rawHeader = item.content.header;
    const header: string = rawHeader?.replace(/(?:\r\n|\r|\n)/g, '<br>');

    const rawText = item.content.text;
    const text: string = rawText?.replace(/(?:\r\n|\r|\n)/g, '<br>');

    return (
      <div className={classes.itemContainerStyle} key={index}>
        <div
          className={classes.headerStyle}
          dangerouslySetInnerHTML={{
            __html: header,
          }}
        />
        <div
          className={classes.textStyle}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        <SurveyAnswerType
          data={item}
          setRating={setRating}
          ratingValue={ratingValue}
          setSurveyComment={setSurveyComment}
          surveyComment={surveyComment}
        />
        <div
          className={classes.buttonStyle}
          onClick={onSubmitClick}
          data-nr={index + 1}
        >
          {submitLabel}
        </div>
      </div>
    );
  };

  const onFlickityLoad = (flickity) => {
    const totalSlides = surveyData.pages.length;

    flickity.on('change', (slideNr) => {
      const isLastSlide = totalSlides - 1 === slideNr;

      // console.log('>>>>', totalSlides, slideNr);

      if (isLastSlide) {
        setLastSlide(isLastSlide);
      }
    });
  };

  const getSlider = () => {
    return (
      // @ts-ignore
      <Flickity
        className={'fcarousel'} // default ''
        elementType={'div'} // default 'div'
        options={flickityOptions} // takes flickity options {}
        // disableImagesLoaded={false} // default false
        // reloadOnUpdate // default false
        // static // default false
        ref={flickityRef}
        flickityRef={onFlickityLoad}
      >
        {surveyData?.pages?.map(getSlides)}
      </Flickity>
    );
  };

  const getContent = () => {
    if (!surveyData) {
      return (
        <div className={classes.centerStyle}>
          <CircularProgress />
        </div>
      );
    }

    const currentSurveyId = surveyData?.content?.sid;
    const isSurveyCompleted = completedSurveys.includes(currentSurveyId);
    const title = surveyData.content.header;

    if (isSurveyCompleted) {
      const rawStored = surveyData.content.stored;
      const stored: string = rawStored?.replace(/(?:\r\n|\r|\n)/g, '<br>');

      return (
        <>
          <UpButton title={title} isActive={true} />
          <div className={classes.itemContainerStyle}>
            <div
              className={classes.headerStyle}
              dangerouslySetInnerHTML={{
                __html: stored,
              }}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <UpButton title={title} isActive={true} />
        {getSlider()}
      </>
    );
  };

  return (
    <div className={classes.helpContainerStyle}>
      <CloseLayout>{getContent()}</CloseLayout>
    </div>
  );
};

export default Survey;
